import { Carousel } from "antd";

import DoctorItem1 from "@/assets/images/doctor-list-6.png";
import DoctorItem2 from "@/assets/images/doctor-list-7.png";
import DoctorItem3 from "@/assets/images/doctor-list-8.png";
import { useResponsive } from "ahooks";
// import DoctorItem4 from '@/assets/images/doctor-list-9.png';

const DOCTOR_LIST = [
  {
    title: "Why Acupuncture",
    avatar: DoctorItem1,
    url: "https://medium.com/@blog_28505/why-acupuncture-b8a9bde8d151",
    summary:
      "Tens of millions of Americans have experienced the benefits of Acupuncture! Acupuncture has been clinically proven to provide low risk, SAFE, EFFECTIVE, narcotic-free, and surgery-free treatment for many common and chronic conditions such as:",
  },
  {
    title: "More You Didn’t Know About Acupuncture",
    avatar: DoctorItem2,
    url: "https://medium.com/@blog_28505/more-you-didnt-know-about-acupuncture-40012382ba63",
    summary:
      "While millions of Americans already know of the significant benefits of acupuncture treatments for pain and stress, management, acupuncture has been clinically proven to be effective treatment for so many more health problems!",
  },
  {
    title: "What is Acupuncture",
    avatar: DoctorItem3,
    url: "https://medium.com/@blog_28505/what-is-acupuncture-6856a340c613",
    summary:
      "Acupuncture is a technique of healing used for millennia in the ancient practice of Traditional Chinese Medicine. The practice of Acupuncture uses the insertion of very thin sterile needles under the skin to gently stimulate acupuncture points to induce healing energy and responses.",
  },
  {
    avatar: null,
    summary: "",
  },
];

export const SectionDoctors = () => {
  const goToBlog = (blog: any) => {
    window.open(blog.url);
  };

  const { isPC } = useResponsive();
  return (
    <div className="section doctor-list-section">
      {isPC ? (
        <div className="doctor-list">
          {DOCTOR_LIST.map((item, index) => (
            <div
              key={index}
              className={[
                "doctor-item",
                item.avatar ? "" : "doctor-item-more",
              ].join(" ")}
            >
              <div className="doctor-item-img">
                {item.avatar ? (
                  <img src={item.avatar} alt="doctor-avatar" />
                ) : (
                  <div className="more-to-come">More to come</div>
                )}
                <div
                  className="doctor-avatar-desc"
                  onClick={() => goToBlog(item)}
                >
                  {item.summary}
                </div>
              </div>
              <div className="doctor-item-info">
                <div className="doctor-name">{item.title}</div>
                {/* <div className="doctor-description">{item.summary}</div> */}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Carousel className="doctor-list-responsive" autoplay={true}>
          {DOCTOR_LIST.slice(0, 3).map((item, index) => (
            <div key={index} className="doctor-item">
              <div className="doctor-item-img">
                <img src={item.avatar || ''} alt="doctor-avatar" />
                <div
                  className="doctor-avatar-desc"
                  onClick={() => goToBlog(item)}
                >
                  {item.summary}
                </div>
              </div>
              <div className="doctor-item-info">
                <div className="doctor-name">{item.title}</div>
                <div className="doctor-description">{item.summary}</div>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};
