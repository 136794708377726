import {
  // FACEBOOK_CLIENT_ID,
  GOOGLE_CLIENT_ID,
  router_account_verify_form,
} from "@/web/utils/consts";
import {
  // FacebookFilled,
  GoogleSquareFilled,
  MailFilled,
  MobileTwoTone,
} from "@ant-design/icons";
import { Button, Divider, message } from "antd";
import { useNavigate } from "react-router";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import "./account_setting_verify.scss";
import { facebookLogin, googleLogin } from "@/web/api/user";
import { useModel } from "use-reaction";
import { action_user_login, model_user } from "@/web/models/model_user";
import { AccountSettingProgress } from "./account_setting_progress";
import { GoogleLoginBtn } from "@/web/comps/google_login_btn";
export const AccountSettingVerify = () => {
  const navigate = useNavigate();
  const {
    doAction,
    store: { email, userPhone, googleUserId, facebookUserId },
  } = useModel(model_user);

  const goVerifyEmail = () =>
    navigate(router_account_verify_form, { state: { type: "email" } });
  const goBindPhone = () =>
    navigate(router_account_verify_form, { state: { type: "phone" } });

  // const fbSuccess = (res: any) => {
  //   if (!res?.userID) {
  //     message.error("facebook authorize failed");
  //     return;
  //   }
  //   let data = {
  //     userID: res?.userID || "",
  //     name: res?.name || "",
  //     email: res?.email || "",
  //     avatar: {
  //       url: res?.picture?.data?.url || "",
  //     },
  //   };
  //   doAction(action_user_login, { data, api: facebookLogin }, "model");
  // };
  const gglSuccess = (idToken: string) => {
    doAction(
      action_user_login,
      { data: { idToken }, api: googleLogin },
      "model"
    );
  };
  const hidePart = (val: string) => {
    if (!val) {
      return "N/A";
    }
    const isEmail = val.includes("@");
    const [left, right] = isEmail ? val.split("@") : [val, ""];
    return (
      left.substring(0, Math.min(3, left.length >> 1)) +
      "***" +
      `${isEmail ? "@" : ""}${right}`
    );
  };

  return (
    <>
      <div className="account-content-page account-verify">
        <h2 className="des">Current bindings:</h2>
        <Divider />
        <div className="bind-item">
          <MailFilled style={{ fontSize: 32, color: "#E69F6C" }} />
          <span className="info">{hidePart(email)}</span>
          <Button onClick={goVerifyEmail}>modify</Button>
        </div>
        <div className="bind-item phone">
          <MobileTwoTone twoToneColor="#56A785" style={{ fontSize: 32 }} />
          <span className="info">{hidePart(userPhone)}</span>
          <Button onClick={goBindPhone}>set</Button>
        </div>
        {/* <div className="bind-item fb">
        <FacebookFilled style={{ fontSize: 32, color: "#2F80ED" }} />
        <span className="info">{hidePart(facebookUserId)}</span>
        <FacebookLogin
          appId={FACEBOOK_CLIENT_ID}
          autoLoad={false}
          fields="name,email,picture"
          //   onClick={fbLogin}
          callback={fbSuccess}
          render={(renderProps: any) => (
            <Button
              className="facebook"
              block={true}
              onClick={renderProps.onClick}
              disabled={renderProps.isDisabled}
            >
              set
            </Button>
          )}
        />
      </div> */}
        <div className="bind-item gl">
          <GoogleSquareFilled style={{ fontSize: 32, color: "#e02f18" }} />
          <span className="info">{hidePart(googleUserId)}</span>
          <GoogleLoginBtn
            clientId={GOOGLE_CLIENT_ID}
            onSuccess={gglSuccess}
            text="signin"
          />
        </div>
      </div>
      <AccountSettingProgress />
    </>
  );
};
