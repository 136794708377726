import { Button, Radio, Space } from "antd";
import { useState } from "react";
import { Opt } from "../utils/types";
import "./profile_book_service.scss";
interface Props {
  title?: string;
  options: Required<Opt>[];
  okText?: string;
  active?: boolean;
  onChosen?: (value: any) => void;
  onOk: (value: any) => void;
}
export const ProfileBookService = ({
  title,
  active = true,
  options,
  okText,
  onChosen,
  onOk,
}: Props) => {
  const [chosen, setChosen] = useState<number>();

  return (
    <div className="profile-book-service">
      <div className="book-title">
        {title || "Choose doctor's service to book appointment"}
      </div>
      {options?.length ? (
        <Radio.Group
          className="service-grp"
          onChange={(e) => {
            setChosen(e.target.value);
            onChosen && onChosen(e.target.value);
          }}
          optionType="default"
        >
          <Space direction="vertical">
            {options.map((o) => (
              <Radio key={o.value} value={o.value}>{o.label}</Radio>
            ))}
          </Space>
        </Radio.Group>
      ) : (
        <div className="empty-tip">No Information</div>
      )}
      <Button
        disabled={!active || !chosen}
        type="primary"
        hidden={!options?.length}
        onClick={(e) => onOk(chosen)}
      >
        {okText || "book appointment"}
      </Button>
    </div>
  );
};
