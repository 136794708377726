import {
  action_ftl_finish_step,
  action_ftl_put_step,
  model_ftl,
} from "@/web/models/model_ftl";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useNavigate } from "react-router-dom";
import { useModel } from "use-reaction";
import { FTLProgress } from "./ftl_progress";
import "./acu_service.scss";
import { useMount } from "ahooks";
import { getFtlacuServices, putFtlacuServices } from "@/web/api/ftl";
import { forwardRef, useImperativeHandle } from "react";
import classNames from "classnames";

let defaultValues = {
  treatmentServices: [
    {
      name: "",
      duration: "",
      price: "",
    },
  ],
};
interface Props {
  showExtra?: boolean;
  className?: string;
  onSave?: (values?: any) => void;
}
export const AcuService = forwardRef(
  ({ showExtra = true, className, onSave }: Props, ref) => {
    const [form] = useForm();
    const navigate = useNavigate();
    useImperativeHandle(ref, () => ({
      save: form.submit,
    }));

    const {
      doAction,
      store: { step_previous },
    } = useModel(model_ftl);

    const goHome = () => navigate("/");

    const goBack = () => {
      doAction(action_ftl_put_step, step_previous, "global");
    };

    const goFinal = async () => {
      const values = await form.validateFields();
      if (values) {
        await onFinish(values, true);
      }
    };

    const onFinish = async (data: typeof defaultValues, next?: boolean) => {
      const values = {
        treatmentServices: data.treatmentServices?.filter((_) => !!_.name),
      };
      onSave
        ? onSave(values)
        : await doAction(
            action_ftl_finish_step,
            { values, api: putFtlacuServices, next },
            "global"
          );
    };

    useMount(async () => {
      const data = await getFtlacuServices();
      if (data.treatmentServices?.length) {
        data.treatmentServices.forEach((_: any) => {
          delete _.clinicId;
        });
        form.setFieldsValue(Object.assign({}, defaultValues, data));
      }
    });

    return (
      <>
        <div className={classNames("fill-profile-page", className)}>
          <div className="acu-services-page">
            <h3 className="page-title font-family-merriweather">
              List your treatment services
            </h3>
            <p className="page-desc">
              Share more about your experiences and backgrounds to reach your
              patients better
            </p>
            <div className="service-list">
              <Form
                form={form}
                onFinish={onFinish}
                name="services-form"
                layout="vertical"
                initialValues={defaultValues}
              >
                <Form.List name="treatmentServices">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restFields }) => (
                        <div className="form-row" key={key}>
                          <div className="form-column">
                            <Form.Item
                              className="first-form-item"
                              {...restFields}
                              label={`service${name + 1}`}
                              name={[name, "name"]}
                              fieldKey={[name, "name"]}
                              rules={[
                                {
                                  required: name > 0,
                                  message: "Missing service",
                                },
                              ]}
                            >
                              <Input placeholder="E.g. Tuina" />
                            </Form.Item>
                          </div>
                          <div className="form-column">
                            <Form.Item
                              className="second-form-item"
                              {...restFields}
                              label="Duration (min)"
                              name={[name, "duration"]}
                              fieldKey={[name, "duration"]}
                              dependencies={["treatmentServices", name, "name"]}
                              rules={[
                                {
                                  required: name > 0,
                                  message: "Missing duration",
                                },
                                ({ getFieldValue }) => ({
                                  validator: (_, value) => {
                                    if (
                                      getFieldValue([
                                        "treatmentServices",
                                        name,
                                        "name",
                                      ]) &&
                                      !value
                                    ) {
                                      return Promise.reject("Missing duration");
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input placeholder="E.g. 90" />
                            </Form.Item>
                          </div>
                          <div className="form-column">
                            <Form.Item
                              className="third-form-item"
                              {...restFields}
                              label="Price ($)"
                              name={[name, "price"]}
                              fieldKey={[name, "price"]}
                              dependencies={["treatmentServices", name, "name"]}
                              rules={[
                                {
                                  required: name > 0,
                                  message: "Missing price",
                                },
                                ({ getFieldValue }) => ({
                                  validator: (_, value) => {
                                    if (
                                      getFieldValue([
                                        "treatmentServices",
                                        name,
                                        "name",
                                      ]) &&
                                      !value
                                    ) {
                                      return Promise.reject("Missing price");
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                            >
                              <Input placeholder="E.g. 100" />
                            </Form.Item>
                          </div>
                          <CloseCircleOutlined
                            style={{
                              fontSize: "20px",
                              color: "#BCC4CE",
                            }}
                            onClick={() => remove(name)}
                          />
                        </div>
                      ))}
                      <div className="add-btn-container">
                        <span className="add-btn" onClick={() => add()}>
                          + Add another service
                        </span>
                      </div>
                    </>
                  )}
                </Form.List>
              </Form>
            </div>
          </div>
        </div>
        {showExtra && (
          <FTLProgress
            currentIndex={5}
            lastIndex={5}
            goBack={goBack}
            onCancel={goHome}
            onFinish={goFinal}
          />
        )}
      </>
    );
  }
);
