import { BasicLayout } from "@/web/comps/basic_layout";
import { useNavigate } from "react-router-dom";
import CalendarIconRed from "@/assets/images/icon-calendar-red.svg";
import BookedIcon from "@/assets/images/icon-booking-success.svg";
import { Button } from "antd";
import { router_account_appointment } from "@/web/utils/consts";

import "./failed.scss";
import { useModel } from "use-reaction";
import { model_booking } from "@/web/models/model_booking";
import moment from "moment";
export const BookingFailed = () => {
  const {store: {
    basic,
    target,
    failReason
  }} = useModel(model_booking)
  const navigate = useNavigate();
  return (
    <BasicLayout>
      <div className="booking-finished">
        <div className="finished-summary failed">
          <img src={CalendarIconRed} alt="date" />
          <div className="title">Appointment not confirm!</div>
          <div className="des">
            Booking was failed. Reason:{" "}
            <span>{failReason}</span>
          </div>
          <div className="info">
            <div className="left">
              <div className="head">
                <img src={BookedIcon} alt="date" />
                <span>{moment(basic.dateTime).format('ddd, MMM DD YYYY, hh:mm A')}</span>
              </div>
              <div className="btm">
                <span className="key">Doctor:</span>
                <span className="value">{target.doctor?.profile?.name}</span>
              </div>
            </div>
            <div className="right">Failed</div>
          </div>
          <div className="why">Why this happened?</div>
          <div className="btns">
            {/* todo: back to profile will go doctor/clinic's profile page later */}
            <Button onClick={() => navigate(-2)}>Back to profile</Button>
            <Button
              onClick={() => navigate(router_account_appointment)}
              type="primary"
            >
              My Appointment
            </Button>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};
