import { ajax } from "../utils/ajax";

export const getDoctorById = (id: string) => ajax(`/profile/id/${id}`);
export const getDoctorServicesById = (id: string) =>
  ajax(`/profile/get-doctor-services/${id}`);
export const getAcuIntroduction = () => ajax(`/profile/introduction`);
export const getAcuServices = () => ajax(`/profile/service`);
export const putAcuInfroduction = (data: any) =>
  ajax(`/profile/introduction`, { type: "put", data });
export const putAcuExperiences = (data: any) =>
  ajax(`/profile/experiences`, { type: "put", data });
export const putAcuServices = (data: any) =>
  ajax(`/profile/service`, { type: "put", data });
export const acuAddSchedule = (data: any) =>
  ajax("/profile/schedule", { type: "put", data });
export const acuDelSchedule = (id: string | number) =>
  ajax("/profile/schedule/" + id, { type: "delete" });
export const getAcuSchedules = (params: any) =>
  ajax("/profile/schedule-list", { type: "get", params, cacheTTL: 0 });
export const getAcuMatchTimes = (params: any) =>
  ajax("/profile/match-times", { type: "get", params });
