import { useResponsive } from "ahooks";
import { Rate, Divider } from "antd";
import moment from "moment";
import IconCured from "@/assets/images/icon-cured.svg";
import IconNotCured from "@/assets/images/icon-not-cured.svg";
import IconSomewhat from "@/assets/images/icon-somewhat.svg";
import { WrapperText } from "@/web/comps/wrapper_text";
import './review_item.scss'

export const ReviewItem = ({ data: item }: any) => {
  const { isPC } = useResponsive();
  return (
    <div className="review-item">
      <div className="review-item-summary">
        <div className="summary-left">
          <div className="detail-info-item to">
            <div className="label">To</div>
            <div className="value">{item.doctor.name}</div>
          </div>
          <div className="detail-info-item date">
            <div className="label">Date</div>
            <div className="value">
              {item.updateDate ? moment(item.updateDate).format("LL") : ""}
            </div>
          </div>
        </div>
        {isPC && (
          <div className="score-item">
            {item.cured === 2 && (
              <span className="cured">
                <img src={IconCured} alt="cured" /> Cured
              </span>
            )}
            {item.cured === 1 && (
              <span className="cured">
                <img src={IconSomewhat} alt="cured" /> Somewhat
              </span>
            )}
            {item.cured === 0 && (
              <span className="cured">
                <img src={IconNotCured} alt="cured" /> Not yet Cured
              </span>
            )}
            <Rate
              className="ant-rate-in-card"
              allowHalf
              disabled
              value={
                Math.round(item.rate) < item.rate ? item.rate + 0.5 : item.rate
              }
            />
          </div>
        )}
      </div>
      <Divider />
      {!isPC && (
        <div className="score-item">
          {item.cured === 2 && (
            <span className="cured">
              <img src={IconCured} alt="cured" />
              <span>Cured</span>
            </span>
          )}
          {item.cured === 1 && (
            <span className="cured">
              <img src={IconSomewhat} alt="cured" />
              <span>Somewhat</span>
            </span>
          )}
          {item.cured === 0 && (
            <span className="cured">
              <img src={IconNotCured} alt="cured" />
              <span>Not yet Cured</span>
            </span>
          )}
        </div>
      )}
      <div className="review-item-detail">
        {item.treatedFor && (
          <div className="detail-info-item">
            <div className="label">Treated for</div>
            <div className="value treated-for">{item.treatedFor}</div>
          </div>
        )}
        {item.timeToRecovery && (
          <div className="detail-info-item">
            <div className="label">Duration</div>
            <div className="value num-value">
              {item.timeToRecovery} week{item.timeToRecovery > 1 ? "s" : ""}
            </div>
          </div>
        )}
        {item.officeVisitTimes && (
          <div className="detail-info-item">
            <div className="label">Visit times</div>
            <div className="value num-value">{item.officeVisitTimes}</div>
          </div>
        )}
        <div className="description">
          <WrapperText text={item.information} />
        </div>
      </div>
      <Divider />
      <div className="review-item-score">
        <div className="score-item">
          <div className="score-item-label">effectiveness</div>
          <div className="score-item-num">{item.effectivenessRating}</div>
        </div>
        <div className="score-item">
          <div className="score-item-label">pleasantry</div>
          <div className="score-item-num">{item.pleasantryRating} </div>
        </div>
        <div className="score-item">
          <div className="score-item-label">staff</div>
          <div className="score-item-num">{item.staffRating} </div>
        </div>
      </div>
    </div>
  );
};
