import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { putFtlStep } from "../api/ftl";
import { router_ftl_root } from "../utils/consts";
import { UpOutlined } from "@ant-design/icons";
import { formatNumber } from "../utils/util";
import IconNoDoctors from "@/assets/images/icon-no-doctors.svg";
import { ProfileAssociateDoctor } from "./profile_associate_doctor";
import "./profile_doctor_info.scss";

interface Props {
  id: string;
  isSelf: boolean;
  introduction: string;
  treatmentServices: any[];
  associateDoctors: any[];
  onChosen: (doctor: any)=>void;
}
export const ProfileClinicInfo = ({
  id,
  isSelf,
  introduction = "",
  treatmentServices = [],
  associateDoctors = [],
  onChosen,
}: Props) => {
  const navigate = useNavigate();
  const [showAllIntro, setShowAllIntro] = useState(false);
  const [chosenDoctor, setChosenDoctor] = useState<string>()
  const goEdit = async (ftlStep: string) => {
    await putFtlStep(ftlStep);
    navigate(router_ftl_root);
  };
  const onChosenDoctor = (doctorId: string)=>{
    setChosenDoctor(doctorId)
    onChosen(associateDoctors.find(_ => _.id === doctorId))
  }
  const introductionWrapped = useMemo(
    () => introduction?.slice(0, 500),
    [introduction]
  );

  return (
    <div className="clinic-information">
      <div className="information-comp">
        <div className="information-list">
          <div className="information-item">
            <div className="information-item-title">About Us</div>
            <div className="information-item-content">
              {introduction ? (
                <div className="introduction">
                  {showAllIntro ? introduction : introductionWrapped}
                  {!showAllIntro && introduction.length > 500 && (
                    <span onClick={(e) => setShowAllIntro(!showAllIntro)}>
                      Read More
                    </span>
                  )}
                  {showAllIntro && introduction.length > 500 && (
                    <UpOutlined
                      style={{ color: "#696974" }}
                      onClick={(e) => setShowAllIntro(!showAllIntro)}
                    />
                  )}
                </div>
              ) : (
                <div className="update-info">
                  No information.
                  {isSelf && (
                    <>
                      Please update in
                      <span
                        className="update-info-link"
                        onClick={(e) => goEdit("acuprofile")}
                      >
                        your profile
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="information-item">
            <div className="information-item-title">Services</div>
            <div className="information-item-content">
              {treatmentServices?.length > 0 ? (
                <div className="services">
                  <div className="services-table-row">
                    <div className="services-table-column">Service</div>
                    <div className="services-table-column">Duration (Min)</div>
                    <div className="services-table-column">Price ($)</div>
                  </div>
                  {treatmentServices.map((item, index) => (
                    <div key={index} className="services-table-row">
                      <div className="services-table-column">{item.name}</div>
                      <div className="services-table-column">
                        {formatNumber(item.duration)}
                      </div>
                      <div className="services-table-column">
                        {formatNumber(item.price)}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="update-info">
                  No information.
                  {isSelf && (
                    <>
                      Please update in
                      <span
                        className="update-info-link"
                        onClick={() => goEdit("acuservice")}
                      >
                        your profile
                      </span>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="information-item">
            <div className="information-item-title">Associate Doctors</div>
            {associateDoctors?.length > 0 ? (
              <div className="information-item-content">
                {associateDoctors.map((item, index) => (
                  <ProfileAssociateDoctor
                    key={"clinic-doctor-" + item.id}
                    avatar={item.avatar?.url}
                    doctorId={item.id}
                    doctorName={item.name}
                    medicalTitle={item.medicalTitle}
                    expertiseAreas={item.expertiseAreas}
                    active={item.active}
                    rank={item.rank || 0}
                    reviewCount={item.reviewCount}
                    chosen={chosenDoctor === item.id}
                    onChosen={onChosenDoctor}
                  />
                ))}
              </div>
            ) : (
              <div className="no-data">
                <div className="no-data-img">
                  <img src={IconNoDoctors} alt="icon-no-doctors" />
                </div>
                <div className="no-data-title font-family-merriweather">
                  No associate doctor found
                </div>
                <div className="no-data-description">
                  Associate doctors will be shown here.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
