import { useRequest } from "ahooks";
import { Button, Input, message } from "antd";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { sendConfirmationEmail } from "../api/user";
import "./verify_code.scss";

interface Props {
  receiver: string;
  className?: string;
  loading?: boolean;
  resend?: () => void;
  onSend: (code: string) => void;
}
export const VerifyCode = ({
  receiver,
  resend,
  onSend,
  loading,
  className,
}: Props) => {
  const [chars, setChars] = useState({
    firstChat: "",
    secondChat: "",
    thirdChat: "",
    fourthChat: "",
  });

  const updateInput = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    let value = e.target.value;
    setChars({
      ...chars,
      [key]: value,
    });
  };
  const { run: resentCode, loading: resendLoading } = useRequest(
    async () => sendConfirmationEmail(receiver),
    { manual: true }
  );
  const resendCodeToEmail = async () => {
    await (resend ? resend() : resentCode());
    message.success("resend success!");
  };

  const [valid, setValid] = useState(false);
  const code_1 = useRef<any>();
  const code_2 = useRef<any>();
  const code_3 = useRef<any>();
  const code_4 = useRef<any>();

  const sendVerify = () => {
    onSend(
      [
        chars.firstChat,
        chars.secondChat,
        chars.thirdChat,
        chars.fourthChat,
      ].join("")
    );
  };
  useEffect(() => {
    if (!chars.firstChat) {
      code_1.current?.focus();
    } else if (!chars.secondChat) {
      code_2.current?.focus();
    } else if (!chars.thirdChat) {
      code_3.current?.focus();
    } else if (!chars.fourthChat) {
      code_4.current?.focus();
    }

    setValid(
      !!chars.firstChat &&
        !!chars.secondChat &&
        !!chars.thirdChat &&
        !!chars.fourthChat
    );
  }, [chars]);

  return (
    <div className={classNames("verify-section", className)}>
      <div className="verify-email-tip">
        <div className="font-family-merriweather">
          We just sent a confirmation code over to
        </div>
        <div className="font-family-merriweather email">{receiver}. </div>
        <div className="font-family-merriweather">
          Please verify by entering the code here:
        </div>
      </div>
      <div className="verify-code">
        <Input
          className="verify-code-1"
          maxLength={1}
          ref={code_1}
          value={chars.firstChat}
          onChange={(e) => updateInput(e, "firstChat")}
        />
        <Input
          className="verify-code-2"
          ref={code_2}
          maxLength={1}
          value={chars.secondChat}
          onChange={(e) => updateInput(e, "secondChat")}
        />
        <Input
          className="verify-code-3"
          maxLength={1}
          ref={code_3}
          value={chars.thirdChat}
          onChange={(e) => updateInput(e, "thirdChat")}
        />
        <Input
          className="verify-code-4"
          maxLength={1}
          ref={code_4}
          value={chars.fourthChat}
          onChange={(e) => updateInput(e, "fourthChat")}
        />
      </div>
      <div className="button-container verify-button">
        <Button
          type="primary"
          disabled={!valid}
          loading={loading || resendLoading}
          onClick={sendVerify}
        >
          Verify
        </Button>
      </div>
      <div className="verify-resend">
        <div className="tip">
          Did not receive the email?
          <span className="link-btn" onClick={resendCodeToEmail}>
            Try to resend code
          </span>
        </div>
      </div>
    </div>
  );
};
