import { KV } from "use-reaction";
import { ScheduleInterval, GeoPoint } from "./types";

// env cfg
export const API_HOST = process.env.CFG_API_HOST!;
export const GOOGLE_MAP_KEY = process.env.CFG_GMAP_KEY!;
export const GOOGLE_CLIENT_ID = process.env.CFG_GOOGLE_CLIENT_ID!;
export const FACEBOOK_CLIENT_ID = process.env.CFG_FACEBOOK_CLIENT_ID!;
export const GOOGLE_ANALYTIC_ID = process.env.CFG_GA_TRACK_ID!;
export const SQUARE_APP_ID = process.env.CFG_SQUARE_APP_ID!;
export const SQUARE_LOCATION_ID = process.env.CFG_SQUARE_LOCATION_ID!;
export const SQUARE_SDK = process.env.CFG_SQUARE_SDK!;
export const CFG_DEFAULT_GEO_CENTER: GeoPoint = process.env
  .CFG_DEFAULT_GEO_CENTER!.split(",")
  .reduce(
    (pt, v, idx) => {
      idx === 0 ? (pt.lat = +v) : (pt.lng = +v);
      return pt;
    },
    { lat: 0, lng: 0 }
  );

// router_defines
export const router_ftl_root = "/ftl/";
export const router_ftl_select_role = "/ftl/roles";
export const router_ftl_usr_profile = "/ftl/userprofile";
export const router_ftl_acu_profile = "/ftl/acuprofile";
export const router_ftl_acu_area = "/ftl/acuarea";
export const router_ftl_acu_service = "/ftl/acuservice";
export const router_ftl_finished = "/ftl/finished";
export const router_ftl_acu_experience = "/ftl/acuexperience";
export const router_ftl_acu_clinic = "/ftl/acuclinic";

export const router_login = "/login";
export const router_signup = "/signup";
export const router_reset_password = "/reset-password";
export const router_check_email = "/check-email";
export const router_verify_email = "/verify-email";

export const router_profile = "/profile/:type/:id";
export const router_profile_with_slug = "/profile/:type/:id/:slug";
export const router_profile_with_slug_review = "/profile/:type/:id/:slug/review";
export const router_search_result = "/search-result";
export const router_booking_appointment = "/booking";
export const router_booking_finished = "/booking-finished";
export const router_booking_failed = "/booking-failed";
export const router_policy = "/service-term";
export const router_account_root = "/account/";
export const router_account_setting = "/account/setting";
export const router_account_user_profile = "/account/user";
export const router_account_profile = "/account/profile";
export const router_account_appointment = "/account/appointment";
export const router_account_calendar = "/account/calendar";
export const router_account_card = "/account/card";
export const router_account_review = "/account/review";
export const router_account_payment = "/account/payment";
export const router_account_verify_form = "/account-verify";
export const router_account_clinic = "/account/clinic";

export const router_claim_profile = "/claim-profile";
export const router_claim_verify = "/claim-verify/:id";
export const router_set_pwd = "/set-password";

export const current_year = new Date().getFullYear();
export const graduated_years: number[] = [];

for (let i = 0; i < 100; i++) {
  graduated_years.push(current_year - i);
}

export const email_reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
// export const phone_reg = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
export const phone_reg = /^[\(\)\-0-9\s]{3,}$/;
export const degree_list = [
  "ABT",
  "AcD",
  "AP",
  "ASA",
  "CA, CAc",
  "CH",
  "CT",
  "DAc",
  "D. Acu",
  "DACM",
  "Dipl. ABT",
  "Dipl. Ac.",
  "Dipl. CH",
  "Dipl. OM",
  "DNBAO",
  "DAOM",
  "DOM",
  "DTCM, Dr. TCM",
  "FIAMA",
  "LAc, Lic. Ac.",
  "MAOM",
  "MD",
  "MSOM",
  "MTCM",
  "MTOM",
  "OMD",
  "PhD",
  "RAc, Reg. Ac.",
  "R. TCM. H.",
  "R. TCM. P.",
  "TCMD",
  "DAcOM",
];

export const experience_years = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  ">20",
];

export enum BookStatus {
  BOOKING_REVIEW = "BOOKING_REVIEW",
  BOOKING_SUCCESS = "BOOKING_SUCCESS",
  BOOKING_FAILED = "BOOKING_FAILED",
  CANCEL_SUCCESS = "CANCEL_SUCCESS",
}

export const bookingStatusCfg: KV = {
  [BookStatus.BOOKING_SUCCESS]: {
    label: "Booking success",
    color: "green",
  },
  [BookStatus.BOOKING_FAILED]: {
    label: "Booking failed",
    color: "red",
  },
  [BookStatus.CANCEL_SUCCESS]: {
    label: "Cancel success",
    color: "#7891a8",
  },
  [BookStatus.BOOKING_REVIEW]: {
    label: "Wait for confirm",
    color: "orange",
  },
};
export const scheduleLabelCfg: KV = {
  [ScheduleInterval.ONCE]: {
    label: "One-time",
    color: "#c3a3fd",
  },
  [ScheduleInterval.WEEKLY]: {
    label: "Weekly",
    color: "#ae9cff",
  },
};

export const clinicDoctorStatusCfg = {
  active: {
    label: "Active",
    color: "green",
  },
  pending: {
    label: "Pending",
    color: "red",
  },
};

export const locationStatesOptions = [
  "Non-US country",
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "Washington, D.C",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
