import intl from "react-intl-universal";
import en from "./en";
import cn from "./cn";

const locales = {
  "en-US": en,
  "zh-CN": cn
};

function loadLocales () {
  intl.load(locales);
}

export {
  locales,
  loadLocales
};