import { Skeleton } from "antd";
import intl from "react-intl-universal";
import { useRequest, useResponsive } from "ahooks";
import { getExpertiseAreaList } from "@/web/api/home";

export const SectionExpertises = () => {
  const { data: expertiseAreas = [], loading } =
    useRequest(getExpertiseAreaList);
  const { isPC } = useResponsive();
  return (
    <div className="section find-acupuncturists-section">
      <div className="section-block-container">
        <div className="section-block"></div>
      </div>
      {isPC ? (
        <div className="section-title">
          {intl.get("homepage_find_acupuncturists_for_title")}
        </div>
      ) : (
        <div className="section-title">
          {intl.get("homepage_find_acupuncturists_for_title_mobile_1")} <br />{" "}
          {intl.get("homepage_find_acupuncturists_for_title_mobile_2")}
        </div>
      )}
      <div className="acupuncturist-list">
        {loading ? (
          <Skeleton paragraph={{ rows: 20 }} />
        ) : (
          expertiseAreas.map((item: any, index: number) => (
            <div key={index} className="acupuncturist-item">
              {item.name}
            </div>
          ))
        )}
      </div>
    </div>
  );
};
