import { BasicLayout } from "@/web/comps/basic_layout";
import { VerifyCode } from "@/web/comps/verify_code";
import { action_user_verify, model_user } from "@/web/models/model_user";
import { router_ftl_root } from "@/web/utils/consts";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLoading, useModel } from "use-reaction";
import "./index.scss";
export const Verify = () => {
  const navigate = useNavigate();
  const {
    doAction,
    store: { email, ftlStep, confirmed },
  } = useModel(model_user);
  const loading = useLoading(model_user);

  const sendVerify = (code: string) => {
    doAction(action_user_verify, code, "model");
  };

  useEffect(() => {
    if (confirmed) {
      if (ftlStep === "finished") {
        navigate(-2);
      } else {
        navigate(router_ftl_root);
      }
    }
  }, [confirmed]);
  return (
    <BasicLayout>
      <VerifyCode
        className="verify-page"
        receiver={email}
        loading={loading}
        onSend={sendVerify}
      />
    </BasicLayout>
  );
};
