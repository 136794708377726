import { router_account_setting } from "@/web/utils/consts";
import { Progress } from "antd";
import { Link } from "react-router-dom";

export const AccountSettingProgress = () => {
  return (
    <div className="progress">
      <div className="title">Profile Completion</div>
      <Progress
        type="circle"
        percent={80}
        strokeColor="#449086"
        trailColor="#4490864f"
        strokeWidth={10}
        width={Math.min(140, ((document.body.clientWidth / 1440) * 150) >> 0)}
      />
      <div className="des">
        Please complete these mandatory fields to get your profile Live on Find
        My Acupuncturist
      </div>
      <ul className="items">
        <li>
          <Link to={router_account_setting + "/basic"}>Basic Information</Link>
        </li>
        <li>
          <Link to={router_account_setting + "/security"}>
            Account Security
          </Link>
        </li>
        <li>
          <Link to={router_account_setting + "/verify"}>
            Email/Phone Verify
          </Link>
        </li>
        <li></li>
      </ul>
    </div>
  );
};
