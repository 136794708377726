import { formatNumber } from "@/web/utils/util";
import { useRequest } from "ahooks";
import { Divider, List, Pagination, Select, Skeleton } from "antd";
import { useState } from "react";
import noDataPic from "@/assets/images/icon-no-data.svg";
import { ReviewItem } from "@/web/comps/review_item";
import "./account_user_reviews.scss";
const { Option } = Select;

export const AccountUserReviews = () => {
  const [filter, setFilter] = useState("doctorReview");
  // const { data, loading } = useRequest(getMyReviews);
  const { data, loading } = useRequest(
    async () => {
      //mock
      return filter === "doctorReview"
        ? {
            reviewCount: 2,
            list: [
              {
                id: 1,
                doctor: {
                  name: "Mia zhang",
                },
                updateDate: "2022-05-26",
                cured: 2,
                treatedFor: "Skys",
                timeToRecovery: 2,
                officeVisitTimes: 2,
                rate: 4.5,
                information:
                  "askdl asdkl awoei klasd faiweo alskd joeijlak soiejf laks jdfie4 jal",
                effectivenessRating: 4,
                pleasantryRating: 4,
                staffRating: 4,
              },
              {
                id: 2,
                doctor: {
                  name: "Ashely",
                },
                updateDate: "2022-05-26",
                cured: 2,
                treatedFor: "Jack",
                timeToRecovery: 2,
                officeVisitTimes: 2,
                rate: 4.5,
                information:
                  "askdl asdkl awoei klasd faiweo alskd joeijlak soiejf laks jdfie4 jal",
                effectivenessRating: 4,
                pleasantryRating: 4.6,
                staffRating: 4.5,
              },
            ],
          }
        : null;
    },
    { refreshDeps: [filter] }
  );
  return (
    <div className="account-content-page account-reviews">
      {loading ? (
        <>
          <Skeleton paragraph={{ rows: 4 }} />
          <Skeleton paragraph={{ rows: 4 }} />
          <Skeleton paragraph={{ rows: 4 }} />
          <Skeleton paragraph={{ rows: 4 }} />
        </>
      ) : (
        <div className="list">
          <div className="list-title">
            <div className="title">
              You have sent <span>{formatNumber(data?.reviewCount || 0)}</span>{" "}
              reviews
            </div>
            <div className="filter">
              <span>show:</span>
              <Select
                defaultValue={filter}
                bordered={false}
                onChange={setFilter}
              >
                <Option value="doctorReview">Doctor Review</Option>
                <Option value="clinicReview">Clinic Review</Option>
              </Select>
            </div>
          </div>
          <Divider />
          {data?.list?.length ? (
            <>
              <List
                className="items"
                dataSource={data?.list}
                renderItem={(item) => <ReviewItem data={item} />}
              />
              <Pagination simple current={1} total={100} pageSize={10} />
            </>
          ) : (
            <div className="no-data">
              <img src={noDataPic} alt=""/>
              <div className="no-data-tip">
                Sorry, you haven't reviewed yet.
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
