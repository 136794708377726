import React from "react";
import { Button, Avatar, Modal, Rate } from "antd";
import IconCardBuilding from "@/assets/images/icon-card-building.svg";
import IconCardLocation from "@/assets/images/icon-card-location.svg";
import IconCardMale from "@/assets/images/icon-card-male.svg";
import IconCardPhone from "@/assets/images/icon-card-phone.svg";
import IconCardCureHigh from "@/assets/images/icon-card-cure-high.svg";
import IconCardCureSlow from "@/assets/images/icon-card-cure-slow.svg";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../utils/util";
import "./search_res_card.scss";
import classNames from "classnames";
import { router_profile_with_slug } from "../utils/consts";

export interface SearchResItem {
  profile_name: string;
  profile_medicalTitle?: string;
  profile_id: string;
  profile_gender: string;
  expertise_area_name?: string;

  patient_review_overallRating: number;
  patient_review_count: number;
  clinic_review_overallRating: number;
  cured_rating: number;
  clinic_address: string;
  clinic_name: string;
  clinic_phone: string;
  clinic_id: string;
  clinic_lng: number;
  clinic_lat: number;
  location: string;
  avatar?: string;
  id: string;
}

interface Props {
  type: "doctor" | "clinic";
  info: SearchResItem;
}

// todo: need to refactor data and api of search resutl item
export const SearchResultCard = ({ info, type }: Props) => {
  const navigate = useNavigate();

  const bookAppointmentFunc = () =>
    navigate(
      router_profile_with_slug
        .replace(":type", type)
        .replace(":id", type === "clinic" ? info.clinic_id : info.profile_id)
        .replace( ":slug", (type === "clinic" ? info.clinic_name : info.profile_name).replace( /\s+/g, "-" )
        )
    );

  const goClinicProfilePage = (
    e: React.MouseEvent,
    profileId: number | string,
    name: string
  ) => {
    e.stopPropagation();
    navigate(`/profile/clinic/${profileId}/${name.replace(/\s+/g, '-')}`);
  };

  return (
    <>
      <div
        className={classNames(
          "search-result-card",
          `search-result-card__${type}`
        )}
        onClick={bookAppointmentFunc}
      >
        <div className="card-left">
          <Avatar shape="square" icon={<UserOutlined />} src={info.avatar} />
        </div>
        <div className="card-middle">
          <div className="card-middle-name">
            {(type === "clinic" ? info.clinic_name : info.profile_name) ||
              "USER NAME"}
          </div>
          <div className="score-infos">
            <Rate
              disabled
              className="ant-rate-in-card"
              value={info.patient_review_overallRating}
            />
            <div className="review-account">
              {formatNumber(info.patient_review_count)} review
              {info.patient_review_count > 1 ? "s" : ""}
            </div>
            {
              // curatedRating is a decimal --Miya said
              type === "doctor" && info.cured_rating !== 0 && (
                <div className="cured divide-point">
                  <img
                    src={
                      info.cured_rating > 0.5
                        ? IconCardCureHigh
                        : IconCardCureSlow
                    }
                    alt="icon-card-cure"
                  />
                  {(info.cured_rating * 100).toFixed(0)}% cured.
                </div>
              )
            }
          </div>
          {type === "doctor" && (
            <div className="card-infos">
              {
                <div className="card-infos-item card-infos-role">
                  <div className="icon">
                    <img src={IconCardMale} alt="icon-card-role" />
                  </div>
                  <div className="value">Acupuncturist</div>
                </div>
              }
              {info.clinic_name && (
                <div className="card-infos-item card-infos-agency">
                  <div className="icon">
                    <img src={IconCardBuilding} alt="icon-card-building" />
                  </div>
                  <div className="value">
                    Works at
                    <span
                      className="agency-link"
                      onClick={(e) => {
                        goClinicProfilePage(e, info.clinic_id, info.clinic_name);
                      }}
                    >
                      {info.clinic_name}
                    </span>
                  </div>
                </div>
              )}
              {info.clinic_address && (
                <div className="card-infos-item card-infos-address">
                  <div className="icon">
                    <img src={IconCardLocation} alt="icon-card-location" />
                  </div>
                  <div className="value">{info.clinic_address}</div>
                </div>
              )}
              {info.clinic_phone && (
                <div className="card-infos-item card-infos-phone">
                  <div className="icon">
                    <img src={IconCardPhone} alt="icon-card-phone" />
                  </div>
                  <div className="value">
                    <a href={`tel:${info.clinic_phone}`}>{info.clinic_phone}</a>
                  </div>
                </div>
              )}
              {info.expertise_area_name && (
                <div className="card-expertise">{info.expertise_area_name}</div>
              )}
            </div>
          )}
          {type === "clinic" && (
            <div className="card-infos">
              {info.clinic_address && (
                <div className="card-infos-item card-infos-address">
                  <div className="icon">
                    <img src={IconCardLocation} alt="icon-card-location" />
                  </div>
                  <div className="value">{info.clinic_address}</div>
                </div>
              )}
              {info.clinic_phone && (
                <div className="card-infos-item card-infos-phone">
                  <div className="icon">
                    <img src={IconCardPhone} alt="icon-card-phone" />
                  </div>
                  <div className="value">
                    <a href={`tel:${info.clinic_phone}`}>{info.clinic_phone}</a>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="card-right">
          <Button>view detail</Button>
        </div>
      </div>
    </>
  );
};

export default SearchResultCard;
