import { CommonSearch } from '@/web/comps/common_search';
import intl from 'react-intl-universal';

export const SectionBanner=()=> {
  return (
    <div className="section homepage-banner-section">
      <div className="homepage-banner">
        <div className="banner-title font-family-merriweather">
        { intl.get('homepage_search_slogan') }
        </div>
        <CommonSearch />
        <div className="banner-description">
          { intl.get('homepage_search_description') }
        </div>
      </div>
    </div>
  );
}