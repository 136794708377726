import { Header } from "@/web/comps/header";
import { model_user } from "@/web/models/model_user";
import { logout } from "@/web/utils/ajax";
import {
  router_account_appointment,
  router_account_calendar,
  router_account_card,
  router_account_clinic,
  router_account_payment,
  router_account_profile,
  router_account_review,
  router_account_root,
  router_account_setting,
  router_account_user_profile,
} from "@/web/utils/consts";
import { RoleType } from "@/web/utils/types";
import {
  CalendarOutlined,
  CreditCardOutlined,
  MedicineBoxOutlined,
  MessageOutlined,
  SettingOutlined,
  UserAddOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Menu } from "antd";
import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useModel } from "use-reaction";
import { AccountAcuCalendar } from "./account_acu_calendar";
import { AccountAppointmentDetail } from "./account_appointment_detail";
import { AccountPaymentList } from "./account_payment_list";
import { AccountAcuProfile } from "./account_acu_profile";
import { AccountClinicProfile } from "./account_clinic_profile";
import { AccountAcuProfileExperience } from "./account_acu_profile_experience";
import { AccountAcuProfilePersonal } from "./account_acu_profile_personal";
import { AccountAcuProfileService } from "./account_acu_profile_service";
import { AccountUserAppointmentList } from "./account_user_appointment_list";
import { AccountSettingBasicInfo } from "./account_setting_basic_info";
import { AccountUserReviews } from "./account_user_reviews";
import "./index.scss";
import { AccountSettingSecurity } from "./account_setting_security";
import { AccountSettingVerify } from "./account_setting_verify";
import { AccountSettingDelete } from "./account_setting_delete";
import { AccountSettingResetPwd } from "./account_setting_reset_pwd";
import { AccountClinicDoctorList } from "./account_clinic_doctor_list";
import { AccountClinicDoctor } from "./account_clinic_doctor";
import { AccountClinicBizHr } from "./account_clinic_biz_hr";
import { AccountUserCards } from "./account_user_cards";
import { useIsClinicAdmin } from "@/web/utils/util";
const setting_key = router_account_setting.replace(router_account_root, "");
const user_key = router_account_user_profile.replace(router_account_root, "");
const profile_key = router_account_profile.replace(router_account_root, "");
const calendar_key = router_account_calendar.replace(router_account_root, "");
const apmt_key = router_account_appointment.replace(router_account_root, "");
const card_key = router_account_card.replace(router_account_root, "");
const review_key = router_account_review.replace(router_account_root, "");
const payment_key = router_account_payment.replace(router_account_root, "");
const clinic_key = router_account_clinic.replace(router_account_root, "");

// sub menu key of acccount setting
const setting_basic = "basic";
const setting_security = "security";
const setting_pwd = "password";
const setting_verify = "verify";
const setting_delete = "delete";
// sub menu key of acu's profile:
const acu_profile_personal = "me";
const acu_profile_experience = "experience";
const acu_profile_service = "service";
// sub menu key of clinic
const clinic_profile = "profile";
const clinic_doctor = "doctor";
const clinic_biz_hr = "time";

export const Account = () => {
  const navigate = useNavigate();
  const {
    store: { role },
  } = useModel(model_user);

  const isClinicAdmin = useIsClinicAdmin();

  const acu_profile_sub_navs = [
    {
      label: "About Me",
      key: `${profile_key}/${acu_profile_personal}`,
    },
    {
      label: "Education & Experiences",
      key: `${profile_key}/${acu_profile_experience}`,
    },
    {
      label: "Services",
      key: `${profile_key}/${acu_profile_service}`,
    },
  ];
  const account_setting_sub_navs = [
    {
      label: "Account Security",
      key: `${setting_key}/${setting_security}`,
    },
    {
      label: "Reset Password",
      key: `${setting_key}/${setting_pwd}`,
    },
    {
      label: "Account Verify",
      key: `${setting_key}/${setting_verify}`,
    },
    {
      label: "Delete My Account",
      key: `${setting_key}/${setting_delete}`,
    },
  ];
  const account_clinic_sub_navs = [
    {
      label: "Clinic Info",
      key: `${clinic_key}/${clinic_profile}`,
    },
    ...(isClinicAdmin
      ? [
          {
            label: "Associate Doctors",
            key: `${clinic_key}/${clinic_doctor}`,
          },
          {
            label: "Business Hours",
            key: `${clinic_key}/${clinic_biz_hr}`,
          },
        ]
      : []),
  ];
  const navs = [
    ...(role === RoleType.ACUPUNCTURIST
      ? [
          {
            label: "My Calendar",
            key: calendar_key,
            icon: <CalendarOutlined />,
          },
          {
            label: "Payments",
            key: payment_key,
            icon: <WalletOutlined />,
            className: 'with-gap'
          },
        ]
      : [
          {
            label: "My Appointments",
            key: apmt_key,
            icon: <CalendarOutlined />,
          },
        ]),
    {
      label: "User Profile",
      key: user_key,
      icon: <UserOutlined />,
    },
    ...(role === RoleType.USER
      ? [
          {
            label: "Account Setting",
            key: setting_key,
            icon: <SettingOutlined />,
            children: account_setting_sub_navs,
            className: 'with-gap'
          },
          {
            label: "My Card",
            key: card_key,
            icon: <CreditCardOutlined />,
          },
          {
            label: "My Review",
            key: review_key,
            icon: <MessageOutlined />,
          },
        ]
      : [
          {
            label: "Practitioner Profile",
            key: profile_key,
            icon: <UserAddOutlined />,
            children: acu_profile_sub_navs,
          },
          {
            label: "Clinic Profile",
            key: clinic_key,
            icon: <MedicineBoxOutlined />,
            children: account_clinic_sub_navs,
            className: 'with-gap'
          },
          {
            label: "Account Setting",
            key: setting_key,
            icon: <SettingOutlined />,
            children: account_setting_sub_navs,
          },
        ]),
  ];
  const mb_header_navs = [
    ...navs.reduce((arr, cur) => {
      if (cur.children) {
        arr = arr.concat(cur.children as any);
      } else {
        const node = { ...cur, icon: undefined };
        arr.push(node);
      }
      return arr;
    }, [] as any[]),
    {
      label: "Logout",
      key: "logout",
    },
  ];

  const [nav, setNav] = useState(
    navs.find((_) => window.location.href.includes(_.key))?.key || profile_key
  );
  const toNav = (key: string) => {
    setNav(key);
    navigate(router_account_root + key);
  };
  const pageTitle = acu_profile_sub_navs
    .concat(navs, account_setting_sub_navs, account_clinic_sub_navs)
    .find((_) => _.key === nav)?.label;

  return (
    <>
      <Header
        whiteBg
        mobileHeaderMenu={
          <Menu
            items={mb_header_navs}
            selectedKeys={[nav]}
            className="mbl-head-menu account-head-menu"
            mode="vertical"
            onClick={({ key }) => (key === "logout" ? logout() : toNav(key))}
          />
        }
      />
      <div className="account-page">
        <Menu
          items={navs}
          selectedKeys={[nav]}
          mode="inline"
          className="account-nav"
          onClick={({ key }) => toNav(key)}
        />
        <div className="account-content">
          <Breadcrumb className="bread-nav">
            <Breadcrumb.Item href={router_account_root}>
              Account
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="content-title">{pageTitle}</div>
          <Routes>
            <Route key={setting_key} path={setting_key}>
              <Route index element={<AccountSettingSecurity />} />
              <Route
                path={setting_security}
                element={<AccountSettingSecurity />}
              />
              <Route path={setting_verify} element={<AccountSettingVerify />} />
              <Route path={setting_pwd} element={<AccountSettingResetPwd />} />
              <Route path={setting_delete} element={<AccountSettingDelete />} />
            </Route>
            <Route path={user_key} element={<AccountSettingBasicInfo />} />
            {role === RoleType.ACUPUNCTURIST && (
              <Route
                key={profile_key}
                path={profile_key}
                element={<AccountAcuProfile />}
              >
                <Route
                  path={acu_profile_personal}
                  element={<AccountAcuProfilePersonal />}
                />
                <Route index element={<AccountAcuProfilePersonal />} />
                <Route
                  path={acu_profile_experience}
                  element={<AccountAcuProfileExperience />}
                />
                <Route
                  path={acu_profile_service}
                  element={<AccountAcuProfileService />}
                />
              </Route>
            )}
            {role === RoleType.USER && (
              <Route
                path={card_key}
                key={card_key}
                element={<AccountUserCards />}
              />
            )}
            <Route
              key={calendar_key}
              path={calendar_key}
              element={<AccountAcuCalendar />}
            />
            <Route key={apmt_key} path={apmt_key}>
              <Route index element={<AccountUserAppointmentList />} />
              <Route path=":id" element={<AccountAppointmentDetail />} />
            </Route>
            <Route
              key={review_key}
              path={review_key}
              element={<AccountUserReviews />}
            />
            <Route key={payment_key} path={payment_key}>
              <Route index element={<AccountPaymentList />} />
              <Route path=":id" element={<AccountAppointmentDetail />} />
            </Route>
            <Route key={clinic_key} path={clinic_key}>
              <Route index element={<AccountClinicProfile />} />
              <Route path={clinic_profile} element={<AccountClinicProfile />} />
              <Route path={clinic_doctor}>
                <Route index element={<AccountClinicDoctorList />} />
                <Route path=":id" element={<AccountClinicDoctor />} />
              </Route>
              <Route path={clinic_biz_hr} element={<AccountClinicBizHr />} />
            </Route>
          </Routes>
        </div>
      </div>
    </>
  );
};
