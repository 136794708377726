import { fetchAccountVerifyCode, putAccountBinding } from "@/web/api/user";
import { BasicLayout } from "@/web/comps/basic_layout";
import { VerifyCode } from "@/web/comps/verify_code";
import { useRequest } from "ahooks";
import { Button, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import "./account_verify_form.scss";
export const AccountVerifyForm = () => {
  const { state } = useLocation();
  const { type } = state as any;
  const [receiver, setReceiver] = useState("");
  const navigate = useNavigate();
  const [form] = useForm();

  const { run: fetchVerify, loading } = useRequest(
    async (values: any) => {
      // call api
      await fetchAccountVerifyCode({ type, receiver: values.receiver });
      // then go step 2
      setReceiver(values.receiver);
    },
    { manual: true }
  );
  const { run: sendVerify, loading:loading2 } = useRequest(
    async (verifyCode: string) => {
      // call api to send
      await putAccountBinding({
        verifyCode,
        ...(type === "email" ? { email: receiver } : { userPhone: receiver }),
      });
      message.success('saved success!', 2, back)
    },
    { manual: true }
  );
  const back = () => navigate(-1);
  return (
    <BasicLayout>
      {!receiver ? (
        <div className="account-verify-form">
          <div className="title">Verify {type}</div>
          <div className="container">
            <Form layout="vertical" form={form} onFinish={fetchVerify}>
              {type === "email" ? (
                <Form.Item
                  label="email"
                  name="receiver"
                  rules={[{ required: true, message: "input email" }]}
                >
                  <Input type="email" placeholder="input email" />
                </Form.Item>
              ) : (
                <Form.Item
                  label="phone"
                  name="receiver"
                  rules={[{ required: true, message: "input phone number" }]}
                >
                  <Input prefix="+1" />
                </Form.Item>
              )}
            </Form>
          </div>
          <div className="submit-btn">
            <Button onClick={back}>cancel</Button>
            <Button type="primary" loading={loading} onClick={form.submit}>
              send
            </Button>
          </div>
        </div>
      ) : (
        <VerifyCode
          className="account-verify-form"
          receiver={receiver}
          loading={loading2}
          onSend={sendVerify}
        />
      )}
    </BasicLayout>
  );
};
