import { ajax } from "../utils/ajax";

export const userSignup = (data: { account: string; password: string }) =>
  ajax("/auth/register", {
    type: "post",
    data,
  });
export const userLogin = (data: { account: string; password: string }) =>
  ajax("/auth/login", {
    type: "post",
    data,
  });
export const googleLogin = (data: { idToken: string }) =>
  ajax("/auth/google-login", {
    type: "post",
    data,
  });
export const facebookLogin = (data: { email: string; userID: string }) =>
  ajax("/auth/facebook-login", {
    type: "post",
    data,
  });
export const userVerify = (id: number, verificationCode: string) =>
  ajax(`/auth/confirmation/${id}`, {
    type: "post",
    data: { verificationCode },
  });
export const sendConfirmationEmail = (email: string) =>
  ajax(`users/send-confirmation-email`, { type: "post", data: { email } });
export const sendConfirmationSms = (phone: string) =>
  ajax(`users/send-confirmation-sms`, { type: "post", data: { phone } });

export const getUserInfo = () => ajax("/users/me", {cacheTTL: 6});

export const verifyCodeWhenResetPassword = (
  userId: number,
  verificationCode = ""
) =>
  ajax(`auth/confirm-password-reset/${userId}`, {
    type: "post",
    data: {
      verificationCode,
    },
  });
export const resetPassword = (newPassword = "") =>
  ajax(`auth/reset-password`, {
    type: "post",
    data: {
      newPassword,
    },
  });
export const accountResetPassword = (data: any) =>
  ajax(`users/reset-password`, {
    type: "put",
    data,
  });
export const userClaim = (unclaimedDoctorId: number) =>
  ajax("/users/claim", { type: "post", data: { unclaimedDoctorId } });
export const userDiscardClaim = (unclaimedDoctorId: number) =>
  ajax("/users/claim-discard", { type: "post", data: { unclaimedDoctorId } });

export const getUserBasicInfo = () => ajax("/users/basicinfo");
export const putUserBasicInfo = (data: Record<string, any>) =>
  ajax("/users/basicinfo", { type: "put", data });

export const getUserSecurity = () => ajax("/users/security");
export const putUserSecurity = (data: Record<string, any>) =>
  ajax("/users/security", { type: "put", data });
export const postUserCheckPwd = (checkPassword: string) =>
  ajax("/users/verifyuser", { type: "post", data: { checkPassword } });
export const putAccountBinding = (data: any) =>
  ajax("/users/account-verify", {type: 'put', data});
export const fetchClaimVerifyCode = (data: any) =>
  ajax("/users/claim-verify-fetch-code", { type: "post", data });
export const checkClaimVerifyCode = (data: any) =>
  ajax("/users/claim-verify-check-code", { type: "post", data });
export const fetchAccountVerifyCode = (data: any) =>
  ajax("/users/account-verify-fetch-code", { type: "post", data });
export const putDeleteAccount = (data: any) =>
  ajax("/users/accountdelete", { type: "put", data });
