import { useEffect, useMemo, useState } from "react";
import { Form, Input, Button, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { VerifyCode } from "@/web/comps/verify_code";
import {
  resetPassword,
  sendConfirmationEmail,
  sendConfirmationSms,
  verifyCodeWhenResetPassword,
} from "@/web/api/user";
import { BasicLayout } from "@/web/comps/basic_layout";
import "./index.scss";
import { setToken } from "@/web/utils/ajax";
import { useNavigate } from "react-router";
import { router_login } from "@/web/utils/consts";
import { useRequest } from "ahooks";

export const ResetPwd = () => {
  const [userId, setUserId] = useState(0);
  const [account, setAccount] = useState("");
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const [form] = useForm();
  useEffect(() => form.resetFields(), [step]);

  const { run: getVerifyCode, loading } = useRequest(
    async () => {
      const account = form.getFieldValue("account");
      const res = await (account.includes("@")
        ? sendConfirmationEmail(account)
        : sendConfirmationSms(account));
      setAccount(account);
      setUserId(res.id);
      setStep(step + 1);
    },
    { manual: true }
  );

  const { run: sendResetVerify, loading: loading2 } = useRequest(
    async (code: string) => {
      const res = await verifyCodeWhenResetPassword(userId, code);
      setToken(res.access_token); // reset pwd use different token
      setStep(step + 1);
    },
    { manual: true }
  );

  const { run: submitPassword, loading: loading3 } = useRequest(
    async () => {
      const values = await form.validateFields();
      if (values.newPassword === values.newPasswordConfirm) {
        await resetPassword(values.newPassword);
        setToken(""); // clear token, then go to login
        message.success("New password saved!");
        // back to login
        navigate(router_login, { replace: true });
      } else {
        message.error("The password entered twice is not the same！");
      }
    },
    { manual: true }
  );
  const cfg = useMemo(() => {
    let title = "";
    let description = "";
    if (step === 1) {
      title = "Password Reset";
      description =
        "Seems like you forgot your password. If this is true, please enter your email or phone number.";
    } else if (step === 2) {
      title = "";
      description = "";
    } else {
      title = "Choose a new password";
      description = "Please choose a new password for your account";
    }
    return { title, description };
  }, [step]);

  return (
    <BasicLayout>
      <div className="reset-password-page">
        <div className="account-page-block"></div>
        <div className="account-page-title font-family-merriweather">
          {cfg.title}
        </div>
        <div className="account-page-description">{cfg.description}</div>
        {step === 1 ? (
          <Form className="reset-pwd-form" layout="vertical" form={form}>
            <Form.Item
              name="account"
              label="Email / Phone"
              rules={[
                {
                  required: true,
                  message: "Please input your email or phone number!",
                },
              ]}
            >
              <Input placeholder="Email address or phone number" />
            </Form.Item>
            <div className="form-item-button">
              <Button
                type="primary"
                block={true}
                style={{ height: "56px" }}
                onClick={getVerifyCode}
                loading={loading}
              >
                Reset
              </Button>
            </div>
          </Form>
        ) : step === 2 ? (
          <VerifyCode
            className="fill-verification-code"
            receiver={account}
            loading={loading2}
            onSend={sendResetVerify}
          />
        ) : (
          <Form form={form} layout="vertical" className="new-password-box">
            <div className="form-item">
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Missing new password!",
                  },
                ]}
              >
                <Input type="password" placeholder="Your new password here" />
              </Form.Item>
            </div>
            <div className="form-item">
              <Form.Item
                name="newPasswordConfirm"
                label="Confirm new password"
                rules={[
                  {
                    required: true,
                    message: "Missing new password!",
                  },
                ]}
              >
                <Input
                  type="password"
                  placeholder="Confirm your new password"
                />
              </Form.Item>
            </div>
            <div className="form-item form-item-submit">
              <Button
                type="primary"
                loading={loading3}
                onClick={submitPassword}
              >
                Submit Password
              </Button>
            </div>
          </Form>
        )}
      </div>
    </BasicLayout>
  );
};
