import { ajax } from "../utils/ajax";

export const getBooked = async (params: {
  doctorId?: string | number;
  date?: Date;
  patientId?: string | number;
  clinicId?: string | number;
  dateRange?: Date[];
  limit?: number;
  page?: number;
}) => {
  const res = await ajax("/appointment/all", { type: "get", params });
  return {
    total: res.total,
    list: res.items,
  };
};

export const getBookedById = (id: string) => ajax("/appointment/book/" + id);

export const postBooking = (data: any) =>
  ajax("/appointment/book", { type: "put", data });
export const acuAddBooking = (data: any) =>
  ajax("/appointment/acu-book", { type: "put", data });
export const payBooking = (id: number) =>
  ajax("/appointment/deposit/" + id, { type: "post" });
export const getServiceById = (id: string | number) =>
  ajax("/appointment/service/" + id);
export const getUserCards = () => ajax("/appointment/user-cards");

export const getMyBooked = async ({ opt, ...params }: any) => {
  const res = await ajax("/appointment/my/" + opt, { type: "get", params });
  return {
    total: res.total,
    list: res.items,
  };
};

export const userCancelBooked = async (id: number | string) =>
  ajax("/appointment/user-cancel/" + id, { type: "post" });

export const acuCancelBooked = async (id: number | string) =>
  ajax("/appointment/acu-cancel/" + id, { type: "post" });

export const acuClaimBooked = async (id: number | string) =>
  ajax("/appointment/acu-claim/" + id, { type: "post" });

export const getMycards = () =>
  ajax("/appointment/user-cards", { cacheTTL: 0 });

export const deleteCard = async (id: string) =>
  ajax("/appointment/del-card/" + id, { type: "delete" });
