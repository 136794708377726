import { ajax } from "../utils/ajax";
import { DataItem } from "../utils/types";

export const getFtlStep = () => ajax("/ftl/ftlstep", { cacheTTL: 0 });
export const putFtlStep = (ftlStep: string) =>
  ajax("/ftl/ftlstep", { type: "put", data: { ftlStep } });

export const postFtlRole = (role: number) =>
  ajax("/ftl/roles", { type: "post", data: { role } });

export const getFtlUserProfile = () => ajax("/ftl/userprofile");
export const putFtlUserProfile = (data: {
  name: string;
  gender: string;
  userPhone: string;
}) => ajax("/ftl/userprofile", { type: "put", data });

export const getFtlacuProfile = () => ajax("/ftl/acuprofile");
export const putFtlacuProfile = (data: {
  name: string;
  gender: string;
  medicalTitle: string;
  languageIds: number[];
}) => ajax("/ftl/acuprofile", { type: "put", data });

export const getFtlacuClinic = () => ajax("/ftl/acuclinic");
export const putFtlacuClinic = (data: any) =>
  ajax("/ftl/acuclinic", { type: "put", data });

export const getFtlacuLanguages = () => ajax("/ftl/acu/languages");
export const putFtlacuLanguages = (languageIds: number[]) =>
  ajax("/ftl/acu/languages", { type: "put", data: { languageIds } });

export const getFtlacuArea = () => ajax("/ftl/acuarea");
export const putFtlacuArea = (data: {
  treatmentTechniqueDescription?: string;
  treatmentTechniques: DataItem[];
  expertiseAreas: DataItem[];
}) => ajax("/ftl/acuarea", { type: "put", data });
export const getFtlacuExperiences = () => ajax("/ftl/acuexperience");
export const putFtlacuExperiences = (data: {
  educations: { school: string; degree: string; graduatedYear: string }[];
  treatmentTechniques: {
    companyName: string;
    position: string;
    experience: string;
  }[];
  certificates: { name: string; issueDate: string }[];
  licenses: { name: string; issueDate: string }[];
  accomplishments: { content: string }[];
  selfIntroduction: string;
}) => ajax("/ftl/acuexperience", { type: "put", data });

export const getFtlacuServices = () => ajax("/ftl/acuservice");
export const putFtlacuServices = (data: {
  treatmentServices: {
    name: string;
    duration: string;
    price: string;
  }[];
}) => ajax("/ftl/acuservice", { type: "put", data });
