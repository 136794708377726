import classNames from "classnames";
import { useRef, useState } from "react";
import "./google_login_btn.scss";
interface Props {
  clientId: string;
  jsSrc?: string;
  className?: string;
  onSuccess: (credential: string) => void;
  width?: number; // max: 400
  type?: "standard" | "icon";
  text?: 'signin' | 'signin_with' | 'signup_with' | 'continue_with'
}
export const useInit = (
  src: string,
  clientId: string,
  callback: (...arg: any) => void
) => {
  const ctx = useRef<Promise<boolean>>();
  (window as any)["ggloginCB"] = callback;
  const [loaded, setLoaded] = useState(false);
  ctx.current =
    ctx.current ||
    new Promise<boolean>((_) => {
      const glEl = document.createElement("div");
      glEl.id = "g_id_onload";
      glEl.dataset.client_id = clientId;
      glEl.dataset.context = "signin";
      glEl.dataset.ux_mode = "popup";
      glEl.dataset.callback = "ggloginCB";
      glEl.dataset.auto_prompt = "false";
      document.body.insertAdjacentElement("beforeend", glEl);
      const scriptEl = document.createElement("script");
      scriptEl.className = "ggl-login-ele";
      scriptEl.src = src;
      document.body.insertAdjacentElement("beforeend", scriptEl);
      scriptEl.onload = () => {
        setLoaded(true);
        _(true);
      };
    });
  return { loaded };
};
export const GoogleLoginBtn = ({
  clientId,
  jsSrc = "https://accounts.google.com/gsi/client",
  className = "",
  onSuccess,
  type = "standard",
  width,
}: Props) => {
  const gbtn = useRef<any>();
  const { loaded } = useInit(jsSrc, clientId, (data: any) => {
    console.log("ggl back", data);
    if (data.credential) {
      onSuccess(data.credential);
    }
  });
  return (
    <span
      className={classNames(
        "g_id_signin",
        "google-login-btn",
        className,
        !loaded && "disabled"
      )}
      data-type={type}
      id="ggl_login_btn"
      data-shape="rectangular"
      data-text="signin_with"
      data-size="large"
      data-theme="filled_blue"
      data-logo_alignment="left"
      {...(width ? { "data-width": width } : {})}
      ref={gbtn}
    ></span>
  );
};
