import { Modal, Form, Rate, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { reviewClinic } from "../api/review";
import "./review_clinic_modal.scss";

const initValues = {
  clinicsRating: 0,
  easyToBooking: 0,
  insuranceCovered: undefined,
};

interface Props {
  clinicId: string;
  clinicName: string;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}
export const ReviewClinicModal = ({
  clinicId,
  clinicName,
  visible,
  setVisible,
}: Props) => {
  const [form] = useForm();

  const onFinish = async (values: any) => {
    await reviewClinic({ ...values, clinicId });
    message.success("thank your for your feedback!");
    setVisible(false);
  };
  return (
    <Modal
      visible={visible}
      width={820}
      closable={false}
      keyboard={false}
      maskClosable={false}
      destroyOnClose={true}
      wrapClassName="review-doctor-modal review-clinic-modal"
      forceRender={true}
      okText="done"
      onCancel={(e) => setVisible(false)}
      onOk={form.submit}
    >
      <div className="modal-header modal-header__clinic">
        <div className="title">Review Clinic</div>
        <div className="desc">
          Review for: <b>{clinicName}</b>
        </div>
      </div>

      <Form
        name="reviewDoctors"
        form={form}
        onFinish={onFinish}
        initialValues={initValues}
        className="review-doctor-form"
      >
        <div className="review-form-item">
          <div className="label">OVERALL RATING</div>
          <Form.Item name="overallRating" className="rate">
            <Rate style={{ fontSize: "34px" }} />
          </Form.Item>
        </div>
        <div className="review-form-item">
          <div className="label">ENVIRONMENT</div>
          <Form.Item name="environmentRating" className="rate">
            <Rate style={{ fontSize: "34px" }} />
          </Form.Item>
        </div>
        <div className="review-form-item">
          <div className="label">STAFF</div>
          <Form.Item name="staffRating" className="rate">
            <Rate style={{ fontSize: "34px" }} />
          </Form.Item>
        </div>
        <div className="review-form-item">
          <div className="label">TIMELINESS</div>
          <Form.Item name="timeRating" className="rate">
            <Rate style={{ fontSize: "34px" }} />
          </Form.Item>
        </div>
        <div className="review-form-item">
          <div className="label">MORE INFORMATION</div>
          <Form.Item name="comments">
            <TextArea placeholder="How can we do better next time" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
