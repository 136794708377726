import { Action, Model } from "use-reaction";
export const SORT_OPTIONS = [
  {
    label: "Distance (Near to Far)",
    value: "geodist() asc",
  },
  {
    label: "Number of reviews - Large to Small",
    value: "reviews",
  },
  {
    label: "Rating - High to Low",
    value: "rating",
  },
  {
    label: "% cured - High to Low",
    value: "cured",
  },
];

export interface SearchFilterOpts extends Model {
  keyword?: string;
  expertise?: string[];
  type: "clinic" | "doctor";
  distance?: number | string; // unit miles
  rating?: number | string;
  lat?: number | string;
  lng?: number | string;
  sortBy: string;
  page: number | string;
  limit: number | string;
}
export const model_search: SearchFilterOpts = {
  NAME: "search_filter",
  type: "clinic",
  distance: 100,
  rating: 2.5,
  sortBy: SORT_OPTIONS[0].value,
  page: 0,
  limit: 10,
  expertise: undefined,
};

export const action_cache_search_filter: Action<SearchFilterOpts> = ({
  payload,
}) => payload;
