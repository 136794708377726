import qs from "qs";
import { useLocation } from "react-router-dom";
import { ClinicRole, DataItem, Opt, RoleType } from "./types";
import IconVisa from "@/assets/images/icon-visa.svg";
import { useModel } from "use-reaction";
import { model_user } from "../models/model_user";

export const dataItem2Opt = <T>(item: DataItem & T) => ({
  ...item,
  label: item.name,
  value: item.id,
});
export const opt2DataItem = <T>(opt: Opt & T) => ({
  ...opt,
  id: +opt.value!,
  name: opt.label!,
});
export const getRoleName = (role: any): string => {
  if (typeof role === "number") {
    switch (role as RoleType) {
      case RoleType.ACUPUNCTURIST:
        return "acupuncturist";
      case RoleType.USER:
        return "patient";
      default:
        return "user";
    }
  }
  if (Array.isArray(role)) {
    return getRoleName(role[0]);
  }
  return role;
};
export const formatNumber = (number: string | number) => {
  const num = +number;
  if (isNaN(num)) return number;
  return num.toString().replace(/(\d)(?=(\d{3})+(\.|$))/g, "$1,");
};

export const getSumOfArr = (arr: number[]) => {
  return arr.reduce((v, c) => v + c, 0);
};
export function useQuery<Query extends { [k: string]: any }>(): Query {
  const location = useLocation();
  return (qs.parse(location?.search?.replace("?", ""), { parseArrays: true }) ||
    {}) as Query;
}
export function getCardIcon(brand: string) {
  switch (brand) {
    case "VISA":
    default:
      return IconVisa;
  }
}
export function useIsClinicAdmin() {
  const {
    store: { role, profile, clinicRole },
  } = useModel(model_user);
  return !!(
    role === RoleType.ACUPUNCTURIST &&
    clinicRole.length &&
    clinicRole.some(
      (c) =>
        c.clinicId === profile.clinicId && c.role === ClinicRole.CLINICADMIN
    )
  );
}
// cache
type CacheData = {
  key: string;
  value: any;
  ttl: number; // time to live in seconds
};
export class CachePool {
  constructor(
    private _max = 10,
    private _store = new Map<string, CacheData>()
  ) {}

  set(key: string, value: any, ttl: number) {
    const data = this._store.get(key) || { key, value, ttl };
    data.ttl = Date.now() / 1000 + ttl; // set to timestamp
    this._store.set(key, data);
    // clear half old on exceed limit
    if (this._store.size > this._max) {
      const oldest = Array.from(this._store.values()).sort(
        (a, b) => a.ttl - b.ttl
      );
      const halfIdx = oldest.length >> 1;
      for (let i = 0; i < halfIdx; i++) {
        this._store.delete(oldest[i].key);
      }
    }
  }

  get(key?: string) {
    if (!key) {
      return null;
    }
    const data = this._store.get(key);
    if (data) {
      if (data.ttl > Date.now() / 1000) {
        return data.value;
      } else {
        // expired
        this._store.delete(key);
      }
    }
    return null;
  }
}
