import React from "react";
import App from "./web/app";
import reportWebVitals from "./web/reportWebVitals";
import "./assets/styles/common.scss";
import { createRoot } from "react-dom/client";

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
process.env.NODE_ENV === "development" && reportWebVitals(console.log);
