import { getMyBooked } from "@/web/api/booking";
import { action_booking_edit, model_booking } from "@/web/models/model_booking";
import {
  bookingStatusCfg,
  BookStatus,
  router_account_appointment,
  router_booking_appointment,
} from "@/web/utils/consts";
import { useAntdTable } from "ahooks";
import { Button, Table, Tabs, Tag } from "antd";
import moment from "moment";
import qs from "qs";
import { useNavigate } from "react-router-dom";
import { useModel } from "use-reaction";
import "./account_user_appointment_list.scss";

export const AccountUserAppointmentList = () => {
  const navigate = useNavigate();
  const { doAction } = useModel(model_booking);

  const { tableProps: activeList } = useAntdTable(
    async ({ current, pageSize }) =>
      await getMyBooked({ opt: "active", page: current, limit: pageSize }),
    {
      defaultPageSize: 10,
    }
  );
  const { tableProps: historyList } = useAntdTable(
    async ({ current, pageSize }) =>
      await getMyBooked({ opt: "histories", page: current, limit: pageSize }),
    {
      defaultPageSize: 10,
    }
  );
  const goDetail = async (item: any) => {
    if (item.status === BookStatus.BOOKING_SUCCESS || item.status === BookStatus.CANCEL_SUCCESS) {
      navigate(router_account_appointment + "/" + item.id);
    } else {
      const query = "?" + qs.stringify({ id: item.id });
      navigate(router_booking_appointment + query);
    }
  };
  const columns: any[] = [
    {
      title: "Date",
      key: "dateTime",
      dataIndex: "dateTime",
      width: 120,
      fixed: "left",
      render: (dateTime: string) => moment(dateTime).format("MMMM DD,YYYY"),
    },
    {
      title: "Time",
      key: "time",
      dataIndex: "time",
      render: (_: string, item: any) =>
        `${moment(item.dateTime).format("HH:mm")}-${moment(item.dateTime)
          .add(item.serviceDuration, "m")
          .format("HH:mm")}`,
    },
    {
      title: "Doctor",
      key: "doctorName",
      dataIndex: "doctorName",
    },
    {
      title: "Clinic",
      key: "clinicName",
      dataIndex: "clinicName",
    },
    {
      title: "Book for",
      key: "bookFor",
      dataIndex: "bookFor",
      render: (bookFor: string, item: any) =>
        bookFor === "myself" ? bookFor : item.patientName,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (st: BookStatus) => (
        <Tag color={bookingStatusCfg[st]?.color || "default"}>
          {bookingStatusCfg[st]?.label || st}
        </Tag>
      ),
    },
    {
      key: "action",
      render: (item: any) => (
        <Button onClick={() => goDetail(item)}>View</Button>
      ),
    },
  ];
  return (
    <div className="account-content-page account-appointment">
      <Tabs>
        <Tabs.TabPane key="active" tab="Active">
          <Table
            {...activeList}
            rowKey="id"
            columns={columns}
            scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="navigate" tab="Histories">
          <Table
            {...historyList}
            rowKey="id"
            columns={columns}
            scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};
