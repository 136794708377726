import { Action } from "use-reaction";
import intl from "react-intl-universal";
import { locales } from "@/web/locales";
export const model_common = {
  intl_ready: false,
};
type M = typeof model_common;

export const action_init_intl: Action<M> = async ({ payload: lang }) => {
  await intl.init({
    currentLocale: lang,
    locales,
  });
  return { intl_ready: true };
};
