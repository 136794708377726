import { ajax } from "../utils/ajax";
import { searchExpertiseCommon } from "./options";

export const getOurStatistics = () => ajax("/users/statistics");
export const getExpertiseAreaList = async (limit = 40) => {
  let data = await searchExpertiseCommon();
  if (data?.length > limit) {
    data = data.slice(0, limit);
  }
  return data;
};
