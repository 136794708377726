import { UserOutlined } from "@ant-design/icons";
import { useResponsive } from "ahooks";
import { Avatar, Button, Checkbox, Rate } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { router_profile_with_slug } from "../utils/consts";
import { formatNumber } from "../utils/util";
import "./profile_associate_doctor.scss";

interface Props {
  avatar: string;
  doctorId: string;
  doctorName: string;
  medicalTitle: string;
  expertiseAreas: any[];
  rank: number;
  reviewCount: number;
  active: boolean;
  chosen?: boolean;
  onChosen?: (doctorId: string) => void;
}
export const ProfileAssociateDoctor = ({
  avatar,
  doctorId,
  doctorName,
  medicalTitle,
  expertiseAreas,
  rank,
  reviewCount,
  chosen,
  onChosen,
}: Props) => {
  const { isPC } = useResponsive();
  const navigate = useNavigate();
  const viewDoctor = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(
      router_profile_with_slug
        .replace(":type", "doctor")
        .replace(":id", doctorId)
        .replace(":slug", doctorName.replace(/\s+/g, "-"))
    );
  };
  return (
    <div
      className="book-card"
      onClick={(e) => (isPC ? onChosen && onChosen(doctorId) : viewDoctor(e))}
    >
      <div className="book-card-left">
        <Avatar
          size={Math.min(((document.body.clientWidth / 1440) * 88) >> 0, 88)}
          icon={<UserOutlined />}
          src={avatar}
        />
      </div>
      <div className="book-card-middle">
        <div className="doctor-name">{doctorName}</div>
        <div className="medical-title">{medicalTitle}</div>
        <div className="reviews-sec">
          <Rate className="ant-rate-in-card" disabled value={rank} />
          <span className="reviews">{formatNumber(reviewCount)} reviews</span>
        </div>
        <div className="description">
          {expertiseAreas?.map((_) => _.name).join(", ")}
        </div>
      </div>
      <div className="book-card-right" hidden={!onChosen}>
        <Button onClick={viewDoctor} className="btn-view-detail">
          View Detail
        </Button>
        <Checkbox checked={chosen} />
      </div>
    </div>
  );
};
