import IconStarActive from "@/assets/images/icon-star-active.svg";
import { Select, Progress } from "antd";
import { formatNumber } from "../utils/util";
import "./profile_doctor_reviews.scss";
import { ReviewItem } from "./review_item";

const test_reivew_item = {
  id: 1,
  doctor: {
    name: "Mia zhang",
  },
  updateDate: "2022-05-26",
  cured: 2,
  treatedFor: "Skys",
  timeToRecovery: 2,
  officeVisitTimes: 2,
  rate: 4.5,
  information:
    "askdl asdkl awoei klasd faiweo alskd joeijlak soiejf laks jdfie4 jal",
  effectivenessRating: 4,
  pleasantryRating: 4,
  staffRating: 4,
};

interface Props {
  id: string;
}
export const ProfileDoctorReviews = ({ id }: Props) => {
  //todo: use doctor id to fetch real reviews

  return (
    <div className="patient-reviews doctor-patient-reviews clinic-patient-reviews">
      <div className="score-filter">
        <Select>
          <Select.Option value="All health problems">
            All health problems
          </Select.Option>
        </Select>
      </div>

      <div className="score-container">
        <div className="score-container-left">
          <div className="title">Overall</div>
          <div className="score">4.35</div>
          <div className="account">{formatNumber(8882)} reviews</div>
        </div>
        <div className="score-container-right">
          {[69, 10, 7, 8, 6].map((item, index) => (
            <div key={index} className="star-progress">
              <div className="score">{5 - index}</div>
              <div className="star">
                <img src={IconStarActive} alt="icon-star-active" />
              </div>
              <Progress percent={item} showInfo={false} strokeColor="#449086" />
            </div>
          ))}
        </div>
        <div className="score-container-circle">
          <Progress
            type="circle"
            percent={86}
            strokeColor="#449086"
            trailColor="rgba(68, 144, 134, 0.3)"
            width={Math.min(120, document.body.clientWidth / 1440 * 120 >> 0)}
            format={(percent) => `${percent}%`}
          />
        </div>
      </div>

      <div className="reviews-filter">
        <div className="account">
          <span>{formatNumber(91238)}</span>
          patients reviews
        </div>
        <div className="filter">
          <div className="filter-show">
            <Select className="filter-show-select">
              <Select.Option value="All outcomes">All outcomes</Select.Option>
            </Select>
          </div>
          <div className="filter-latest">
            <Select className="filter-latest-select">
              <Select.Option value="Latest first">Latest first</Select.Option>
            </Select>
          </div>
        </div>
      </div>

      <div className="reviews-container">
        {[0, 1, 2, 3, 4].map((card, index) => (
          <ReviewItem data={test_reivew_item} key={index} />
        ))}
      </div>
    </div>
  );
};
