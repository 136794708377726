import { Avatar, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";

import IconLocation from "@/assets/images/icon-location.svg";
import IconPhone from "@/assets/images/icon-phone.svg";

import { useResponsive } from "ahooks";
import { ReviewClinicModal } from "./review_clinic_modal";
import { useState } from "react";
import "./profile_clinic_card.scss";
import { useModel } from "use-reaction";
import { model_user } from "../models/model_user";
import { RoleType } from "../utils/types";
import { useParams } from "react-router-dom";

interface Props {
  avatar: string;
  clinicId: string;
  clinicName: string;
  clinicAddress: string;
  clinicActive: boolean;
  clinicPhone: string;
  rating?: {
    reviewCount: number;
    overallRating: string | number;
    environmentRating: string | number;
    staffRating: string | number;
    timeRating: string | number;
  };
  goBooking?: () => void;
}
export const ProfleClinicCard = ({
  avatar,
  clinicId,
  clinicName,
  clinicAddress,
  clinicActive,
  clinicPhone,
  rating,
  goBooking,
}: Props) => {
  const { isPC } = useResponsive();
  const [showReviewClinic, setShowReviewClinic] = useState(
    window.location.pathname.includes("review")
  );
  const {
    store: { role },
  } = useModel(model_user);

  return (
    <div className="role-card role-card__clinic">
      <div className="role-avatar">
        <Avatar shape="square" icon={<UserOutlined />} src={avatar} />
      </div>
      <div className="role-infos">
        <div className="infos-title">{clinicName}</div>
        <div className="infos-desc"></div>
        <div className="infos-list">
          <div className="infos-item">
            <div className="infos-item-icon">
              <img src={IconLocation} alt="icon-location" />
            </div>
            <div className="infos-item-value">{clinicAddress}</div>
          </div>
          <div className="infos-item">
            <div className="infos-item-icon">
              <img src={IconPhone} alt="icon-phone" />
            </div>
            <div className="infos-item-value">
              <a href={`tel:${clinicPhone}`}>{clinicPhone}</a>
            </div>
          </div>
        </div>
      </div>
      <div className="role-book">
        <div className="btns">
          <Button
            type="default"
            style={{
              width: "175px",
              height: "38px",
              marginRight: isPC ? "16px" : 0,
            }}
            disabled={!!role && role !== RoleType.USER}
            onClick={(e) => setShowReviewClinic(true)}
          >
            Review Clinic
          </Button>
          <Button
            type="primary"
            style={{ width: "175px", height: "38px" }}
            hidden={(!!role && role !== RoleType.USER) || !clinicActive}
            onClick={goBooking}
          >
            Book appointment
          </Button>
        </div>
        <div className="clinic-scores">
          <div className="clinic-scores-item">
            <div className="clinic-scores-title">environment</div>
            <div className="clinic-scores-score">
              <span>{rating?.environmentRating || 0}</span>
              <span>/5</span>
            </div>
          </div>
          <div className="clinic-scores-item">
            <div className="clinic-scores-title">timeliness</div>
            <div className="clinic-scores-score">
              <span>{rating?.timeRating || 0}</span>
              <span>/5</span>
            </div>
          </div>
          <div className="clinic-scores-item">
            <div className="clinic-scores-title">staff</div>
            <div className="clinic-scores-score">
              <span>{rating?.staffRating || 0}</span>
              <span>/5</span>
            </div>
          </div>
        </div>
      </div>
      <ReviewClinicModal
        visible={showReviewClinic}
        setVisible={setShowReviewClinic}
        clinicName={clinicName}
        clinicId={clinicId}
      />
    </div>
  );
};
