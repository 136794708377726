import { message } from "antd";
import axios, { AxiosBasicCredentials, Method, ResponseType } from "axios";
import queryString from "qs";
import { API_HOST, router_login } from "./consts";
import { CachePool } from "./util";
const tokenKey = "access-token";
// defalut cache ttl for get method
const default_get_cache_ttl = 0;
const cachePool = new CachePool();

export const setToken = (token: string) => {
  return localStorage.setItem(tokenKey, token);
};

export const getToken = () => {
  return localStorage.getItem(tokenKey) || "";
};

export const removeToken = () => {
  return localStorage.removeItem(tokenKey);
};

//status code
const code_not_allowed = 405;
const code_unauthorized = 401;

export const logout = () => {
  removeToken();
  console.log("unauthorized, logout");
  window.location.pathname = router_login;
};

const http = axios.create({
  baseURL: API_HOST,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 60000,
});

http.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token && !config.cancelToken) {
      config.headers!["authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(
  (res) => res.data,
  (err) => {
    const status = err.response?.data?.statusCode;
    const msg = err.response?.data?.message;
    switch (status) {
      case code_unauthorized:
      case code_not_allowed:
        logout();
        return;
      default:
        message.error(msg);
        return Promise.reject(msg);
    }
  }
);
interface Option {
  type?: Method | "form";
  headers?: Record<string, string>;
  resType?: ResponseType;
  params?: Record<string, any>;
  data?: Record<string, any>;
  auth?: AxiosBasicCredentials;
  /**cache time to live in seconds, only available for get method, default = 30, pass 0 to disable cache */
  cacheTTL?: number;
}

export const ajax = async (url: string, options?: Option) => {
  if (options?.type === "form") {
    const formD = new FormData();
    for (let k in options?.data) {
      formD.append(k, options?.data[k]);
    }
    options.data = formD;
    options.headers = {};
    options.type = "post";
  }
  const method = options?.type?.toLowerCase() || "get";
  const cacheTTL =
    method === "get"
      ? options?.cacheTTL !== undefined
        ? options.cacheTTL
        : default_get_cache_ttl
      : 0;
  const cacheKey = `${url}-${queryString.stringify(options?.params, {
    arrayFormat: "repeat",
  })}`;
  if (cacheTTL > 0) {
    // try to use cache
    const cached = cachePool.get(cacheKey);
    if (cached) {
      return (await cached)?.data;
    }
  }
  const res = http({
    url,
    ...(options?.headers
      ? options?.headers
      : {
          "Content-Type": "application/json; charset=UTF-8",
        }),
    responseType: options?.resType || "json",
    params: options?.params || {},
    paramsSerializer: (params: any) => {
      return queryString.stringify(params, { arrayFormat: "comma" });
    },
    data: options?.data,
    method,
    auth: options?.auth,
  });
  cacheTTL && cachePool.set(cacheKey, res, cacheTTL);
  return (await res)?.data;
};
