export interface ImgData {
  key?: string;
  url: string;
  id?: number;
  name?: string;
}
export interface DataItem {
  id: number;
  name: string;
  [k: string]: any;
}
export interface GeoPoint {
  lat: number | string;
  lng: number | string;
}
export interface AddrData {
  id?: string;
  raw: string;
  terms: string[];
  latlng?: string;
}
export interface Opt {
  [k: string]: any;
  label?: string;
  value?: string | number;
}
export enum RoleType {
  USER = 1,
  ACUPUNCTURIST = 2,
}

export enum ClinicRole {
  ACUPUNCTURIST = "ACUPUNCTURIST",
  CLINICADMIN = "CLINICADMIN",
}
export enum DoctorStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
}

export enum curedEnum {
  NOT_AT_ALL,
  SOMEWHAT,
  CURED,
}

export interface CardBillingAddr {
  addressLine1: string;

  addressLine2: string;

  locality: string;

  country: string;

  postalCode: string;
}
export interface CardData {
  id: string;

  brand: string;

  expMonth: number;

  expYear: number;

  last4: string;

  paymentToken?: string;

  cardholderName: string;

  billingAddress: CardBillingAddr;
}
export enum EventType {
  ALL = "all",
  APPOINTMENT = "appointment",
  SCHEDULE = "schedule",
}
export enum ScheduleInterval {
  ONCE = 'once',
  WEEKLY = 'weekly',
}
