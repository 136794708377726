import { action_user_get_info, model_user } from "@/web/models/model_user";
import { router_account_setting } from "@/web/utils/consts";
import { RoleType } from "@/web/utils/types";
import { Modal, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useModel } from "use-reaction";
import "./ftl_finished.scss";
export const FtlFinishModal = () => {
  const navigate = useNavigate();

  const {
    doAction,
    store: {role}
  } = useModel(model_user);

  const afterClose = async () => {
    const info = await doAction(action_user_get_info);
    if (info!.role === RoleType.USER) {
      navigate(router_account_setting);
    } else {
      navigate(`/profile/doctor/${info!.profile!.id}/${(info!.profile!.name || '').replace(/\s+/g, '-')}`);
    }
  };

  return (
    <Modal
      className="congratulation-modal"
      visible
      closable={false}
      destroyOnClose={true}
      width={754}
      footer={null}
      keyboard={false}
      maskClosable={false}
      afterClose={afterClose}
    >
      <div className="congratulation-main">
        <div className="block">
          <span></span>
        </div>
        <div className="congratulation">Congratulations!</div>
        <div className="description">
          Your profile is now completed! You can see what your profile looks
          like
        </div>
        <div className="btns">
          <Button
            type="primary"
            style={{ width: "200px", height: "44px" }}
            onClick={afterClose}
          >
            Visit My {role === RoleType.ACUPUNCTURIST? 'Public Link' : 'Account'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
