import { getClinicDoctors } from "@/web/api/clinic";
import {
  clinicDoctorStatusCfg,
  router_account_clinic,
} from "@/web/utils/consts";
import { ClinicRole } from "@/web/utils/types";
import { useRequest } from "ahooks";
import { Avatar, Button, Table, Tag } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "./account_clinic_doctor_list.scss";

export const AccountClinicDoctorList = () => {
  const navigate = useNavigate();
  const { data: doctorList } = useRequest(getClinicDoctors);

  const goDetail = (item: any) => {
    navigate(router_account_clinic + "/doctor/" + +item.id);
  };
  const columns: any[] = [
    {
      title: "Avatar",
      key: "avatar",
      dataIndex: "avatar",
      width: 80,
      fixed: "left",
      render: (url: string) => <Avatar src={url} />,
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: 80,
      fixed: "left",
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "role",
      render: (role: ClinicRole) =>
        role === ClinicRole.CLINICADMIN ? "Admin" : "Staff",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Phone number",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Date added",
      key: "createDate",
      dataIndex: "createDate",
      render: (date: string) => moment(date).format("YYYY/MM/DD"),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (st: keyof typeof clinicDoctorStatusCfg) => (
        <Tag color={clinicDoctorStatusCfg[st]?.color || "default"}>
          {clinicDoctorStatusCfg[st]?.label || st}
        </Tag>
      ),
    },
    {
      key: "action",
      render: (item: any) => (
        <Button onClick={() => goDetail(item)}>View</Button>
      ),
    },
  ];
  return (
    <div className="account-clinic-doctors">
      <Table
        rowKey="id"
        columns={columns}
        dataSource={doctorList}
        pagination={{ hideOnSinglePage: true }}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
      />
    </div>
  );
};
