import { getFtlUserProfile, putFtlUserProfile } from "@/web/api/ftl";
import { getUserInfo } from "@/web/api/user";
import { UploadAvatar } from "@/web/comps/upload_avatar";
import { action_ftl_finish_step, model_ftl } from "@/web/models/model_ftl";
import { ImgData } from "@/web/utils/types";
import { useMount } from "ahooks";
import { Button, Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useModel } from "use-reaction";
import "./usr_profile.scss";

export const UsrProfile = () => {
  const [form] = useForm();
  const [avatar, setAvatar] = useState<ImgData>();
  const { doAction } = useModel(model_ftl);

  const updateAvatar = (img: ImgData) => {
    // call api to refresh user, then got updated info/avatar
    setAvatar(img);
  };
  const onFinish = async (values: any) => {
    await doAction(
      action_ftl_finish_step,
      {
        values: { ...values, avatar },
        api: putFtlUserProfile,
        next: true,
      },
      "global"
    );
  };
  useMount(async () => {
    const profile = await getFtlUserProfile();
    profile && form.setFieldsValue(profile);
    const user = await getUserInfo();
    user.avatar && setAvatar(user.avatar);
  });

  return (
    <div className="fill-profile-page">
      <div className="profile-form user-profile-form">
        <div className="common-title-of-profile">
          <div className="profile-page-title">
            Welcome to Find My Acupuncturist!
          </div>
          <div className="profile-page-description">
            Set up your profile with basic information.
          </div>
          <UploadAvatar onFinish={updateAvatar} initImg={avatar} />
        </div>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="name"
            label="Your name"
            rules={[
              {
                required: true,
                message: "Missing your name!",
              },
            ]}
          >
            <Input placeholder="Your name" />
          </Form.Item>
          <Form.Item
            name="userPhone"
            label="Your number"
            rules={[
              {
                required: true,
                message: "Missing number!",
              },
            ]}
          >
            <Input placeholder="716-803-8899" />
          </Form.Item>
          <Form.Item
            name="gender"
            label="Gender"
            rules={[
              {
                required: true,
                message: "Missing gender!",
              },
            ]}
          >
            <Select placeholder="Please select">
              <Select.Option value="female">Female</Select.Option>
              <Select.Option value="male">Male</Select.Option>
            </Select>
          </Form.Item>
        </Form>
        <div className="submit-btn">
          <Button type="primary" onClick={form.submit}>
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
};
