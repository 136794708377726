import {
  Calendar,
  momentLocalizer,
  SlotInfo,
  EventWrapperProps,
} from "react-big-calendar";
import moment from "moment";
import "./event_calendar.scss";
import { ComponentType, useMemo, useState } from "react";
import classNames from "classnames";
import { MailOutlined } from "@ant-design/icons";
import { Tooltip, Tag, Divider, Button, Badge, Select } from "antd";
import {
  bookingStatusCfg,
  BookStatus,
  scheduleLabelCfg,
} from "../utils/consts";
import { useResponsive } from "ahooks";
import { EventType, ScheduleInterval } from "../utils/types";
const localizer = momentLocalizer(moment);

const filterOpts = Object.values(EventType).map((k) => ({
  label: k!,
  value: k,
}));
interface Props {
  date?: Date;
  timeStart?: string;
  timeEnd?: string;
  timeStep?: number; // unit=Minute, default=30
  timeSlots?: number; // unit=Minute, default=3
  itemRender?: ComponentType<EventWrapperProps<any>>;
  items?: (
    | {
        id: number | string;
        patientName: string;
        patientEmail: string;
        dateTime: Date;
        serviceDuration: number; //unit=minute
        status: string;
        type: EventType.APPOINTMENT; // EventType
        serviceName: string;
        healthProblem: string;
      }
    | {
        id: number | string;
        title: string;
        start: string; // dateString
        end: string; // dateString
        originStart: string; // dateString
        originEnd: string; // dateString
        interval: string;
        type: EventType.SCHEDULE;
      }
  )[];
  onWeekChange?: (weekStart: Date, weekEnd: Date) => void;
  onClickItem?: (type: EventType, item: any) => void;
  onCreate?: (info: { start: Date; end: Date; view: string }) => void;
}
export const EventCalendar = ({
  date,
  timeStart = "07:00",
  timeEnd = "22:00",
  timeStep = 30,
  timeSlots = 3,
  items = [],
  onWeekChange,
  onClickItem,
  itemRender,
  onCreate,
}: Props) => {
  const { isPC } = useResponsive();
  const [view, setView] = useState("week");
  const [min, max] = useMemo(() => {
    const [startHour, startMin] = timeStart.split(":");
    const [endHour, endMin] = timeEnd.split(":");
    const min = moment()
      .hour(+startHour)
      .minute(+startMin)
      .second(0)
      .toDate();
    const max = moment()
      .hour(+endHour)
      .minute(+endMin)
      .second(0)
      .toDate();
    return [min, max];
  }, [timeStart, timeEnd]);

  const [filter, setFilter] = useState<EventType>(EventType.ALL);
  const source = useMemo(
    () =>
      items.map((it) => {
        if (it.type === EventType.APPOINTMENT) {
          const start = new Date(it.dateTime);
          const end = moment(it.dateTime)
            .add(it.serviceDuration, "minute")
            .toDate();
          return {
            start,
            end,
            title: it.patientName,
            type: it.type,
            resource: it,
          };
        } else {
          return {
            start: moment(it.start).toDate(),
            end: moment(it.end).toDate(),
            title: it.title,
            type: it.type,
            resource: it,
          };
        }
      }),
    [items]
  );
  const events = useMemo(() => {
    return filter === EventType.ALL
      ? source
      : source.filter((_) => _.type === filter);
  }, [source, filter]);

  const eventClassName = (
    event: any,
    start: Date,
    end: Date,
    isSelected: boolean
  ) => {
    return { className: event.resource.status };
  };

  const createEvent = (info: SlotInfo) => {
    onCreate && onCreate({ start: info.start, end: info.end, view });
  };
  const addEvent = () => {
    onCreate &&
      onCreate({
        start: moment(moment().format("YYYY-MM-DD HH:00")).add(1, "h").toDate(),
        end: max,
        view,
      });
  };
  const rangeChg = (range: any) => {
    console.log("range chg", range);
    if (range?.length === 7) {
      // week view
      onWeekChange && onWeekChange(range[0], range[range.length - 1]);
    }
  };
  return (
    <div className="event-calendar">
      <div className="head-bar">
        <div className="section legend">
          <div>
            <Badge
              color={bookingStatusCfg[BookStatus.BOOKING_REVIEW].color}
              text={bookingStatusCfg[BookStatus.BOOKING_REVIEW].label}
            />
            <Badge
              color={bookingStatusCfg[BookStatus.BOOKING_SUCCESS].color}
              text={bookingStatusCfg[BookStatus.BOOKING_SUCCESS].label}
            />
          </div>
          <div>
            <Badge
              color={bookingStatusCfg[BookStatus.BOOKING_FAILED].color}
              text={bookingStatusCfg[BookStatus.BOOKING_FAILED].label}
            />
            <Badge
              color={bookingStatusCfg[BookStatus.CANCEL_SUCCESS].color}
              text={bookingStatusCfg[BookStatus.CANCEL_SUCCESS].label}
            />
          </div>
          <div>
            <Badge
              color={scheduleLabelCfg[ScheduleInterval.ONCE].color}
              text={scheduleLabelCfg[ScheduleInterval.ONCE].label + " schedule"}
            />
            <Badge
              color={scheduleLabelCfg[ScheduleInterval.WEEKLY].color}
              text={
                scheduleLabelCfg[ScheduleInterval.WEEKLY].label + " schedule"
              }
            />
          </div>
        </div>
        <div className="section filter">
          <div className="grp">
            <Button className="show">show:</Button>
            <Select
              options={filterOpts}
              onChange={setFilter}
              value={filter}
              bordered={false}
            />
          </div>
          <Button className="action" onClick={addEvent}>
            Add Event
          </Button>
        </div>
      </div>
      <Divider />
      <Calendar
        className="bg-calendar"
        localizer={localizer}
        events={events}
        eventPropGetter={eventClassName}
        defaultView="week"
        defaultDate={date}
        views={["week", "day"]}
        dayLayoutAlgorithm="no-overlap"
        min={min}
        max={max}
        step={timeStep}
        timeslots={timeSlots}
        selectable={!!onCreate}
        onSelectSlot={createEvent}
        onRangeChange={rangeChg}
        onView={setView}
        startAccessor="start"
        endAccessor="end"
        allDayAccessor="allDay"
        {...(itemRender ? { components: { eventWrapper: itemRender } } : {})}
        formats={{
          dayRangeHeaderFormat: ({ start, end }) =>
            `${moment(start).format("MMM DD")} - ${moment(end).format(
              "MMM DD"
            )}`,
          dayFormat: (date) =>
            isPC
              ? `${moment(date).format("ddd DD")}`
              : `${moment(date).format("DD")}`,
        }}
        style={{ height: 620 }}
      />
    </div>
  );
};
