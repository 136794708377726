import { Action } from "use-reaction";
import {
  getUserInfo,
  userDiscardClaim,
  userSignup,
  userVerify,
} from "../api/user";
import { getToken, setToken } from "../utils/ajax";

export const model_user = {
  NAME: "user",
  id: 0,
  email: "",
  googleUserId: "",
  facebookUserId: "",
  role: 0,
  clinicRole: [
    {
      id: 0,
      role: "ACUPUNCTURIST",
      profileId: 0,
      clinicId: 0,
    },
  ],
  userPhone: "",
  name: "",
  gender: "",
  ftlStep: "",
  confirmed: false,
  unclaimed: { profile: undefined as any, rating: undefined as any },
  noPwd:false,
  avatar: { id: 0, key: "", url: "" },
  profile: {
    id: 0,
    clinicId: 0,
    name: null,
  },
};

type T = typeof model_user;

export const action_user_signup: Action<T> = async ({ payload }) => {
  const res = await userSignup(payload);
  return res;
};

export const action_user_verify: Action<T> = async ({
  payload,
  store: { id },
}) => {
  const res = await userVerify(id, payload);
  const { access_token, ...info } = res;
  setToken(access_token);
  return info;
};

export const action_user_login: Action<T> = async ({
  payload: { data, api },
}) => {
  const res = await api(data);
  const { access_token, ...info } = res;
  setToken(access_token);
  return info;
};

export const action_user_get_info: Action<T> = async () => {
  if (getToken()) {
    const data = await getUserInfo();
    return data;
  }
};

export const action_user_discard_claim: Action<T> = async ({
  store: { unclaimed },
}) => {
  unclaimed?.profile?.id && (await userDiscardClaim(unclaimed.profile.id));
  return {
    unclaimed: { profile: undefined, rating: undefined },
  };
};
