import { Form, Input, Button, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { accountResetPassword } from "@/web/api/user";
import { BasicLayout } from "@/web/comps/basic_layout";
import "./index.scss";
import { useNavigate } from "react-router";
import { router_ftl_root } from "@/web/utils/consts";
import { useRequest } from "ahooks";
import { useModel } from "use-reaction";
import { action_user_get_info, model_user } from "@/web/models/model_user";

export const SetPwd = () => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { doAction } = useModel(model_user);
  const { run: submitPassword, loading } = useRequest(
    async () => {
      const values = await form.validateFields();
      if (values.newPassword === values.confirmPassword) {
        await accountResetPassword({ currentPassword: null, ...values });
        message.success("New password saved!");
        await doAction(action_user_get_info);
        // back to ftl
        navigate(router_ftl_root, { replace: true });
      } else {
        message.error("The password entered twice is not the same！");
      }
    },
    { manual: true }
  );

  return (
    <BasicLayout>
      <div className="set-password-page">
        <div className="account-page-block"></div>
        <div className="account-page-title font-family-merriweather">
          Choose a new password
        </div>
        <div className="account-page-description">
          Please choose a new password for your account
        </div>
        <Form form={form} layout="vertical" className="new-password-box">
          <div className="form-item">
            <Form.Item
              name="newPassword"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: "Missing new password!",
                },
              ]}
            >
              <Input type="password" placeholder="Your new password here" />
            </Form.Item>
          </div>
          <div className="form-item">
            <Form.Item
              name="confirmPassword"
              label="Confirm new password"
              rules={[
                {
                  required: true,
                  message: "Missing new password!",
                },
              ]}
            >
              <Input type="password" placeholder="Confirm your new password" />
            </Form.Item>
          </div>
          <div className="form-item form-item-submit">
            <Button type="primary" loading={loading} onClick={submitPassword}>
              Submit Password
            </Button>
          </div>
        </Form>
      </div>
    </BasicLayout>
  );
};
