import { action_ftl_put_step, model_ftl } from "@/web/models/model_ftl";
import { Progress as ProgressBar, Button } from "antd";
import { useModel } from "use-reaction";
import "./ftl_progress.scss";
interface Props {
  currentIndex: number;
  lastIndex: number;
  goNext?: () => void;
  goBack?: () => void;
  onFinish: () => void;
  onCancel: () => void;
}
export const FTLProgress = ({
  currentIndex = 0,
  lastIndex = 1,
  goNext,
  goBack,
  onFinish,
  onCancel,
}: Props) => {
  const { store, doAction } = useModel(model_ftl);
  const onSkip = () => {
    doAction(action_ftl_put_step, store.step_next);
  };
  return (
    <div className="ftl-progress-wrapper">
      <div className="ftl-progress-content">
        <div className="bar-wrapper">
          <ProgressBar
            strokeColor="#449086"
            showInfo={false}
            percent={(currentIndex / lastIndex) * 100}
          />
          <span className="info">{`${
            currentIndex >= lastIndex ? lastIndex : currentIndex
          }/${lastIndex}`}</span>
        </div>
        <div className="btn-wrapper">
          {
            <Button onClick={currentIndex === 1 ? onCancel : goBack}>
              {currentIndex === 1 ? "Cancel" : "back"}
            </Button>
          }
          <Button
            type="primary"
            onClick={currentIndex >= lastIndex ? onFinish : goNext}
          >
            {currentIndex >= lastIndex ? "finish" : "next"}
          </Button>
          <Button
            onClick={onSkip}
            hidden={currentIndex === 1 || currentIndex >= lastIndex}
          >
            Skip
          </Button>
        </div>
      </div>
    </div>
  );
};
