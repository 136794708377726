import { useState } from "react";
import { useNavigate } from "react-router-dom";
import intl from "react-intl-universal";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useResponsive } from "ahooks";
import { router_search_result } from "../utils/consts";
import "./common_search.scss";
import qs from "qs";
import { useModel } from "use-reaction";
import { model_search } from "../models/model_search";

export const CommonSearch = () => {
  const navigate = useNavigate();
  const { resetModel, doAction } = useModel(model_search);
  const [keyword, setKeyword] = useState("");
  const { isPC } = useResponsive();
  const goSearch = async () => {
    // clear search filter cache
    await resetModel();
    navigate(
      router_search_result + (keyword ? `?${qs.stringify({ keyword })}` : "")
    );
  };
  return (
    <div className="common-search">
      <Input
        placeholder={
          isPC
            ? intl.get("common_search_placeholder")
            : intl.get("common_search_mobile_placeholder")
        }
        prefix={<SearchOutlined style={{ fontSize: "20px" }} />}
        bordered={false}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        onPressEnter={goSearch}
      />
      <Button type="primary" onClick={goSearch}>
        {intl.get("common_button_search")}
      </Button>
    </div>
  );
};
