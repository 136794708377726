import { searchExpertiseCommon } from "@/web/api/options";
import { getTreatmentTechs } from "@/web/api/options";
import { SearchSelect } from "@/web/comps/search_select";
import {
  action_ftl_finish_step,
  action_ftl_put_step,
  model_ftl,
} from "@/web/models/model_ftl";
import { Opt } from "@/web/utils/types";
import { useRequest } from "ahooks";
import { Input, Select, Skeleton, Spin, Tag } from "antd";
import { unionBy } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModel } from "use-reaction";
import { FTLProgress } from "./ftl_progress";
import { getFtlacuArea, putFtlacuArea } from "@/web/api/ftl";
import "./acu_area.scss";
import SkeletonInput from "antd/lib/skeleton/Input";
import { dataItem2Opt, opt2DataItem } from "@/web/utils/util";

export const AcuArea = () => {
  const navigate = useNavigate();
  const {
    doAction,
    store: { step_next, step_previous },
  } = useModel(model_ftl);

  const [commonTags, setCommonTags] = useState<Opt[]>([]);
  const [selectTags, setSelectTags] = useState<Opt[]>([]);
  const [treatments, setTreatments] = useState<Opt[]>([]);
  const [treatmentsDes, setTreatmentsDes] = useState<string>();

  const getExpertiseByName = async (name: string) => {
    let common = commonTags;
    if (!common?.length) {
      // all onMount
      const commonData = await searchExpertiseCommon();
      common = commonData.map(dataItem2Opt);
      setCommonTags(common);
      const data = await getFtlacuArea();
      setSelectTags(data.expertiseAreas?.map(dataItem2Opt));
      setTreatments(data.treatmentTechniques?.map(dataItem2Opt));
      setTreatmentsDes(data.treatmentTechniqueDescription);
    }
    return common;
    // const data = await searchExpertiseCommon(name, 20);
    // return unionBy(data.map(dataItem2Opt), common, "value");
  };

  const { data: treatmentList } = useRequest(async () => {
    const data = await getTreatmentTechs();
    return data.map(dataItem2Opt) as Opt[];
  });

  const toggleTag = (v: any) => {
    const idx = selectTags.findIndex((_) => _.value === v.value);
    if (idx > -1) {
      selectTags.splice(idx, 1);
    } else {
      selectTags.push(v);
    }
    setSelectTags([...selectTags]);
  };

  const goHome = () => navigate("/");

  const goNext = () => onFinish(true);
  const goBack = () => {
    doAction(action_ftl_put_step, step_previous, "global");
  };
  const onFinish = async (next?: boolean) => {
    const values = {
      expertiseAreas: selectTags.map(opt2DataItem),
      treatmentTechniques: treatments.map(opt2DataItem),
      treatmentTechniqueDescription: treatmentsDes,
    };
    await doAction(
      action_ftl_finish_step,
      {
        values,
        api: putFtlacuArea,
        next,
      },
      "global"
    );
  };

  return (
    <>
      <div className="fill-profile-page">
        <div className="acu-expertise-area">
          <div className="select-tags">
            <div className="select-tags-title font-family-merriweather">
              What are your areas of expertise?
            </div>
            <SearchSelect
              searchFn={getExpertiseByName}
              value={selectTags.map((_) => _.value!)}
              searchOnMount
              mode="multiple"
              className="acu-select"
              onChange={(_, opts) => setSelectTags(opts as Opt[])}
            />
            <div className="suggestion-title">common expertise</div>
            <div className="suggestion-tags">
              {commonTags?.length ? (
                commonTags.map((t) => (
                  <Tag
                    key={"acu-expertise" + t.value}
                    className="expertise-tag"
                    onClick={(e) => toggleTag(t)}
                  >
                    {t.label}
                  </Tag>
                ))
              ) : (
                <Skeleton paragraph={{ rows: 3 }} />
              )}
            </div>
          </div>
          <div className="treatment-techniques">
            <div className="treatment-techniques-title font-family-merriweather">
              What treatment techniques do you use?
            </div>
            {treatmentList?.length ? (
              <Select
                mode="multiple"
                className="acu-select"
                placeholder="Search for treatment techniques"
                value={treatments}
                disabled={!treatmentList?.length}
                onChange={(_, opt) => setTreatments(opt as Opt[])}
                options={treatmentList}
              />
            ) : (
              <SkeletonInput />
            )}
            <div className="input-title">Technique Details</div>
            <Input.TextArea
              className="technique-detail"
              placeholder="Enter your technique details"
              value={treatmentsDes}
              disabled={!treatmentList?.length}
              onChange={(e) => setTreatmentsDes(e.target.value)}
            ></Input.TextArea>
          </div>
        </div>
      </div>
      <FTLProgress
        currentIndex={3}
        lastIndex={5}
        goBack={goBack}
        goNext={goNext}
        onCancel={goHome}
        onFinish={goHome}
      />
    </>
  );
};
