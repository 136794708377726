import { useResponsive } from "ahooks";
import { Button } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CheckIcon } from "@/assets/images/icon-checked-2.svg";
import "./select_role.scss";
import { postFtlRole } from "@/web/api/ftl";
import { useModel } from "use-reaction";
import { action_ftl_get_step, model_ftl } from "@/web/models/model_ftl";
import { RoleType } from "@/web/utils/types";
import { action_user_get_info, model_user } from "@/web/models/model_user";

type Role = {
  key: string;
  role: number;
  txt: string;
  cnt: number;
};
const role_list: Role[] = [
  {
    key: "acupuncturist",
    role: RoleType.ACUPUNCTURIST,
    txt: "I am an acupuncturist.",
    cnt: 5,
  },
  {
    key: "user",
    role: RoleType.USER,
    txt: "I am an user.",
    cnt: 1,
  },
];

export const SelectRole = () => {
  const navigate = useNavigate();
  const { isPC } = useResponsive();
  const [role, setRole] = useState<Role>();
  const [saved, setSaved] = useState(false);
  const { doAction } = useModel(model_ftl);
  const { doAction: doActionUser } = useModel(model_user);
  const goHome = () => {
    doActionUser(action_user_get_info);
    navigate("/");
  };
  const saveRole = async () => {
    await postFtlRole(role!.role);
    setSaved(true);
  };
  const goOn = () => doAction(action_ftl_get_step);

  return (
    <div className="select-role">
      <div
        className="select-role-title"
        dangerouslySetInnerHTML={{
          __html: !saved
            ? "Select what best describe you"
            : "Welcome! <br /> Build Your Profile Now, or Do It Later",
        }}
      />
      {!saved ? (
        <>
          <div className="role-cards">
            {role_list.map((item) => (
              <div
                key={item.key}
                className={[
                  "select-role-card",
                  `select-role-card-${item.key}-${
                    item.key === role?.key ? "checked" : "unchecked"
                  }`,
                ].join(" ")}
                onClick={() => setRole(item)}
              > 
                {!isPC && item.txt}
                {!isPC && <CheckIcon className="check-ico" />}
              </div>
            ))}
          </div>
          <div className="select-role-continue">
            <Button
              className="ant-btn-success"
              disabled={!role}
              onClick={saveRole}
            >
              Continue
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="select-role-desc">
            {`Build your profile in ${role!.cnt} easy step${
              role!.cnt > 1 ? "s" : ""
            }`}
          </div>

          <div className="button-group">
            <Button type="primary" onClick={goHome}>
              DO IT LATER
            </Button>
            <Button className="ant-btn-success" onClick={goOn}>
              BUILD MY PROFILE
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
