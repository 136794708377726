import { ajax } from "../utils/ajax";
import { DataItem } from "../utils/types";
import { dataItem2Opt } from "../utils/util";

export const searchExpertiseCommon = (name?: string, limit?: number) =>
  ajax("options/expertises", {
    type: "get",
    params: {
      name,
      limit,
    },
  });
export const getLanguages = async () => {
  const res: DataItem[] = await ajax("options/languages");
  return res.map(dataItem2Opt);
};
export const getTreatmentTechs = () => ajax("options/treatment-techs");
