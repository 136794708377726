import { putAcuExperiences } from "@/web/api/profile";
import { model_user, action_user_get_info } from "@/web/models/model_user";
import { useRequest } from "ahooks";
import { message, Button } from "antd";
import { useRef } from "react";
import { useModel } from "use-reaction";
import { AcuExperience } from "../ftl/acu_experience";
import "./account_acu_profile_experience.scss";

export const AccountAcuProfileExperience = () => {
  const ref = useRef<any>();
  const { doAction } = useModel(model_user);
  const { run: onSave, loading } = useRequest(
    async (values: any) => {
      await putAcuExperiences(values);
      message.success("save success!");
      doAction(action_user_get_info);
    },
    { manual: true }
  );
  // not getAcuExperience here, will get in <AcuExperience /> element
  return (
    <>
      <AcuExperience
        ref={ref}
        className="account-profile"
        showExtra={false}
        onSave={onSave}
      />
      <div className="submit-btn">
        <Button
          type="primary"
          loading={loading}
          onClick={() => ref.current?.save()}
        >
          Save changes
        </Button>
      </div>
    </>
  );
};
