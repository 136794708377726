import { Button, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ReactComponent as IconShowEye } from "@/assets/images/icon-show-pwd.svg";
import { ReactComponent as IconHideEye } from "@/assets/images/icon-hide-pwd.svg";

import "./account_setting_reset_pwd.scss";
import { useRequest } from "ahooks";
import { accountResetPassword } from "@/web/api/user";
export const AccountSettingResetPwd = () => {
  const [form] = useForm();

  const { run: onFinish, loading } = useRequest(
    async (values: any) => {
      await accountResetPassword(values);
      message.success("New Password Saved!");
      form.resetFields()
    },
    {
      manual: true,
    }
  );

  return (
    <>
      <div className="account-content-page account-reset-pwd">
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="current password"
            name="currentPassword"
            rules={[
              {
                required: true,
                message: "current password",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              iconRender={(visible) =>
                visible ? <IconHideEye /> : <IconShowEye />
              }
            />
          </Form.Item>
          <Form.Item
            label="new password"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "new password",
              },
            ]}
          >
            <Input.Password
              placeholder="New Password"
              iconRender={(visible) =>
                visible ? <IconHideEye /> : <IconShowEye />
              }
            />
          </Form.Item>
          <Form.Item
            label="confirm new password"
            name="confirmPassword"
            dependencies={["newPassword"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm New Password"
              iconRender={(visible) =>
                visible ? <IconHideEye /> : <IconShowEye />
              }
            />
          </Form.Item>
        </Form>
      </div>
      <div className="submit-btn">
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={form.submit}
        >
          Reset
        </Button>
      </div>
    </>
  );
};
