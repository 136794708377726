import { Form, Input, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { postUserCheckPwd } from "../api/user";

interface Props {
  title?: string;
  className?: string;
  width?: number;
  centered?: boolean;
  okText?: string;
  cancelText?: string;
  onSuccess: () => void;
}
let pwd: string;
export const accountConfirmPwd = ({
  title = "Please confirm this operation with your password",
  width = 600,
  className = "fma-modal",
  centered = true,
  onSuccess,
}: Props) => {
  Modal.confirm({
    title,
    width,
    centered,
    className,
    content: (
      <Form layout="vertical">
        <Form.Item label="password" name="password">
          <Input.Password
            placeholder="input password"
            value={pwd}
            onChange={(e) => (pwd = e.target.value.trim())}
          />
        </Form.Item>
      </Form>
    ),
    cancelText: "cancel",
    okText: "Confirm",
    onOk: () => {
      if (!pwd) {
        message.error("password required");
        return;
      }
      // call api to verify password
      postUserCheckPwd(pwd).then((res) => {
        if (res.success) {
          message.success("verify success!");
          onSuccess();
        }
      });
    },
  });
};
