import Logo from "../../assets/images/logo.svg";
import Logo2 from "../../assets/images/logo2.svg";
import LogoResponsive from "../../assets/images/logo-responsive.svg";
import LogoResponsive2 from "../../assets/images/logo-responsive2.svg";
import intl from "react-intl-universal";
import moment from "moment";
import "./footer.scss";
import { useResponsive } from "ahooks";

export const Footer = ({ whiteBg = false }) => {
  const { isPC } = useResponsive();
  const currentYear = moment().format("YYYY");
  return (
    <div className={["app-footer", whiteBg && "white-bg-footer"].join(" ")}>
      <div className="logo">
        {isPC ? (
          <img src={whiteBg ? Logo : Logo2} alt="footer-logo" />
        ) : (
          <img
            src={whiteBg ? LogoResponsive : LogoResponsive2}
            alt="footer-logo"
          />
        )}
        <div className="pc-copyright">
          {intl.get("footer_copyright", { currentYear })}
        </div>
      </div>
      <div className="links">
        <a href="/service-term" target="_blank" rel="noopener noreferer">
          {intl.get("footer_links_service_term")}
        </a>
        <a href="/service-term" target="_blank" rel="noopener noreferer">
          {intl.get("footer_links_privacy_policy")}
        </a>
        <a href="/service-term" target="_blank" rel="noopener noreferer">
          {intl.get("footer_links_contract_us")}: service@findmyacu.com
        </a>
      </div>
      <div className="responsive-copyright">
        {intl.get("footer_copyright", { currentYear })}
      </div>
      <div className="social-medias">
        {/* <span className="facebook"></span>
        <span className="google"></span>
        <span className="twitter"></span>
        <span className="instagram"></span> */}
      </div>
    </div>
  );
};
