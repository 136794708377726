import { Action } from "use-reaction";
import { getFtlStep, putFtlStep } from "../api/ftl";

export const model_ftl = {
  NAME: 'ftl',
  step_now: "",
  step_next: "",
  step_previous: "",
};
type T = typeof model_ftl;

// get ftl step from api
export const action_ftl_get_step: Action<T> = async () => {
  const res = await getFtlStep();
  return res;
};

// force ftl step in FE, used to back-step
export const action_ftl_put_step: Action<T> = async ({ payload: step }) => {
  await putFtlStep(step);
  return await getFtlStep();
};

export const action_ftl_finish_step: Action<T> = async ({
  payload: { values, api, next },
  store: { step_next },
}) => {
  await api(values);
  if (next) {
    await putFtlStep(step_next);
    return await getFtlStep();
  }
};
