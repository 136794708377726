import { BasicLayout } from "@/web/comps/basic_layout";
import { ReactComponent as IconShowEye } from "@/assets/images/icon-show-pwd.svg";
import { ReactComponent as IconHideEye } from "@/assets/images/icon-hide-pwd.svg";
import {
  email_reg,
  phone_reg,
  router_login,
  router_verify_email,
} from "@/web/utils/consts";
import { Form, Input, Checkbox, Button } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoading, useModel } from "use-reaction";
import { action_user_signup, model_user } from "@/web/models/model_user";
import "./index.scss";
import { ValidateStatus } from "antd/lib/form/FormItem";
export const SignUp = () => {
  const navigate = useNavigate();
  const [agreePolicy, setAgreePolicy] = useState(false);
  const { doAction } = useModel(model_user);
  const loading = useLoading(model_user);
  const [validateStatus, setValidateStatus] = useState<ValidateStatus>("");
  const onFinish = async ({ account, password }: any) => {
    if (account && !(email_reg.test(account) || phone_reg.test(account))) {
      setValidateStatus("error");
      return false;
    }

    const success = await doAction(
      action_user_signup,
      { account: account.trim(), password },
      "model"
    );
    if (success) {
      navigate(router_verify_email);
    }
  };

  return (
    <BasicLayout showUser={false}>
      <div className="common-page-style signup-page">
        <div className="signup-main">
          <div className="title-block"></div>
          <div className="section-title font-family-merriweather">Sign Up</div>
          <Form className="signup-form" layout="vertical" onFinish={onFinish}>
            <Form.Item
              name="account"
              label="Email / Phone"
              validateStatus={validateStatus}
              help={
                validateStatus === "error"
                  ? "Not valid format of email or phone number"
                  : undefined
              }
              rules={[
                {
                  required: true,
                  message: "Please input your email or phone number!",
                },
              ]}
            >
              <Input
                placeholder="Email address or phone number"
                onFocus={() => setValidateStatus("")}
              />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                iconRender={(visible) =>
                  visible ? <IconHideEye /> : <IconShowEye />
                }
              />
            </Form.Item>
            <div className="signup-form-links">
              <Checkbox
                checked={agreePolicy}
                onChange={(e) => setAgreePolicy(e.target.checked)}
              >
                <span className="txt">
                  I certify that I am 13 years of age or older, agree to the{" "}
                </span>
                <a
                  href="/service-term"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  Terms of Services
                </a>
                , and acknowledge the
                <a
                  href="/service-term"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  Privacy Policy.
                </a>
              </Checkbox>
            </div>
            <div className="signup-form-btns">
              <Button
                htmlType="submit"
                className="email"
                block={true}
                type="primary"
                disabled={!agreePolicy}
                loading={loading}
              >
                Sign up
              </Button>
            </div>
            <div className="go-login">
              Already have an account?
              <a href={router_login}>Log In</a>
            </div>
          </Form>
        </div>
      </div>
    </BasicLayout>
  );
};
