import { useMount } from "ahooks";
import { Button, Skeleton } from "antd";
import classNames from "classnames";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { SQUARE_LOCATION_ID } from "../utils/consts";
import { loadSquare } from "../utils/lib";
import "./square_card_input.scss";

export interface CardInfo {
  brand: string
  expMonth: number
  expYear: number
  last4: string
  postalCode: string
  paymentToken: string
}
interface Props {
  className?: string;
  required?: boolean;
  onOk?: (info: CardInfo) => void;
}
export const SquareCardInput = forwardRef(
  ({ className, onOk, required = true }: Props, ref: any) => {
    const cardEle = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);
    const [payments, setPayments] = useState<any>();
    const [card, setCard] = useState<any>();
    const attachCard = async () => {
      if (card) {
        return;
      }
      const cd = await payments.card();
      await cd.attach(cardEle.current);
      setCard(cd);
      setLoading(false);
    };

    const submit = async () => {
      const res = await card.tokenize();
      if (res.status === "OK") {
        const data = {
          paymentToken: res.token,
          ...res.details.card,
          ...res.details.billing
        };
        onOk && onOk(data);
        return data;
      } else {
        let err = `Tokenization failed with status: ${res.status}`;
        if (res.errors) {
          err = `square card token failed and errors: ${JSON.stringify(
            res.errors
          )}`;
        }
        console.error(err);
      }
    };

    useImperativeHandle(ref, () => ({ submit }));

    useMount(async () => {
      setLoading(true);
      const payments = await loadSquare();
      setPayments(payments);
    });

    useEffect(() => {
      payments && attachCard();
    }, [payments]);

    return (
      <div className={classNames("square-card-input", className)}>
        <div className={classNames("label", required && "required")}>
          card number
        </div>
        {loading && <Button loading className="loading">loading</Button>}
        <div className="card-con" ref={cardEle}></div>
      </div>
    );
  }
);
