import { BasicLayout } from "@/web/comps/basic_layout";
import CalendarIcon from "@/assets/images/icon-calendar-green.svg";
import BookedIcon from "@/assets/images/icon-booking-success.svg";
import { CalendarOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "./finished.scss";
import { useNavigate } from "react-router-dom";
import { router_account_appointment } from "@/web/utils/consts";
import { useModel } from "use-reaction";
import { model_booking } from "@/web/models/model_booking";
import moment from "moment";
export const BookingFinished = () => {
  const {store: {
    basic,
    target,
    bookDeposite
  }} = useModel(model_booking)
  const navigate = useNavigate();
  return (
    <BasicLayout>
      <div className="booking-finished">
        <div className="finished-summary">
          <img src={CalendarIcon} alt="date" />
          <div className="title">Appointment confirmed!</div>
          <div className="des">
            Please kindly note that appointment cancelled without 24 hours
            notice will be assessed a <span>${bookDeposite} cancellation fee</span>.
            <br/>
            Appointments are considered cancelled and forfeited 20 minutes after
            the appointment time.
          </div>
          <div className="info">
            <div className="left">
              <div className="head">
                <img src={BookedIcon} alt="date" />
                <span>{moment(basic.dateTime).format('ddd, MMM DD YYYY, hh:mm A')}</span>
              </div>
              <div className="btm">
                <span className="key">Doctor:</span>
                <span className="value">{target.doctor?.profile?.name}</span>
              </div>
            </div>
            <div className="right" aria-disabled>
              <CalendarOutlined />
              <span>Add to calendar</span>
            </div>
          </div>
          <div className="btns">
            {/* todo: back to profile will go doctor/clinic's profile page later */}
            <Button onClick={() => navigate(-2)}>Back to profile</Button>
            <Button
              onClick={() => navigate(router_account_appointment)}
              type="primary"
            >
              My Appointment
            </Button>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};
