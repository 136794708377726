import { BasicLayout } from "@/web/comps/basic_layout";
import { BookingBaseForm } from "@/web/comps/booking_base_form";
import { BookingCardForm } from "@/web/comps/booking_card_form";
import { BookingSummary } from "@/web/comps/booking_summary";
import {
  action_booking_edit,
  action_booking_get_target_info,
  action_booking_pay,
  model_booking,
} from "@/web/models/model_booking";
import {
  bookingStatusCfg,
  BookStatus,
  router_booking_failed,
  router_booking_finished,
} from "@/web/utils/consts";
import { useQuery } from "@/web/utils/util";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useMount } from "ahooks";
import { Button, Modal, Steps, Tag } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModel } from "use-reaction";
import "./index.scss";
export const Booking = () => {
  const navigate = useNavigate();
  const {
    doAction,
    store: { status },
  } = useModel(model_booking);
  const {
    clinicId,
    doctorId,
    serviceId = "0",
    id,
  } = useQuery<{
    clinicId: string;
    doctorId: string;
    serviceId?: string;
    id?: string;
  }>();
  const [step, setStep] = useState<number>(0);

  const getNextBtnLabel = () => {
    switch (step) {
      case 0:
        return "next to payment";
      case 1:
        return "continue";
      case 2:
        return "confirm";
    }
  };
  const basicRef = useRef<any>();
  const cardRef = useRef<any>();
  const next = async () => {
    switch (step) {
      case 0:
        await basicRef.current?.next();
        setStep(step + 1);
        break;
      case 1:
        await cardRef.current?.next();
        setStep(step + 1);
        break;
      case 2:
        onConfirm();
        break;
    }
  };

  const tryCancel = () => {
    Modal.confirm({
      okText: "Yes,Cancel",
      cancelText: "No,Continue",
      title: "Are you sure to cancel?",
      content: "Cancel book appointment, and back to last page.",
      width: 500,
      okButtonProps: { size: "large" },
      cancelButtonProps: { size: "large" },
      onOk: () => navigate(-1),
    });
  };

  const onConfirm = async () => {
    const res: any = await doAction(action_booking_pay, null, "global");
    navigate(
      res.status === BookStatus.BOOKING_SUCCESS
        ? router_booking_finished
        : router_booking_failed
    );
  };

  useMount(async () => {
    if (id) {
      const res: any = await doAction(action_booking_edit, id);
      switch (res.status) {
        case BookStatus.BOOKING_SUCCESS:
          navigate(router_booking_finished);
          break;
        case BookStatus.BOOKING_FAILED:
        case BookStatus.CANCEL_SUCCESS:
          navigate(router_booking_failed);
          break;
        default:
          setStep(2);
      }
    } else {
      await doAction(action_booking_get_target_info, {
        clinicId: +clinicId,
        doctorId: +doctorId,
      });
    }
  });

  return (
    <BasicLayout>
      <div className="booking-page">
        <div className="back" onClick={(e) => navigate(-1)}>
          <ArrowLeftOutlined />
          Back to {id ? "my appointment" : "profile"}
        </div>
        <Steps className="fill-step" direction="horizontal" current={step}>
          <Steps.Step title="Booking appointment" icon={null} />
          <Steps.Step title="Payment method" icon={null} />
          <Steps.Step title="Confirmation" icon={null} />
        </Steps>
        {id && (
          <div className="status">
            Reviewing
            <Tag color={bookingStatusCfg[status]?.color || "default"}>
              {bookingStatusCfg[status]?.label || status}
            </Tag>
          </div>
        )}
        {step === 0 ? (
          <BookingBaseForm ref={basicRef} clinicId={+clinicId} serviceId={+serviceId} />
        ) : step === 1 ? (
          <>
            <BookingSummary />
            <BookingCardForm ref={cardRef} />
          </>
        ) : (
          <BookingSummary />
        )}

        <div className="btns">
          <Button onClick={tryCancel}>Cancel</Button>
          <Button hidden={step < 1} onClick={() => setStep(step - 1)}>
            Back
          </Button>
          <Button type="primary" onClick={next}>
            {getNextBtnLabel()}
          </Button>
        </div>
      </div>
    </BasicLayout>
  );
};
