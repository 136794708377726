import React, { Component, useState } from "react";
import { Skeleton, message } from "antd";
import OurStatisticsImg from "@/assets/images/our-statistics.png";
import { getOurStatistics } from "@/web/api/home";
import intl from "react-intl-universal";
import { useInterval, useRequest } from "ahooks";
import { formatNumber } from "@/web/utils/util";
import { isEqual } from "lodash";

const OUR_STATISTICS = [
  {
    name: "clinics",
    num: "clinicAmount",
  },
  {
    name: "doctors",
    num: "doctorAmount",
  },
  {
    name: "cured",
    num: "curedPatientAmount",
  },
];
export const SectionStatistics = () => {
  const [stats, setStats] = useState<any>({})
  const [loop, setLoop] = useState(0)
  const { data = {}, loading } = useRequest<any, any>(async () => {
    const data = await getOurStatistics();
    return {
      clinicAmount: (data.clinics || 0) + 500,
      doctorAmount: (data.doctors || 0) + 500,
      curedPatientAmount: (data.curedPatients || 0) + 500,
    };
  }, {});

  const clearNumSteper = useInterval(()=>{
    if(loading){
      return
    }
    if(isEqual(data,stats)) {
      clearNumSteper()
      return
    }
    for(let key in data){
      const val = data[key] || 0
      const now = stats[key] || 0
      const delta = Math.min(val - now, Math.pow(2, loop))
      if (delta > 0) {
        const step = Math.random() * delta >> 0
        stats[key] = now + step + 1;
      }
    }
    setLoop(loop + 1)
    setStats({...stats})
  }, 136)
  return (
    <div className="section our-statistics-section">
      <div className="our-statistics">
        <div className="our-statistics-left">
          <div className="title">
            {intl.get("homepage_our_statistics_description_title")}
          </div>
          <div className="description">
            {intl.get("homepage_our_statistics_description_content_1")}
          </div>
          <div className="description">
            {intl.get("homepage_our_statistics_description_content_2")}
          </div>
          <div className="description">
            {intl.get("homepage_our_statistics_description_content_3")}
          </div>
          <div className="description">
            {intl.get("homepage_our_statistics_description_content_4")}
          </div>
        </div>
        <div className="our-statistics-middle">
          <div className="section-block-container">
            <div className="section-block"></div>
          </div>
          <div className="title">
            {intl.get("homepage_our_statistics_title")}
          </div>
          {OUR_STATISTICS.map((item, index) => (
            <div key={index} className="our-statistics-item">
              {loading ? (
                <Skeleton paragraph={false} active={true} />
              ) : (
                <div className="num font-family-merriweather">
                  {formatNumber(stats[item.num as keyof typeof stats])}
                </div>
              )}
              <div className="name">
                {intl.get(`homepage_our_statistics_${item.name}`)}
              </div>
            </div>
          ))}
        </div>
        <div className="our-statistics-right">
          <div className="img-container">
            <img src={OurStatisticsImg} alt="our-statistics-img" />
          </div>
        </div>
      </div>
    </div>
  );
};
