import { UserOutlined } from "@ant-design/icons";
import { Avatar, Divider } from "antd";
import moment from "moment";
import { useModel } from "use-reaction";
import { model_booking } from "../models/model_booking";
import { model_user } from "../models/model_user";
import { formatNumber, getCardIcon } from "../utils/util";
import "./booking_summary.scss";

export const BookingSummary = () => {
  const {
    store: {
      target: { doctor },
      basic,
      cardData,
      bookDeposite,
    },
  } = useModel(model_booking);
  const {
    store: { profile: user },
  } = useModel(model_user);

  return (
    <div className="booking-section booking-summary">
      <div className="section-title">Summary</div>
      <div className="summary-section-doctor">
        <div className="doctor-avatar">
          <Avatar
            size={Math.min(((document.body.clientWidth / 1440) * 48) >> 0, 48)}
            icon={<UserOutlined />}
            src={doctor?.profile?.avatar?.url}
          />
        </div>
        <div className="doctor-info">
          <div className="name">{doctor?.profile?.name}</div>
          <div className="title">{doctor?.profile?.medicalTitle}</div>
        </div>
        <div className="doctor-book">
          <div className="time">
            {moment(basic.dateTime).format("HH:mm A")} -{" "}
            {moment(basic.dateTime)
              .add(basic.selectedTreatmentService.duration, "minute")
              .format("HH:mm A")}
          </div>
          <div className="date">
            {moment(basic.dateTime).format("dddd, MMM DD YYYY")}
          </div>
        </div>
      </div>
      <Divider type="horizontal" />
      <div className="summary-section-patient">
        {doctor?.clinic?.name && (
          <div className="patient-item">
            <div className="key">Clinic:</div>
            <div className="value">{doctor?.clinic?.name}</div>
          </div>
        )}
        <div className="patient-item">
          <div className="key">Address:</div>
          <div className="value">{doctor?.clinic?.address}</div>
        </div>
        <div className="patient-item">
          <div className="key">Tel:</div>
          <div className="value">{doctor?.clinic?.phone}</div>
        </div>
        <div className="patient-item">
          <div className="key">Patients:</div>
          <div className="value">{basic.patientName || user?.name}</div>
        </div>
        <div className="patient-item">
          <div className="key">Service:</div>
          <div className="value">{basic.selectedTreatmentService?.label}</div>
        </div>
        <div className="patient-item">
          <div className="key">Health problem:</div>
          <div className="value">{basic.healthProblem}</div>
        </div>
      </div>
      <Divider type="horizontal" />

      {cardData?.last4 && (
        <>
          <div className="summary-section-cardData">
            <div className="key">Payment method:</div>
            <div className="value">
              <img src={getCardIcon(cardData.brand)} alt="cardData-img" />
              <span className="text"> ending in {cardData.last4}</span>
            </div>
          </div>
          <Divider type="horizontal" />
        </>
      )}
      <div className="summary-section-fee">
        <div className="key">Total</div>
        <div className="value">
          ${formatNumber(basic.selectedTreatmentService?.price || 0)}
        </div>
      </div>
      {bookDeposite && (
        <div className="summary-section-fee">
          <div className="key">Deposit</div>
          <div className="value">${formatNumber(bookDeposite || 0)}</div>
        </div>
      )}
    </div>
  );
};
