import { PlusOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import { Button, Divider, Input, InputRef, Select, Space } from "antd";
import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Opt } from "../utils/types";
import "./search_select.scss";

type V = string | number;
interface Props {
  allowClear?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  value?: V | V[];
  mode?: "multiple" | "tags";
  onChange?: (val: V | V[], opt: Opt | Opt[]) => void;
  searchFn: (keyword: string) => Promise<Opt[]>;
  searchOnMount?: boolean;
  allowAdd?: boolean;
}

export const SearchSelect = ({
  allowClear = false,
  autoFocus = false,
  placeholder = "search and select",
  className = "",
  style,
  value,
  mode,
  searchFn,
  searchOnMount = false,
  onChange,
  allowAdd,
}: Props) => {
  const [keyword, setKeyword] = useState("");
  const [val, setVal] = useState(value);
  const { data, loading } = useRequest(() => searchFn(keyword), {
    refreshDeps: [keyword],
    ready: searchOnMount || !!keyword,
    throttleLeading: true,
  });
  const [added, setAdded] = useState<Opt>();
  const [name, setName] = useState("");
  const items = useMemo(
    () => (added ? [...(data || []), added] : data),
    [data, added]
  );
  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setVal(name);
  };

  const addItem = (e: any) => {
    e.preventDefault();

    const creating = {
      value: 0,
      label: name,
      isNew: true,
    };
    setAdded(creating);
    setName("");

    setTimeout(() => {
      // call on change after creating-item added to option list
      onChange && onChange("", creating);
    }, 0);
  };

  useEffect(() => {
    setVal(value);
  }, [value]);
  return (
    <Select
      showSearch
      value={name || (val as any)}
      mode={mode}
      allowClear={allowClear}
      autoFocus={autoFocus}
      className={`search-select ${className}`}
      style={style}
      placeholder={placeholder}
      optionFilterProp="label"
      loading={loading}
      options={items}
      onChange={onChange}
      onSearch={setKeyword}
      filterOption={(input, option) =>
        !!option?.label?.toLowerCase().includes(input.toLowerCase())
      }
      {...(allowAdd
        ? {
            dropdownRender: (menu) => (
              <div className="search-select-dropdown">
                {menu}
                <Divider />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Please enter name to add"
                    value={name}
                    onChange={onNameChange}
                    onPressEnter={addItem}
                  />
                  <Button
                    disabled={!name}
                    // type="primary"
                    className="add-new-btn"
                    size="large"
                    icon={<PlusOutlined />}
                    onClick={addItem}
                  >
                    Add New
                  </Button>
                </Space>
              </div>
            ),
          }
        : {})}
    />
  );
};
