import { BasicLayout } from "@/web/comps/basic_layout";
import "./index.scss";

export const ServiceTerm = () => {
  return (
    <BasicLayout>
      <div className="service-term">
        <div className="service-term-title">Terms of Use</div>
        <div className="service-term-date">Effective Date: January 1, 2022</div>
        <div className="service-term-description">
          Your continued use of this site to access, view, and use material on
          the site constitutes an affirmative act indicating your full
          understanding and acceptance of the below terms of use (“Terms and
          Conditions”) which govern the use of FINDMYACUPUNCTURIST.NET in its
          entirety.{" "}
        </div>
        <div className="service-term-list">
          {/* 1 */}
          <div className="service-term-item">
            <div className="service-term-item-title">
              1.Updates and Termination
            </div>
            <div className="service-term-item-content">
              <p>
                We reserve the right to make improvements and/or changes to any
                contents of the website, including but not limited to
                adding/substituting/discontinuing features, and updating the
                terms of this agreement (“Terms and Conditions”) at any time and
                without prior notice. Any changes will be effective as soon as
                it is published on the website. Users will assume the
                responsibility of reviewing the terms of the agreement
                periodically. If you do not agree with content modifications,
                you must discontinue use of the website. Continued use of the
                site will constitute continued agreement to the revised terms
                and conditions as published. Under no circumstances will
                FINDMYACUPUNCTURIST.NET be liable for any damages/liabilities
                for any updates and changes to the content of the website. We
                reserve the right to terminate or deny your access to the site
                in the event that you violate these Terms and Conditions, or for
                any reason whatever.
              </p>
            </div>
          </div>
          {/* 2 */}
          <div className="service-term-item">
            <div className="service-term-item-title">
              2.Disclaimer of Medical Services or Advice
            </div>
            <div className="service-term-item-content">
              <p>
                While FINDMYACUPUNCTURIST.NET facilitates your selection of,
                communications with and other interactions of your
                acupuncturist, FINDMYACUPUNCTURIST.NET does NOT provide medical
                advice or services, and does NOT interfere with acupuncturist’s
                treatment or their independent professional judgment. All
                content found on FINDMYACUPUNCTURIST.NET is provided for general
                information purpose ONLY. Nothing on the site, expressed or
                implied, is intended as nor shall be construed as medical
                advice. Use of the site does NOT establish any doctor-patient
                relationship between you and FINDMYACUPUNCTURIST.NET under any
                circumstances. If you have questions about medical issues, or
                sensitive or confidential matters, you should immediately
                contact appropriate health care professionals.
              </p>
            </div>
          </div>
          {/* 3 */}
          <div className="service-term-item">
            <div className="service-term-item-title">
              3.Disclaimer of Legal Advice
            </div>
            <div className="service-term-item-content">
              <p>
                All information found on FINDMYACUPUNCTURIST.NET is provided for
                general information purpose ONLY. Nothing on
                FINDMYACUPUNCTURIST.NET, expressed, or implied, is intended as,
                nor shall be construed as legal advice, guidance, or
                interpretation. Use of the site does NOT establish any
                attorney-client relationship between you and
                FINDMYACUPUNCTURIST.NET under any circumstances. If you have
                questions about any law, statute, regulation, or requirement
                expressly or implicitly referenced on the site, please contact
                your own legal counsel.
              </p>
            </div>
          </div>
          {/* 4 */}
          <div className="service-term-item">
            <div className="service-term-item-title">
              4.Disclaimer of Warranty and Liability
            </div>
            <div className="service-term-item-content">
              <p>
                USE OF THIS SITE IS ENTIRELY AT YOUR OWN RISK. NEITHER
                FINDMYACUPUNCTURIST.NET NOR ANY OF ITS AFFILIATES ARE
                RESPONSIBLE FOR THE CONSEQUENCES OF RELIANCE ON ANY INFORMATION
                CONTAINED IN OR SUBMITTED TO THE SITE, AND THE RISK OF INJURY
                FROM THE FOREGOING RESTS ENTIRELY WITH YOU.{" "}
              </p>
              <p>
                FINDMYACUPUNCTURIST.NET DOES NOT WARRANT, OR MAKE ANY
                REPRESENTATIONS REGARDING THE USE, VALIDITY, ACCURACY OR
                RELIABILITY OF THE CONTENTS AVAILABLE ON OUR SITE, NOR ANY OTHER
                SITES LINKED TO OR FROM OUR SITE. THESE MATERIALS ARE PROVIDED
                ON AN "AS IS" BASIS WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS
                OR IMPLIED. TO THE FULLEST EXTENT POSSIBLE UNDER APPLICABLE LAW,
                FINDMYACUPUNCTURIST.NET DISCLAIM ALL WARRANTIES, EXPRESS OR
                IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                NON-INFRINGEMENT.
              </p>
              <p>
                FINDMYACUPUNCTURIST.NET AND ALL ITS RELATED AFFILIATES SHALL NOT
                BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT, INCIDENTAL,
                CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING WITHOUT
                LIMITATION, LOST REVENUES OR LOST PROFITS, WHICH MAY RESULT FROM
                THE USE OF, ACCESS TO, OR INABILITY TO USE THESE MATERIALS.
                UNDER NO CIRCUMSTANCES WILL THE TOTAL LIABILITY OF FINDMY.NET TO
                YOU EXCEED THE PRICE PAID FOR USE OF THE SITE.
              </p>
            </div>
          </div>
          {/* 5 */}
          <div className="service-term-item">
            <div className="service-term-item-title">5.lndemnification</div>
            <div className="service-term-item-content">
              <p>
                You agree to defend, indemnify, and hold harmless
                FINDMYACUPUNCTURIST.NET, its operators, affiliates and
                subsidiaries, and all partners, shareholders, principals,
                agents, and attorneys from and against any and all claims,
                proceedings, damages (actual and consequential), injuries,
                liabilities, losses, costs, and expenses (including attorney's
                fees and litigation expenses) relating to or arising from your
                use of the Site and any breach by you of these Terms and
                Conditions.{" "}
              </p>
            </div>
          </div>
          {/* 6 */}
          <div className="service-term-item">
            <div className="service-term-item-title">
              6.Confidentiality Cannot be Guaranteed
            </div>
            <div className="service-term-item-content">
              <p>
                We at FINDMYACUPUNCTURIST.NET take your confidentiality very
                seriously, and do our best to keep your data safe. However, due
                to the nature of electronic data transfers and storage please be
                advised that the confidentiality of any communication or
                material transmitted to FINDMYACUPUNCTURIST.NET via the website
                or Internet electronic mail cannot be guaranteed.
                <span className="font-color-red">
                  For details please see our Privacy Policy page disclosing what
                  information we collect about visitors, how we use such
                  information, the steps we take to secure such information, how
                  you can view and correct such information, and how you can
                  decline to have information about you collected or used.
                </span>
              </p>
            </div>
          </div>
          {/* 7 */}
          <div className="service-term-item">
            <div className="service-term-item-title">
              7.Links to Other Web Sites
            </div>
            <div className="service-term-item-content">
              <p>
                User acknowledges that any links to other internet websites
                provided on FINDMYACUPUNCTURIST.NET for the convenience of users
                in locating information and services that may be of interest,
                are maintained by persons or organizations over which
                FINDMYACUPUNCTURIST.NET have no control.{" "}
              </p>
              <p>
                FINDMYACUPUNCTURIST.NET expressly disclaims any responsibility
                for all contents, including but not limited to, the accuracy of
                the information, the quality of products or services provided by
                or advertised on these third-party sites.{" "}
              </p>
              <p>
                FINDMYACUPUNCTURIST.NET does not control, endorse, promote, or
                have any affiliation with any other Web site unless expressly
                stated in the Site. Additionally, FINDMYACUPUNCTURIST.NET does
                not control or manage the privacy practices of third parties,
                and you are subject to the privacy practices of those third
                parties when you use their services.
              </p>
            </div>
          </div>
          {/* 8 */}
          <div className="service-term-item">
            <div className="service-term-item-title">
              8.User Generated Content
            </div>
            <div className="service-term-item-content">
              <p>
                FINDMYACUPUNCTURIST.NET welcomes your feedback, comments,
                reviews, and other posts (“User Generated Content”) regarding
                our Site and Services. The User Generated Content must be
                appropriate and otherwise complies with these Terms of Use as
                applicable. All User Generated Content submitted will
                automatically be considered non-confidential and non-proprietary
                to the user, and grants FINDMYACUPUNCTURIST.NET non-exclusive,
                worldwide, fully paid-up, royalty-free, irrevocable,
                sub-licensable, perpetual, transferable and sub-licensable right
                and license to use, display, disclose, perform, reproduce,
                transmit, copy, modify, delete, adapt, publish, translate,
                creative derivative works from, sell and distribute such User
                Generated Content for any purpose, without compensation to you
                the user.
              </p>
              <p>
                By posting or submitting your User Generated Content to our
                Service, you expressly agree NOT to submit User Generated
                Content which: (i) is harmful, inaccurate, threatening, abusive,
                harassing, tortious, deceptive, defamatory, vulgar, obscene,
                pornographic, libelous, fraudulent, invasive of another's
                privacy or other rights, hateful or otherwise objectionable;
                (ii) violates any law, including without limitation
                fake/fraudulent reviews and testimonials, and/or review and
                testimonials influenced by, including without limitation, money,
                gifts. publicity or relationship, not otherwise clearly and
                properly disclosed, (iii) violates any regulation or right of
                any third party, including without limitation, copyright,
                trademark, patent, privacy or publicity rights or any other
                proprietary rights; and/or (iv) contains malware, commercial
                solicitations, political campaigning, mass mailings or "spam" of
                any kind.
              </p>
              <p>
                You acknowledge and agree that FINDMYACUPUNCTURIST.NET has the
                right (but not the obligation) to do any or all of the following
                at any time: (a) monitor User Generated Content; (b) remove or
                refuse to post any User Generated Content; (c) disclose User
                Generated Content to any third party, and the circumstances
                surrounding submission, and/or (d) to investigate and/or take
                appropriate legal action against persons/entities who violate
                the Terms of Use.
              </p>
              <p>
                You alone, and NOT FINDMYACUPUNCTURIST.NET, are responsible for
                ensuring you have the rights to grant the license described in
                these Terms of Use and for FINDMYACUPUNCTURIST.NET to post the
                User Generated Content, including, but not limited to, any
                copyright rights to images or other content, and the right to
                publish the images of people, landscapes and/or other creative
                works within the User Generated Content.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};
