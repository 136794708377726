import React, { useEffect, useState } from "react";
import { Input } from "antd";
import PlaceAutocomplete, { geocodeByPlaceId } from "react-places-autocomplete";
import "./auto_complete_addr.scss";
import { AddrData } from "../utils/types";
import { loadGoogleMap } from "../utils/lib";
import { useMount } from "ahooks";

interface Props {
  value?: string;
  disabled?: boolean;
  onChange: (addrData: AddrData) => void;
}
export const AutoCompleteAddr = ({ value = "", onChange, disabled }: Props) => {
  const [ready, setReady] = useState(false);
  const [address, setAddress] = useState(value);
  const handleAddressChange = (val: string) => {
    setAddress(val);
    onChange({ raw: "", terms: [] });
  };
  const handleAddressSelect = async (
    val: string,
    placeId: string,
    suggestion: any
  ) => {
    setAddress(val);
    const geoCode = await geocodeByPlaceId(placeId);
    onChange({
      id: placeId,
      raw: suggestion.description,
      terms: suggestion.terms.map((m: any) => m.value),
      ...geoCode?.length ? {
        latlng: [geoCode[0].geometry.location.lat(), geoCode[0].geometry.location.lng()].join(',')
      } : {}
    });
  };

  useEffect(() => {
    setAddress(value);
  }, [value]);
  useMount(() => {
    loadGoogleMap().then((_) => setReady(_));
  });
  return (
    <div className="auto-complete-place">
      {ready ? (
        <PlaceAutocomplete
          onChange={handleAddressChange}
          onSelect={handleAddressSelect as any}
          value={address}
        >
          {({
            getInputProps,
            getSuggestionItemProps,
            suggestions,
            loading,
          }) => (
            <>
              <Input
                {...getInputProps({
                  placeholder: "Clinic’s Address",
                  className: "location-search-input",
                })}
                disabled={disabled}
              />
              <div className="autocomplete-dropdown-container">
                {loading ? <div className="loading">Loading...</div> : null}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? {
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                      fontWeight: "bold",
                    }
                    : { backgroundColor: "#ffffff" };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={index}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </PlaceAutocomplete>
      ) : (
        <Input placeholder="address" disabled={disabled} />
      )}
    </div>
  );
};
