import { getFtlacuProfile, putFtlacuProfile } from "@/web/api/ftl";
import { SearchSelect } from "@/web/comps/search_select";
import { UploadAvatar } from "@/web/comps/upload_avatar";
import { action_ftl_finish_step, model_ftl } from "@/web/models/model_ftl";
import { Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useModel } from "use-reaction";
import { FTLProgress } from "./ftl_progress";
import "./acu_profile.scss";
import { useNavigate } from "react-router";
import { useMount } from "ahooks";
import { forwardRef, useImperativeHandle, useState } from "react";
import { ImgData } from "@/web/utils/types";
import { getUserInfo } from "@/web/api/user";
import classNames from "classnames";
import { getLanguages } from "@/web/api/options";
interface Props {
  showExtra?: boolean;
  className?: string;
  onSave?: (values?: any) => void;
}
export const AcuProfile = forwardRef(
  ({ showExtra = true, className, onSave }: Props, ref) => {
    const navigate = useNavigate();
    const [form] = useForm();
    const [avatar, setAvatar] = useState<ImgData>();
    const { doAction } = useModel(model_ftl);

    useImperativeHandle(ref, () => ({
      save: form.submit,
    }));
    const updateAvatar = (img: ImgData) => {
      // call api to refresh user, then got updated info/avatar
      setAvatar(img);
    };
    const onFinish = async (values: any, next?: boolean) => {
      await doAction(
        action_ftl_finish_step,
        {
          values,
          api: putFtlacuProfile,
          next,
        },
        "global"
      );
      onSave && onSave(values);
    };
    const goHome = () => navigate("/");

    const goNext = async () => {
      const filled = await form.validateFields();
      if (filled) {
        await onFinish(filled, true);
      }
    };

    useMount(async () => {
      const profile = await getFtlacuProfile();
      profile && form.setFieldsValue(profile);
      const user = await getUserInfo();
      user.avatar && setAvatar(user.avatar);
    });

    return (
      <>
        <div className={classNames("fill-profile-page", className)}>
          <div className="profile-form doctor-profile-form">
            <div className="common-title-of-profile">
              {showExtra && (
                <>
                  <div className="profile-page-title">
                    Welcome to Find My Acupuncturist!
                  </div>
                  <div className="profile-page-description">
                    Set up your profile so patients can search and book
                    appointments with you.
                  </div>
                </>
              )}
              <UploadAvatar onFinish={updateAvatar} initImg={avatar} />
            </div>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
                name="name"
                label="Your name"
                rules={[
                  {
                    required: true,
                    message: "Missing your name!",
                  },
                ]}
              >
                <Input placeholder="Your name" />
              </Form.Item>
              <Form.Item
                name="medicalTitle"
                label="Your medical title(s)"
                rules={[
                  {
                    required: true,
                    message: "Missing medical titles(s)!",
                  },
                ]}
              >
                <Input placeholder="PhD, MD, LAc..." />
              </Form.Item>
              <Form.Item
                name="gender"
                label="Gender"
                rules={[
                  {
                    required: true,
                    message: "Missing gender!",
                  },
                ]}
              >
                <Select placeholder="Please select">
                  <Select.Option value="female">Female</Select.Option>
                  <Select.Option value="male">Male</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="languageIds"
                label="Language(s)"
                rules={[
                  {
                    required: true,
                    message: "Missing languages!",
                  },
                ]}
              >
                <SearchSelect
                  mode="multiple"
                  placeholder="Please select"
                  searchOnMount
                  searchFn={getLanguages}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
        {showExtra && (
          <FTLProgress
            currentIndex={1}
            lastIndex={5}
            goNext={goNext}
            onCancel={goHome}
            onFinish={goHome}
          />
        )}
      </>
    );
  }
);
