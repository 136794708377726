import {
  getUserBasicInfo,
  getUserInfo,
  putUserBasicInfo,
} from "@/web/api/user";
import { UploadAvatar } from "@/web/comps/upload_avatar";
import { action_user_get_info, model_user } from "@/web/models/model_user";
import { locationStatesOptions } from "@/web/utils/consts";
import { ImgData } from "@/web/utils/types";
import { useMount } from "ahooks";
import { Form, Input, Select, Button, message, DatePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useState } from "react";
import { useModel } from "use-reaction";
import "./account_setting_basic_info.scss";
import { AccountSettingProgress } from "./account_setting_progress";
const { getData: getCountryList } = require("country-list");
const countryList = getCountryList();

const birth_day_format = "YYYY-MM-DD";
export const AccountSettingBasicInfo = () => {
  const [form] = useForm();
  const [avatar, setAvatar] = useState<ImgData>();
  const { doAction: doActionUser } = useModel(model_user);
  const updateAvatar = (data: ImgData) => {
    // update user's avatar in model
    setAvatar(data);
    doActionUser(() => ({
      avatar: data as any,
    }));
  };
  const onFinish = async (values: any) => {
    const { name, gender, birthDay, userPhone, ...useraddressTable } = values;
    await putUserBasicInfo({
      name,
      gender,
      birthDay: birthDay ? moment(birthDay).format(birth_day_format) : "",
      userPhone,
      useraddressTable,
    });
    await doActionUser(action_user_get_info);
    message.success("saved success!");
  };
  useMount(async () => {
    const profile = await getUserBasicInfo();
    if (profile) {
      const { useraddressTable = {}, birthDay, ...others } = profile;
      form.setFieldsValue({
        ...others,
        ...useraddressTable,
        birthDay: birthDay ? moment(birthDay) : undefined,
        country: useraddressTable.country || "US",
      });
    }
    const user = await getUserInfo();
    user.avatar && setAvatar(user.avatar);
  });

  return (
    <div className="account-setting">
      <div className="info">
        <div className="info-title">Personal Details</div>
        <UploadAvatar onFinish={updateAvatar} initImg={avatar} />
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="name"
            label="Your name"
            rules={[
              {
                required: true,
                message: "Missing your name!",
              },
            ]}
          >
            <Input placeholder="Your name" />
          </Form.Item>
          <Form.Item
            name="userPhone"
            label="Your number"
            rules={[
              {
                required: true,
                message: "Missing number!",
              },
            ]}
          >
            <Input placeholder="716-803-8899" />
          </Form.Item>
          <div className="flex">
            <Form.Item
              name="gender"
              label="Gender"
              rules={[
                {
                  required: true,
                  message: "Missing gender!",
                },
              ]}
            >
              <Select placeholder="Please select">
                <Select.Option value="female">Female</Select.Option>
                <Select.Option value="male">Male</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Birthday" name="birthDay">
              <DatePicker
                defaultValue={moment("2000-01-01")}
                format={birth_day_format}
              />
            </Form.Item>
          </div>
          <Form.Item label="Address Line 1" name="addLine1">
            <Input placeholder="1234 Main St" />
          </Form.Item>
          <Form.Item label="Address Line 2" name="addLine2">
            <Input placeholder="Apartment, studio or floor" />
          </Form.Item>
          <div className="flex">
            <Form.Item label="Suburb / City" name="city">
              <Input placeholder="city" />
            </Form.Item>

            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  // required: true,
                  whitespace: true,
                  message: "Choose Country",
                },
              ]}
            >
              <Select showSearch placeholder="Country">
                {Object.values(countryList)?.map((item: any) => (
                  <Select.Option value={item.code} key={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="flex">
            <Form.Item noStyle dependencies={["country"]}>
              {({ getFieldValue }) => {
                return getFieldValue("country") === "US" ? (
                  <Form.Item
                    label="State"
                    name="state"
                    className="select-item"
                    rules={[
                      {
                        // required: true,
                        whitespace: false,
                        message: "Select State",
                      },
                    ]}
                  >
                    <Select showSearch placeholder="State">
                      {Object.values(locationStatesOptions)?.map((item) => (
                        <Select.Option value={item} key={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item
                    label="State / Province"
                    name="state"
                    rules={[
                      {
                        // required: true,
                        whitespace: true,
                        message: "Enter State",
                      },
                    ]}
                  >
                    <Input placeholder="State" />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item
              label="Zip Code"
              name="zip"
              rules={[
                {
                  required: false,
                  message: "Zip Code",
                },
              ]}
            >
              <Input maxLength={30} placeholder="Zip Code" />
            </Form.Item>
          </div>
        </Form>
      </div>
      <div className="submit-btn">
        <Button type="primary" onClick={form.submit}>
          Save
        </Button>
      </div>
      <AccountSettingProgress />
    </div>
  );
};
