import { delClinicPic, getClinicById, getClinicByName } from "@/web/api/clinic";
import { getFtlacuClinic, putFtlacuClinic } from "@/web/api/ftl";
import { AutoCompleteAddr } from "@/web/comps/auto_complete_addr";
import { SearchSelect } from "@/web/comps/search_select";
import { UploadPic } from "@/web/comps/upload_pic";
import {
  action_ftl_finish_step,
  action_ftl_put_step,
  model_ftl,
} from "@/web/models/model_ftl";
import { API_HOST, router_profile_with_slug } from "@/web/utils/consts";
import { AddrData, ImgData, Opt } from "@/web/utils/types";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useMount } from "ahooks";
import { Checkbox, Divider, Form, Input, message, Modal, Radio } from "antd";
import { useForm } from "antd/lib/form/Form";
import useModal from "antd/lib/modal/useModal";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useNavigate } from "react-router";
import { useModel } from "use-reaction";
import "./acu_clinic.scss";
import { FTLProgress } from "./ftl_progress";
interface Props {
  showExtra?: boolean;
  canEdit?: boolean;
  canCreate?: boolean;
  className?: string;
  onSave?: (values?: any) => void;
}
type ClinicOpt = Opt & { isNew?: boolean };
export const AcuClinic = forwardRef(
  (
    {
      showExtra = true,
      canEdit = false,
      canCreate = true,
      className,
      onSave,
    }: Props,
    ref
  ) => {
    const [form] = useForm();
    const navigate = useNavigate();
    useImperativeHandle(ref, () => ({
      save: form.submit,
      view: () =>
        navigate(
          router_profile_with_slug
            .replace(":type", "clinic")
            .replace(":id", clinic?.value?.toString() || "")
            .replace(":slug", clinic?.label?.replace(/\s+/g, "-") || "")
        ),
    }));
    const {
      doAction,
      store: { step_previous },
    } = useModel(model_ftl);
    const [isOwner, setIsOwner] = useState(false);
    const [guaranteed, setGuaranteed] = useState(false);
    const [addr, setAddr] = useState<AddrData>();
    const [clinic, setClinic] = useState<ClinicOpt>();
    const [fileList, setFileList] = useState<ImgData[]>([]);
    const [previewImage, setPreviewImage] = useState<ImgData>();
    const getClinicOpts = async (keyword: string) => {
      const clinics: { c_id: string; c_name: string }[] = await getClinicByName(
        keyword
      );
      return clinics.map((_) => ({ value: _.c_id, label: _.c_name }));
    };
    const onFinish = async (values: any, next?: boolean) => {
      if (!guaranteed) {
        throw message.error(
          "You should guarantee the reality and responsbility of your information"
        );
      }
      if(!clinic?.label){
        throw message.error(
          "Clinic name is required"
        );
      }
      const data = {
        ...values,
        id: clinic?.value,
        name: clinic?.label,
        address: addr?.raw,
        addressTable: addr,
        isOwner,
      };
      onSave
        ? onSave(data)
        : await doAction(
            action_ftl_finish_step,
            {
              values: data,
              api: putFtlacuClinic,
              next,
            },
            "global"
          );
    };
    const goHome = () => navigate("/");

    const goNext = async () => {
      const filled = await form.validateFields();
      if (filled) {
        await onFinish(filled, true);
      }
    };
    const goBack = () => {
      doAction(action_ftl_put_step, step_previous, "global");
    };

    const [modal, modalComp] = useModal();
    const getMyAcuClinic = () =>
      getFtlacuClinic().then((data) => {
        const { id, name, address, isOwner, ...others } = data;
        form.setFieldsValue(others);
        setClinic({ value: id, label: name });
        setAddr({ raw: address, terms: [] });
        setIsOwner(isOwner);
      });

    const onChooseClinic = (_: any, opt: ClinicOpt) => {
      setClinic(opt as Opt);
      if (opt.isNew) {
        // creating, empty form
        setAddr(undefined);
        form.setFieldsValue({
          phone: undefined,
          selfIntroduction: undefined,
        });
        setFileList([]);
      }
    };

    useMount(() => {
      getMyAcuClinic();
    });

    useEffect(() => {
      clinic?.value &&
        getClinicById(clinic!.value).then((data) => {
          setAddr(data.addressTable);
          setFileList(data.pictures);
          form.setFieldsValue({
            phone: data.phone,
            selfIntroduction: data.selfIntroduction,
            // isOwner: false,
          });
        });
    }, [clinic?.value, form]);
    return (
      <>
        <div className={classNames("fill-profile-page", className)}>
          <div className="profile-form clinic-profile-form">
            {showExtra && (
              <div className="common-title-of-profile">
                <div
                  className={classNames(
                    "profile-page-title",
                    clinic?.isNew && "creating"
                  )}
                >
                  Clinic {clinic?.isNew ? "Creation" : "Information"}
                </div>
                <div className="profile-page-description">
                  Set up your clinic profile so patients can search and book
                  appointments with you.
                </div>
              </div>
            )}
            <Modal
              visible={!!previewImage}
              title="Image Preview"
              closable={true}
              footer={null}
              width={548}
              onCancel={() => setPreviewImage(undefined)}
            >
              <img
                src={previewImage?.url}
                alt={previewImage?.name}
                style={{ width: "100%", height: "500px" }}
              />
            </Modal>
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Form.Item
                label="Clinic Name"
                validateStatus={clinic?.label ? "success" : "error"}
                help={clinic?.label ? undefined : "Missing clinic name!"}
                className="clinic-search-select"
              >
                <SearchSelect
                  searchFn={getClinicOpts}
                  value={clinic?.label?.toString()}
                  onChange={onChooseClinic}
                  allowAdd
                  allowClear
                />
              </Form.Item>
              <div className="owner-tip" hidden={!canCreate}>
                <span>Are you the Owner/Administrator of this clinic?</span>
                <Radio.Group
                  value={isOwner}
                  onChange={(e) => setIsOwner(e.target.value)}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </div>
              <Form.Item
                label="Clinic’s Address"
                rules={[
                  {
                    required: true,
                    message: "Missing clinic’s address!",
                  },
                ]}
              >
                <AutoCompleteAddr
                  disabled={!canEdit && !isOwner && !clinic?.isNew}
                  value={addr?.raw}
                  onChange={setAddr}
                />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Clinic’s Phone"
                rules={[
                  {
                    required: false,
                    message: "Missing clinic’s phone!",
                  },
                ]}
              >
                <Input
                  placeholder="716-803-8899"
                  disabled={!canEdit && !isOwner && !clinic?.isNew}
                />
              </Form.Item>
              <Form.Item
                name="selfIntroduction"
                label="Introduction"
                rules={[
                  {
                    required: false,
                    message: "Missing introduction!",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Introduce about your clinic"
                  style={{ height: "102px" }}
                  disabled={!canEdit && !isOwner && !clinic?.isNew}
                />
              </Form.Item>
              <div className="form-item" hidden={showExtra}>
                <div className="form-item-label">Clinic's Photos</div>
                <div className="picture-list">
                  {fileList.map((pic, index) => (
                    <div key={index} className="picture-item">
                      <img src={pic.url} alt="" />
                      <div className="picture-item-modal">
                        <EyeOutlined onClick={() => setPreviewImage(pic)} />
                        <DeleteOutlined
                          onClick={(e) => {
                            if (!canEdit && !isOwner) {
                              return;
                            }
                            modal.confirm({
                              title: "Delete this picture?",
                              className: "fma-modal",
                              centered: true,
                              onOk: async () => {
                                setFileList(
                                  fileList.filter((_) => _.url !== pic.url)
                                );
                                await delClinicPic(pic.id! + "");
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  <UploadPic
                    allowTypes={["iamge/jpg", "image/jpeg", "image/png"]}
                    actionUrl={
                      canEdit
                        ? clinic?.value
                          ? `${API_HOST}/clinic/picture/${clinic?.value}`
                          : ""
                        : undefined
                    }
                    onAdded={(img) => setFileList([...fileList, img])}
                    allowSize={6}
                  />
                </div>
                <div className="upload-tip">
                  Limit JPG/PNG format, no bigger than 6MB.
                </div>
              </div>
              <Divider />
              <Checkbox
                checked={guaranteed}
                onChange={(e) => setGuaranteed(e.target.checked)}
              >
                I verify all information provided herein to be authentic,
                current, and accurate to the best of my knowledge
              </Checkbox>
            </Form>
          </div>
        </div>
        {showExtra && (
          <FTLProgress
            currentIndex={2}
            lastIndex={5}
            goBack={goBack}
            goNext={goNext}
            onCancel={goHome}
            onFinish={goHome}
          />
        )}
        {modalComp}
      </>
    );
  }
);
