import { Button } from "antd";
import IconNotFound from "@/assets/images/not-found.svg";
import IconNotFoundResponsive from "@/assets/images/not-found-responsive.svg";
import { useResponsive } from "ahooks";
import { CommonSearch } from "@/web/comps/common_search";
import { BasicLayout } from "@/web/comps/basic_layout";
import { useNavigate } from "react-router-dom";
import "./index.scss";

export const NotFound = () => {
  const navigate = useNavigate();
  const { isPC } = useResponsive();
  const goHome = () => navigate("/");
  const goContact = () => navigate("/");
  return (
    <BasicLayout>
      <div className="not-found-page">
        <div className="not-found-icon">
          <img
            src={isPC ? IconNotFound : IconNotFoundResponsive}
            alt="icon-not-found"
          />
        </div>
        <div className="sorry-message">
          {isPC ? (
            <>
              <div>Sorry. The content you’re looking for doesn’t exist. </div>
              <div>Either it was removed, or you mistyped the link.</div>
            </>
          ) : (
            <div>
              Sorry. The content you’re looking for doesn’t exist. Either it was
              removed, or you mistyped the link.
            </div>
          )}
        </div>
        <div className="handle-btns">
          <Button type="primary" onClick={goHome}>
            Go to homepage
          </Button>
          <Button type="default" onClick={goContact}>
            Contact
          </Button>
        </div>
        <div className="or">or</div>
        <div className="search-container">
          <CommonSearch />
        </div>
      </div>
    </BasicLayout>
  );
};
