import React from "react";
import { Footer } from "./footer";
import { Header } from "./header";
import "./basic_layout.scss";

interface Props {
  whiteBg?: boolean;
  showUser?: boolean;
  children: React.ReactNode;
}
export const BasicLayout = ({
  whiteBg = false,
  showUser = true,
  children,
}: Props) => {
  return (
    <div className="page-wrapper">
      <Header whiteBg={whiteBg} showUser={showUser} />
      <div className="page-content-wrapper">{children}</div>
      <Footer whiteBg={whiteBg} />
    </div>
  );
};
