import { getFtlacuExperiences, putFtlacuExperiences } from "@/web/api/ftl";
import {
  action_ftl_finish_step,
  action_ftl_put_step,
  model_ftl,
} from "@/web/models/model_ftl";
import {
  degree_list,
  experience_years,
  graduated_years,
} from "@/web/utils/consts";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useMount } from "ahooks";
import { Form, Input, Select, InputNumber } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import classNames from "classnames";
import { merge } from "lodash";
import { forwardRef, useImperativeHandle } from "react";
import { useNavigate } from "react-router-dom";
import { useModel } from "use-reaction";
import { FTLProgress } from "./ftl_progress";
import "./acu_experience.scss";

const defaultValues = {
  selfIntroduction: "",
  educations: [
    {
      school: "",
      degree: "",
      graduatedYear: "",
    },
  ],
  workExperiences: [
    {
      companyName: "",
      position: "",
      experience: "",
    },
  ],
  licenses: [
    {
      name: "",
      issueDate: "",
    },
  ],
  certificates: [
    {
      name: "",
      issueDate: "",
    },
  ],
  accomplishments: [
    {
      content: "",
    },
  ],
};

interface Props {
  showExtra?: boolean;
  className?: string;
  onSave?: (values?: any) => void;
}
export const AcuExperience = forwardRef(
  ({ showExtra = true, className, onSave }: Props, ref) => {
    const [form] = useForm();
    const navigate = useNavigate();
    useImperativeHandle(ref, () => ({
      save: form.submit,
    }));
    const {
      doAction,
      store: { step_previous },
    } = useModel(model_ftl);

    const goHome = () => navigate("/");

    const goNext = async () => {
      const values = await form.validateFields();
      if (values) {
        await onFinish(values, true);
      }
    };
    const goBack = () => {
      doAction(action_ftl_put_step, step_previous, "global");
    };

    const onFinish = async (data: typeof defaultValues, next?: boolean) => {
      // drop empty initial fields
      const values = {
        selfIntroduction: data.selfIntroduction,
        educations: data.educations?.filter((_) => !!_.school),
        workExperiences: data.workExperiences?.filter((_) => !!_.companyName),
        licenses: data.licenses?.filter((_) => !!_.name),
        certificates: data.certificates?.filter((_) => !!_.name),
        accomplishments: data.accomplishments?.filter((_) => !!_.content),
      };
      onSave
        ? onSave(values)
        : await doAction(
            action_ftl_finish_step,
            {
              values,
              api: putFtlacuExperiences,
              next,
            },
            "global"
          );
    };

    useMount(async () => {
      const data = await getFtlacuExperiences();

      if (
        data.selfIntroduction ||
        data.accomplishments?.length ||
        data.certificates?.length ||
        data.educations?.length ||
        data.licenses?.length ||
        data.workExperiences?.length
      ) {
        form.setFieldsValue(merge({}, defaultValues, data));
      }
    });
    return (
      <>
        <div className={classNames("fill-profile-page", className)}>
          <div className="acu-experience">
            {showExtra && (
              <>
                <h3 className="experience-title font-family-merriweather">
                  Now let’s enrich your profile!
                </h3>
                <p className="experience-desc">
                  Set up your profile so patients can search and book
                  appointments with you.
                </p>
              </>
            )}
            <Form
              form={form}
              name="user-experience-form"
              layout="vertical"
              onFinish={onFinish}
              initialValues={defaultValues}
            >
              {/*self-introduction*/}
              {showExtra && (
                <div className="experience-section self-introduction">
                  <p className="section-title">Tell us about you</p>
                  <Form.Item name="selfIntroduction" label="Self INtroduction">
                    <TextArea
                      maxLength={1000}
                      placeholder="e.g. As an acupuncturist, I view each individual as a dynamic, integrated whole and partner with my patients to create healing and well-being. Health goes beyond the absence of acute or chronic illness. Often, we do not acknowledge our health until it is compromised..."
                    />
                  </Form.Item>
                </div>
              )}

              {/*experience*/}
              <div className="experience-section">
                <p className="section-title">Education and Past Position(s)</p>
                {/*experience-school*/}
                <div className="school-list">
                  <Form.List name="educations">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restFields }) => (
                          <div key={key} className="form-row">
                            <div className="form-column">
                              <Form.Item
                                label="School"
                                className="first-form-item"
                                {...restFields}
                                name={[name, "school"]}
                                fieldKey={[name, "school"]}
                                rules={[
                                  {
                                    required: name > 0,
                                    message: "Missing school",
                                  },
                                ]}
                              >
                                <Input
                                  type="text"
                                  placeholder="e.g. Nanjing University Of Chinese Medicine"
                                />
                              </Form.Item>
                            </div>
                            <div className="form-column">
                              <Form.Item
                                label="Degree"
                                className="second-form-item"
                                {...restFields}
                                name={[name, "degree"]}
                                fieldKey={[name, "degree"]}
                                dependencies={["educations", name, "school"]}
                                rules={[
                                  {
                                    required: name > 0,
                                    message: "Missing degree",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator: (_, value) => {
                                      if (
                                        getFieldValue([
                                          "educations",
                                          name,
                                          "school",
                                        ]) &&
                                        !value
                                      ) {
                                        return Promise.reject("Missing degree");
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <Select>
                                  {degree_list.map((item, index) => (
                                    <Select.Option key={item} value={item}>
                                      {item}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="form-column">
                              <Form.Item
                                label="Graduated Year"
                                className="third-form-item"
                                {...restFields}
                                name={[name, "graduatedYear"]}
                                fieldKey={[name, "graduatedYear"]}
                                dependencies={["educations", name, "school"]}
                                rules={[
                                  {
                                    required: name > 0,
                                    message: "Missing graduated year",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator: (_, value) => {
                                      if (
                                        getFieldValue([
                                          "educations",
                                          name,
                                          "school",
                                        ]) &&
                                        !value
                                      ) {
                                        return Promise.reject(
                                          "Missing graduated year"
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <Select>
                                  {graduated_years.map((item, index) => (
                                    <Select.Option key={item} value={item}>
                                      {item}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <CloseCircleOutlined
                              style={{
                                fontSize: "20px",
                                color: "#BCC4CE",
                              }}
                              onClick={() => remove(name)}
                            />
                          </div>
                        ))}
                        <div className="add-btn-container">
                          <span className="add-btn" onClick={() => add()}>
                            + Add education
                          </span>
                        </div>
                      </>
                    )}
                  </Form.List>
                </div>
                {/*experience-company*/}
                <div className="companies-list">
                  <Form.List name="workExperiences">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restFields }) => (
                          <div key={key} className="form-row">
                            <div className="form-column">
                              <Form.Item
                                label="Company name"
                                className="first-form-item"
                                {...restFields}
                                name={[name, "companyName"]}
                                fieldKey={[name, "companyName"]}
                                rules={[
                                  {
                                    required: name > 0,
                                    message: "Missing company name",
                                  },
                                ]}
                              >
                                <Input
                                  type="text"
                                  placeholder="e.g. Nanjing University Of Chinese Medicine"
                                />
                              </Form.Item>
                            </div>
                            <div className="form-column">
                              {/* <div className="form-item-title"></div> */}
                              <Form.Item
                                label="Position"
                                className="second-form-item"
                                {...restFields}
                                name={[name, "position"]}
                                fieldKey={[name, "position"]}
                                dependencies={[
                                  "workExperiences",
                                  name,
                                  "companyName",
                                ]}
                                rules={[
                                  {
                                    required: name > 0,
                                    message: "Missing position",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator: (_, value) => {
                                      if (
                                        getFieldValue([
                                          "workExperiences",
                                          name,
                                          "companyName",
                                        ]) &&
                                        !value
                                      ) {
                                        return Promise.reject(
                                          "Missing position"
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <Input type="text" placeholder="e.g. CEO" />
                              </Form.Item>
                            </div>
                            <div className="form-column">
                              <Form.Item
                                label="Experiences"
                                className="third-form-item"
                                {...restFields}
                                name={[name, "experience"]}
                                fieldKey={[name, "experience"]}
                                dependencies={[
                                  "workExperiences",
                                  name,
                                  "companyName",
                                ]}
                                rules={[
                                  {
                                    required: name > 0,
                                    message: "Missing experiences",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator: (_, value) => {
                                      if (
                                        getFieldValue([
                                          "workExperiences",
                                          name,
                                          "companyName",
                                        ]) &&
                                        !value
                                      ) {
                                        return Promise.reject(
                                          "Missing experiences"
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <Select>
                                  {experience_years.map((item, index) => (
                                    <Select.Option key={index} value={item}>
                                      {item} year{+item > 1 ? "s" : ""}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <CloseCircleOutlined
                              style={{
                                fontSize: "20px",
                                color: "#BCC4CE",
                              }}
                              onClick={() => remove(name)}
                            />
                          </div>
                        ))}
                        <div className="add-btn-container">
                          <span className="add-btn" onClick={() => add()}>
                            + Add past position
                          </span>
                        </div>
                      </>
                    )}
                  </Form.List>
                </div>
              </div>

              {/*certificates*/}
              <div className="experience-section certificates">
                <p className="section-title">License(s) and Certificate(s)</p>
                {/*certificates-license*/}
                <div className="licenses-list">
                  <Form.List name="licenses">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restFields }) => (
                          <div
                            key={key}
                            className="form-row form-row-certificates"
                          >
                            <div className="form-column">
                              <Form.Item
                                label="License"
                                className="first-form-item"
                                {...restFields}
                                name={[name, "name"]}
                                fieldKey={[name, "name"]}
                                rules={[
                                  {
                                    required: name > 0,
                                    message: "Missing license",
                                  },
                                ]}
                              >
                                <Input
                                  type="text"
                                  placeholder="e.g. Nanjing University Of Chinese Medicine"
                                />
                              </Form.Item>
                            </div>
                            <div className="form-column date">
                              <Form.Item
                                label="Since"
                                className="second-form-item"
                                {...restFields}
                                name={[name, "issueDate"]}
                                fieldKey={[name, "issueDate"]}
                                dependencies={["licenses", name, "name"]}
                                rules={[
                                  {
                                    required: name > 0,
                                    message: "Missing date of issue",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator: (_, value) => {
                                      if (
                                        getFieldValue([
                                          "licenses",
                                          name,
                                          "name",
                                        ]) &&
                                        !value
                                      ) {
                                        return Promise.reject(
                                          "Missing date of issue"
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <InputNumber
                                  placeholder="Year"
                                  min={1900}
                                  max={new Date().getFullYear()}
                                />
                              </Form.Item>
                            </div>
                            <CloseCircleOutlined
                              style={{
                                fontSize: "20px",
                                color: "#BCC4CE",
                              }}
                              onClick={() => remove(name)}
                            />
                          </div>
                        ))}
                        <div className="add-btn-container">
                          <span className="add-btn" onClick={() => add()}>
                            + Add license
                          </span>
                        </div>
                      </>
                    )}
                  </Form.List>
                </div>

                {/*certificates-certificates*/}
                <div className="certificates-list">
                  <Form.List name="certificates">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restFields }) => (
                          <div
                            key={key}
                            className="form-row form-row-certificates"
                          >
                            <div className="form-column">
                              <Form.Item
                                label="Certificate"
                                className="first-form-item certificates-name"
                                {...restFields}
                                name={[name, "name"]}
                                fieldKey={[name, "name"]}
                                rules={[
                                  {
                                    required: name > 0,
                                    message: "Missing certificate",
                                  },
                                ]}
                              >
                                <Input
                                  type="text"
                                  name="certificate"
                                  placeholder="e.g. Nanjing University Of Chinese Medicine"
                                />
                              </Form.Item>
                            </div>
                            <div className="form-column date">
                              <Form.Item
                                label="Since"
                                className="second-form-item"
                                {...restFields}
                                name={[name, "issueDate"]}
                                fieldKey={[name, "issueDate"]}
                                dependencies={["certificates", name, "name"]}
                                rules={[
                                  {
                                    required: name > 0,
                                    message: "Missing date of issue",
                                  },
                                  ({ getFieldValue }) => ({
                                    validator: (_, value) => {
                                      if (
                                        getFieldValue([
                                          "certificates",
                                          name,
                                          "name",
                                        ]) &&
                                        !value
                                      ) {
                                        return Promise.reject(
                                          "Missing date of issue"
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <InputNumber
                                  placeholder="Year"
                                  min={1900}
                                  max={new Date().getFullYear()}
                                />
                              </Form.Item>
                              <div className="date-input-list"></div>
                            </div>
                            <CloseCircleOutlined
                              style={{
                                fontSize: "20px",
                                color: "#BCC4CE",
                              }}
                              onClick={() => remove(name)}
                            />
                          </div>
                        ))}
                        <div className="add-btn-container">
                          <span className="add-btn" onClick={() => add()}>
                            + Add certificate
                          </span>
                        </div>
                      </>
                    )}
                  </Form.List>
                </div>
              </div>

              {/*activities*/}
              <div className="experience-section activities">
                <p className="section-title">
                  Professional membership and academia activities (including
                  publications)
                </p>
                <Form.List name="accomplishments">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div key={key} className="activities-item">
                          <Form.Item
                            {...restField}
                            key={key}
                            name={[name, "content"]}
                            fieldKey={[name, "content"]}
                            rules={[
                              {
                                required: name > 0,
                                message:
                                  "Missing professional membership and academia activities!",
                              },
                            ]}
                          >
                            <TextArea
                              maxLength={1000}
                              rows={4}
                              placeholder="Ma, Y., Zhou, K., Fan, J., & Sun, S. (2016). Traditional Chinese medicine: potential approaches from modern dynamical complexity theories. Front Med, 10(1), 28-32. doi:10.1007/s11684-016-0434-2"
                            />
                          </Form.Item>
                          <CloseCircleOutlined
                            style={{
                              fontSize: "20px",
                              color: "#BCC4CE",
                            }}
                            onClick={() => remove(name)}
                          />
                        </div>
                      ))}
                      <div className="add-btn-container">
                        <span className="add-btn" onClick={() => add()}>
                          + Add activity
                        </span>
                      </div>
                    </>
                  )}
                </Form.List>
              </div>
            </Form>
          </div>
        </div>
        {showExtra && (
          <FTLProgress
            currentIndex={4}
            lastIndex={5}
            goBack={goBack}
            goNext={goNext}
            onCancel={goHome}
            onFinish={goHome}
          />
        )}
      </>
    );
  }
);
