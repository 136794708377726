import { CameraOutlined } from "@ant-design/icons";
import { message, Spin, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { RcFile } from "rc-upload/lib/interface";
import { useState } from "react";
import { getToken } from "../utils/ajax";
import { ImgData } from "../utils/types";
import "./upload_pic.scss";
const allow_types = ["iamge/jpg", "image/jpeg", "image/png", "image/svg"];
interface Props {
  allowTypes?: string[];
  /**size in MB */
  allowSize?: number;
  actionUrl?: string;
  onAdded: (img: ImgData) => void;
}
export const UploadPic = ({
  allowTypes = allow_types,
  allowSize = 2,
  actionUrl,
  onAdded,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const beforeUpdate = (file: RcFile) => {
    if (!allowTypes.includes(file.type)) {
      message.warning(
        `You can only upload ${allowTypes
          .map((_) => _.split(/[.\/]/).pop())
          .join("/")} file!`
      );
      return false;
    }
    if (file.size / 1024 / 1024 > allowSize) {
      message.warning(`Image must smaller than ${allowSize}MB!`);
      return false;
    }
    return true;
  };

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      // Get this url from response in real world.
      let data = info.file.response.data;
      onAdded(data);
    } else {
      setLoading(false);
    }
  };

  return (
    <Upload.Dragger
      accept="image/*"
      headers={{
        authorization: `Bearer ${getToken()}`,
      }}
      action={actionUrl}
      showUploadList={false}
      beforeUpload={beforeUpdate}
      className="picture-drop"
      disabled={!actionUrl}
      onChange={handleChange}
    >
      <CameraOutlined />
      <Spin spinning={loading}>
        <div className="upload-drop">Select or Drop your photo here</div>
      </Spin>
    </Upload.Dragger>
  );
};
