import { putAcuServices } from "@/web/api/profile";
import { model_user, action_user_get_info } from "@/web/models/model_user";
import { useRequest } from "ahooks";
import { Button, message } from "antd";
import { useRef } from "react";
import { useModel } from "use-reaction";
import { AcuService } from "../ftl/acu_service";
import "./account_acu_profile_service.scss";

export const AccountAcuProfileService = () => {
  const ref = useRef<any>();
  const { doAction } = useModel(model_user);
  const { run: onSave, loading } = useRequest(
    async (values) => {
      await putAcuServices(values);
      message.success("save success!");
      doAction(action_user_get_info);
    },
    { manual: true }
  );
  // not get services here, will get in <AcuService /> element
  return (
    <>
      <AcuService
        ref={ref}
        className="account-profile"
        showExtra={false}
        onSave={onSave}
      />
      <div className="submit-btn">
        <Button
          type="primary"
          loading={loading}
          onClick={() => ref.current?.save()}
        >
          Save changes
        </Button>
      </div>
    </>
  );
};
