import moment from "moment";
import { Action } from "use-reaction";
import {
  acuCancelBooked,
  acuClaimBooked,
  getBookedById,
  getServiceById,
  payBooking,
  postBooking,
  userCancelBooked,
} from "../api/booking";
import { getClinicById } from "../api/clinic";
import { getDoctorById } from "../api/profile";
import { RoleType } from "../utils/types";
import { dataItem2Opt } from "../utils/util";

export const model_booking = {
  NAME: "booking",
  id: 0,
  target: {
    clinicId: 0,
    doctorId: 0,
    clinic: {} as any,
    doctor: {} as any,
  },

  basic: {
    dateTime: undefined,
    timezone: "",
    bookFor: "myself",
    selectedTreatmentService: {
      price: 0,
      duration: 0,
      value: 0,
      label: "",
    },
    healthProblem: "",
    comments: "",
    patientName: null,
    phoneNumber: null,
    age: null,
    gender: null,
    existFetched: false,
  },
  cardData: {
    brand: "VISA",
    expMonth: 0,
    expYear: 0,
    last4: "",
    paymentToken: "",
    cardholderName: "",
    billingAddress: {
      addressLine1: "",
      addressLine2: "",
      locality: "",
      country: "",
      postalCode: "",
    },
    save: false,
  },
  bookDeposite: 0, // caculate by server
  returnAmount: 0, // caculate by server
  status: "pending",
  failReason: "", // error reason when deposit fail
};

type T = typeof model_booking;

export const action_booking_save_basic: Action<T> = ({
  payload,
  store: { basic },
}) => {
  return {
    basic: { ...basic, ...payload },
  };
};

export const action_booking_post: Action<T> = async ({
  payload: cardData,
  store: { id, target, basic },
}) => {
  const dateTime = basic.dateTime;
  const data = {
    id,
    clinicId: target.clinicId,
    doctorId: target.doctorId,
    serviceId: basic.selectedTreatmentService.value,
    dateTime,
    timezone: basic.timezone,
    date: [
      moment(dateTime).startOf("date").toDate(),
      moment(dateTime).endOf("date").toDate(),
    ],
    bookFor: basic.bookFor,
    comments: basic.comments,
    ...(basic.bookFor === "another"
      ? {
          patientName: basic.patientName,
          phoneNumber: basic.phoneNumber,
          age: basic.age,
          gender: basic.gender,
        }
      : {}),
    cardData,
  };
  const res = await postBooking(data);
  return {
    ...res, // id and deposit
    cardData,
  };
};

export const action_booking_pay: Action<T> = async ({ store: { id } }) => {
  if (id) {
    const res = await payBooking(id);
    return res;
  }
};
/**
 * get target clinic, doctor info
 */
export const action_booking_get_target_info: Action<T> = async ({
  payload = {},
  store: { target },
}) => {
  const { clinicId = 0, doctorId = 0 } = { ...target, ...payload };
  if (clinicId && doctorId) {
    const clinic = await getClinicById(clinicId);
    const doctor = await getDoctorById(doctorId);
    return {
      target: {
        clinicId,
        doctorId,
        clinic,
        doctor,
      },
    };
  }
  return { target };
};

export const action_booking_edit: Action<T> = async ({
  payload: id,
  store,
}) => {
  const exist = await getBookedById(id);
  return await buildModelByExist(exist, store);
};

export const action_return_deposit: Action<T> = async ({
  payload: role,
  store: { id },
}) => {
  let res;
  if (role === RoleType.USER) {
    res = await userCancelBooked(id);
  } else {
    res = await acuCancelBooked(id);
  }
  return {
    status: res.status,
    bookDeposite: res.bookDeposite,
    returnAmount: res.returnAmount,
  };
};

export const action_acu_claim_booked: Action<T> = async ({ store: { id } }) => {
  const res = await acuClaimBooked(id);
  return {
    status: res.status,
    bookDeposite: res.bookDeposite,
    returnAmount: res.returnAmount,
  };
};

async function buildModelByExist(exist: any, store: T) {
  const service = await getServiceById(exist.serviceId);
  const { target } = (await action_booking_get_target_info({
    payload: { clinicId: exist.clinicId, doctorId: exist.doctorId },
    store,
  })) as T;
  return {
    id: exist.id,
    target,
    basic: {
      dateTime: exist.dateTime,
      timezone : exist.timezone,
      bookFor: exist.bookFor,
      selectedTreatmentService:
        dataItem2Opt<typeof store.basic.selectedTreatmentService>(service),
      healthProblem: exist.healthProblem,
      comments: exist.comments,
      patientName: exist.patientName,
      age: exist.age,
      gender: exist.gender,
      phoneNumber: exist.phoneNumber,
      existFetched: true,
    },
    cardData: exist.cardData,
    status: exist.status,
    bookDeposite: exist.bookDeposite,
    returnAmount: exist.returnAmount,
    failReason: exist.failReason,
  };
}
