import { GOOGLE_MAP_KEY, SQUARE_APP_ID, SQUARE_LOCATION_ID, SQUARE_SDK } from "./consts";

let gmapLoad: Promise<boolean>;
let squareLoad: Promise<boolean>;
export const loadGoogleMap = () => {
  gmapLoad =
    gmapLoad ||
    new Promise<boolean>((_) => {
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.className = "google-map-entrance";
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_KEY}&libraries=places`;
      document.body.insertAdjacentElement("beforeend", gmapScriptEl);
      gmapScriptEl.onload = () => {
        _(true);
      };
    });
  return gmapLoad;
};
export const loadSquare = () =>{
  squareLoad = squareLoad || 
  new Promise<boolean>(_ =>{
    const sqrEl = document.createElement("script");
    sqrEl.className = "square-ele";
    sqrEl.src = SQUARE_SDK;
    document.body.insertAdjacentElement("beforeend", sqrEl);
    sqrEl.onload = () => {
      const payments = (window as any).Square.payments(SQUARE_APP_ID, SQUARE_LOCATION_ID);
      _(payments);
    };
  })
  return squareLoad
}