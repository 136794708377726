import { Modal, Form, Input, Radio, Rate, Slider, Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { reviewDoctor } from "../api/review";
import { curedEnum } from "../utils/types";
import "./review_doctor_modal.scss";

let initValues = {
  treatedFor: "",
  cured: 0,
  effectivenessRating: 0,
  pleasantryRating: 0,
  officeVisitTimes: 1,
  timeToRecovery: 1,
  sideEffects: "",
  information: "",
};
let marks = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: {
    label: (
      <span>
        10<sup>+</sup>
      </span>
    ),
  },
};
interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onReviewClinic: () => void;
  doctorId: string;
  doctorName: string;
  medicalTitle: string;
}
export const ReviewDoctorModal = ({
  visible,
  setVisible,
  onReviewClinic,
  doctorId,
  doctorName,
  medicalTitle,
}: Props) => {
  const navigate = useNavigate();
  const [form] = useForm();
  const [sent, setSent] = useState(false);

  const onFinish = async (values: any) => {
    await reviewDoctor({ ...values, doctorId });
    setSent(true);
  };
  const goHome = () => navigate("/");
  const reviewClinic = () => {
    setVisible(false);
    onReviewClinic && onReviewClinic();
  };
  return (
    <Modal
      visible={visible}
      width={820}
      closable={true}
      keyboard={false}
      maskClosable={false}
      destroyOnClose={true}
      wrapClassName="review-doctor-modal"
      forceRender={true}
      okText="done"
      onCancel={(e) => setVisible(false)}
      onOk={form.submit}
      footer={sent ? null : undefined}
    >
      {!sent && (
        <div className="modal-header modal-header__doctor">
          <div className="title-1">Review for</div>
          <div className="title">{doctorName}</div>
          <div className="desc">{medicalTitle || "Acupuncturist"}</div>
        </div>
      )}

      {!sent ? (
        <Form
          name="reviewDoctors"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={initValues}
          className="review-doctor-form"
        >
          <div className="review-form-item">
            <div className="label">
              Treated problem (Primary treatment if multiple apply)
            </div>
            <Form.Item
              name="treatedFor"
              rules={[
                { required: true, message: "Please select a treated problem" },
              ]}
            >
              <Input placeholder="Please select a treated problem" />
            </Form.Item>
          </div>
          <div className="review-form-item">
            <div className="label">symptoms resolved</div>
            <Form.Item name="cured">
              <Radio.Group>
                <Radio value={curedEnum.CURED}>Fully</Radio>
                <Radio value={curedEnum.SOMEWHAT}>Partially</Radio>
                <Radio value={curedEnum.NOT_AT_ALL}>Not at all</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="review-form-item">
            <div className="label">overall</div>
            <Form.Item name="overallRating" className="rate">
              <Rate
                allowClear={true}
                allowHalf={false}
                style={{ fontSize: "34px" }}
              />
            </Form.Item>
          </div>
          <div className="review-form-item">
            <div className="label">effectiveness</div>
            <Form.Item name="effectivenessRating" className="rate">
              <Rate
                allowClear={true}
                allowHalf={false}
                style={{ fontSize: "34px" }}
              />
            </Form.Item>
          </div>
          <div className="review-form-item">
            <div className="label">pleasantry</div>
            <Form.Item name="pleasantryRating" className="rate">
              <Rate style={{ fontSize: "34px" }} />
            </Form.Item>
          </div>
          <div className="review-form-item">
            <div className="title">
              <div className="label">office visit times</div>
              <div className="tip">How many times have you been here?</div>
            </div>
            <Form.Item
              name="officeVisitTimes"
              className="rate time-to-recovery"
            >
              <Slider
                min={1}
                max={10}
                dots={true}
                tooltipVisible={false}
                marks={marks}
              />
            </Form.Item>
          </div>
          <div className="review-form-item">
            <div className="label">total time to recovery</div>
            <Form.Item name="timeToRecovery">
              <Slider
                min={1}
                max={10}
                dots={true}
                tooltipVisible={false}
                marks={marks}
              />
            </Form.Item>
            <span className="unit-of-time">(Week)</span>
          </div>
          <div className="review-form-item">
            <div className="label">potential side effects</div>
            <Form.Item name="sideEffects">
              <Input placeholder="Potential side effects" />
            </Form.Item>
          </div>
          <div className="review-form-item">
            <div className="label">anything specific to say</div>
            <Form.Item name="information">
              <Input placeholder="Anything specific to say" />
            </Form.Item>
          </div>
        </Form>
      ) : (
        <div className="review-doctor-added">
          <div className="block"></div>
          <div className="message-1 font-family-merriweather">
            Review successfully saved
          </div>
          <div className="message-2">
            Help us rate this clinic’s service within 1 minute?{" "}
          </div>
          <div className="buttons">
            <Button type="default" onClick={goHome}>
              back to homepage
            </Button>
            <Button type="primary" onClick={reviewClinic}>
              Let rate the clinic
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
