import { getDoctorById } from "@/web/api/profile";
import { ProfileBookService } from "@/web/comps/profile_book_service";
import { ProfleDoctorCard } from "@/web/comps/profile_doctor_card";
import { ProfileDoctorInfo } from "@/web/comps/profile_doctor_info";
import { ProfileDoctorReviews } from "@/web/comps/profile_doctor_reviews";
import { model_user } from "@/web/models/model_user";
import { router_booking_appointment } from "@/web/utils/consts";
import { RoleType } from "@/web/utils/types";
import { dataItem2Opt, getRoleName } from "@/web/utils/util";
import { useRequest, useResponsive } from "ahooks";
import { message, Skeleton, Tabs } from "antd";
import qs from "qs";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModel } from "use-reaction";

const { TabPane } = Tabs;
interface Props {
  id: string;
}
export const DoctorProfile = ({ id }: Props) => {
  const { isPC } = useResponsive();
  const navigate = useNavigate();
  const {
    store: {role, profile },
  } = useModel(model_user);
  const [chosenService, setChosenService] = useState<number>();
  const { data, loading } = useRequest(getDoctorById, { defaultParams: [id] });
  const isSelf = useMemo(() => profile?.id === +id, [profile?.id]);
  const serviceOpts = useMemo(
    () => data?.profile?.treatmentServices?.map(dataItem2Opt),
    [data?.profile?.treatmentServices]
  );

  const goBooking = (serviceId?: number) => {
    if(role !== RoleType.USER) {
      message.error('your account not allowed to book appointment.')
      return;
    }
    serviceId = serviceId || chosenService;
    navigate(
      router_booking_appointment +
        "?" +
        qs.stringify({
          serviceId,
          doctorId: id,
          clinicId: data?.profile?.clinicId,
        })
    );
  };
  const tipPickService = () => {
    if(role !== RoleType.USER) {
      message.error('your account not allowed to book appointment.')
      return;
    }
    goBooking();
  };

  return (
    <div className="profile-page doctor-profile">
      <div className="profile-banner"></div>
      {loading ? (
        <Skeleton
          avatar={{ size: 174, shape: "square" }}
          paragraph={{ rows: 4 }}
        />
      ) : (
        <ProfleDoctorCard
          id={id}
          name={data?.profile?.name}
          avatar={data?.profile?.avatar?.url}
          role={getRoleName(data?.profile?.role)}
          isSelf={isSelf}
          medicalTitle={data?.profile?.medicalTitle}
          clinicActive={
            data?.profile?.clinic?.active &&
            data?.profile.treatmentServices?.length
          }
          clinicAddress={data?.profile?.clinic?.address}
          clinicId={data?.profile?.clinic?.id}
          clinicName={data?.profile?.clinic?.name}
          clinicPhone={data?.profile?.clinic?.phone}
          rating={data?.rating}
          goBooking={tipPickService}
        />
      )}
      <div className="profile-main">
        <div className="profile-main-center">
          <div className="profile-tabs">
            <Tabs defaultActiveKey="information">
              <TabPane tab="Information" key="information">
                <ProfileDoctorInfo
                  isSelf={isSelf}
                  introduction={data?.profile?.selfIntroduction}
                  expertiseAreas={data?.profile?.expertiseAreas}
                  treatmentTechniques={data?.profile?.treatmentTechniques}
                  accomplishments={data?.profile?.accomplishments}
                  treatmentServices={data?.profile?.treatmentServices}
                  educations={data?.profile?.educations}
                  workExperiences={data?.profile?.workExperiences}
                  licenses={data?.profile?.licenses}
                  certificates={data?.profile?.certificates}
                />
              </TabPane>
              <TabPane tab="Patients' reviews" key="patientsReviews">
                <ProfileDoctorReviews id={id} />
              </TabPane>
            </Tabs>
          </div>
          <div className="profile-main-right">
            <ProfileBookService
              title={`Services of ${data?.profile?.name}`}
              active={!isSelf && data?.profile?.active}
              options={serviceOpts}
              onChosen={setChosenService}
              onOk={goBooking}
            />
          </div>
          {!isPC && (
            <div className="book-tip">I'd like to book appointment with</div>
          )}
        </div>
      </div>
    </div>
  );
};
