import { Avatar, Progress, Rate, Select } from "antd";
import { formatNumber } from "../utils/util";
import IconStarActive from "@/assets/images/icon-star-active.svg";
import { UserOutlined } from "@ant-design/icons";

import "./profile_clinic_reviews.scss";
// test data
const SCORE_CARD_LIST = [
  {
    title: "environment",
    score: 4.5,
  },
  {
    title: "Timeliness",
    score: 4.5,
  },
  {
    title: "Staff",
    score: 4.0,
  },
];

interface Props {}
export const ProfileClinicReviews = ({}: Props) => {
  return (
    <div className="patient-reviews clinic-patient-reviews">
      <div className="score-filter">
        <Select>
          <Select.Option value="All health problems">
            All health problems
          </Select.Option>
        </Select>
      </div>

      <div className="score-container">
        <div className="score-container-left">
          <div className="title">Overall</div>
          <div className="score">4.35</div>
          <div className="account">{formatNumber(8882)} reviews</div>
        </div>
        <div className="score-container-right">
          {[69, 10, 7, 8, 6].map((item, index) => (
            <div key={index} className="star-progress">
              <div className="score">{5 - index}</div>
              <div className="star">
                <img src={IconStarActive} alt="icon-star-active" />
              </div>
              <Progress percent={item} showInfo={false} strokeColor="#449086" />
            </div>
          ))}
        </div>
        <div className="score-card-list">
          {SCORE_CARD_LIST.map((card, index) => (
            <ScoreCard key={index} title={card.title} score={card.score} />
          ))}
        </div>
      </div>

      <div className="reviews-filter">
        <div className="account">
          <span>{formatNumber(91238)}</span>
          patients reviews
        </div>
        <div className="filter">
          <Select className="filter-sortBy">
            <Select.Option value="Latest first">Latest first</Select.Option>
          </Select>
        </div>
      </div>

      <div className="reviews-container">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((card, index) => (
          <ReviewCard key={index} />
        ))}
      </div>
    </div>
  );
};

const ScoreCard = ({
  title = "",
  score = 0,
}: {
  title: string;
  score: number;
}) => {
  return (
    <div className="score-card">
      <div className="title">{title}</div>
      <div className="score">{score}</div>
    </div>
  );
};

const ReviewCard = () => {
  return (
    <div className="review-card">
      <div className="review-info">
        <div className="avatar">
          <Avatar
            size={Math.min(((document.body.clientWidth / 1440) * 40) >> 0, 40)}
            icon={<UserOutlined />}
          />
        </div>
        <div className="name-date">
          <div className="name">Jenny Wilson</div>
          <div className="date">Jun 17, 2020</div>
        </div>
        <Rate
          className="ant-rate-in-card review-stars"
          disabled
          allowHalf
          value={4.3}
        />
      </div>
      <div className="review-desc">
        Dr Jing is one of the best doctors I've ever had. She is our family
        doctor and is so incredibly warm and personate. Immediately when she
        enters the room, she takes time to get to know you and is so patient and
        such a good listener. She provides excellent, knowledgeable care a ...
        <a href="/">Read More</a>
      </div>
      <div className="review-score">
        <ScoreCard title="environment" score={2.3} />
        <ScoreCard title="TIMELINESS" score={4.3} />
        <ScoreCard title="STAFF" score={5} />
      </div>
    </div>
  );
};
