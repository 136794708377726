import { ExclamationCircleOutlined, UserOutlined } from "@ant-design/icons";
import { useMount, useRequest } from "ahooks";
import {
  Avatar,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Rate,
  Select,
  Spin,
} from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import classNames from "classnames";
import moment from "moment-timezone";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useModel } from "use-reaction";
import { getAcuMatchTimes } from "../api/profile";
import {
  action_booking_save_basic,
  model_booking,
} from "../models/model_booking";
import { Opt } from "../utils/types";
import { dataItem2Opt, formatNumber } from "../utils/util";
import "./booking_base_form.scss";
import { BookingCalendar, TimeOpt } from "./booking_calendar";
const { Option } = Select;
interface Props {
  clinicId: number;
  serviceId?: number;
}
export const BookingBaseForm = forwardRef(
  ({ clinicId, serviceId }: Props, ref) => {
    const {
      doAction,
      store: {
        target: { doctor },
        basic,
      },
    } = useModel(model_booking);

    const [bookDate, setBookDate] = useState<Date | undefined>(basic.dateTime);
    const [bookTime, setBookTime] = useState<TimeOpt | undefined>(
      basic.dateTime
        ? {
            timeStart: moment(basic.dateTime).format("HH:mm"),
            timeEnd: moment(basic.dateTime)
              .add(basic.selectedTreatmentService.duration, "minute")
              .format("HH:mm"),
            ap: moment(basic.dateTime).format("hh:mm A").includes("AM")
              ? "AM"
              : "PM",
            active: true,
          }
        : (undefined as any)
    );
    const [form] = useForm();

    const dateTime = useMemo(() => {
      if (!bookTime) {
        return undefined;
      }
      const dtm = moment(bookDate);
      const hm = bookTime.timeStart || "0:0";
      const [h, m] = hm.split(":");
      dtm.hour(+h).minute(+m);
      return dtm.toDate();
    }, [bookDate, bookTime]);

    useImperativeHandle(ref, () => ({
      next: async () => {
        if (!chosenServiceId) {
          const err = "Please select type of service";
          message.error(err);
          throw new Error(err);
        }
        if (!bookTime) {
          const err = "Please pick up a time";
          message.error(err);
          throw new Error(err);
        }
        const values = await form.validateFields();

        const { serviceId, ...others } = values;
        const selectedTreatmentService = treatmentServices?.find(
          (_) => _.value === serviceId
        );
        await doAction(action_booking_save_basic, {
          ...others,
          dateTime,
          timezone,
          selectedTreatmentService,
        });
      },
    }));
    const bookFor = useWatch("bookFor", form);
    const chosenServiceId = useWatch("serviceId", form);

    const treatmentServices = useMemo<Opt[]>(
      () => doctor?.profile?.treatmentServices?.map(dataItem2Opt),
      [doctor?.profile?.treatmentServices]
    );

    const { data: timeOpts, loading } = useRequest<TimeOpt[], any>(
      async () => {
        const times = await getAcuMatchTimes({
          clinic: clinicId,
          doctor: doctor.profile.id,
          date: [moment(bookDate).startOf('date').toDate(), moment(bookDate).endOf('date').toDate()],
          service: chosenServiceId,
        });
        return times.map((t: any) => ({
          timeStart: moment(`${moment(t.updateDate).format('YYYY-MM-DD')}T${t.timeStart}:00.000Z`).format('HH:mm'),
          timeEnd: moment(`${moment(t.updateDate).format('YYYY-MM-DD')}T${t.timeEnd}:00.000Z`).format('HH:mm'),
          active: t.active,
          ap: moment(`${moment(t.updateDate).format('YYYY-MM-DD')}T${t.timeStart}:00.000Z`).format("A"),
        }));
      },
      {
        refreshDeps: [chosenServiceId, bookDate],
        ready:
          !!doctor?.profile?.treatmentServices?.length &&
          !!bookDate &&
          !!chosenServiceId,
      }
    );
    const timezone = moment.tz.guess();
    const timeDisableTip = useMemo(()=>{
      if (loading) {
        return 'checking...'
      }
      if (!chosenServiceId) {
        return 'Please select a service first!'
      }
      if (!timeOpts?.length){
        return 'This clinic does not open on this day!'
      }
      if(!timeOpts.some(_ => _.active)) {
        return 'This clinic is already fully booked today!'
      }
      return undefined
    }, [timeOpts, chosenServiceId, loading])

    useMount(() => {
      if (serviceId) {
        form.setFieldsValue({ ...basic, serviceId: serviceId });
      }
    });
    useEffect(() => {
      if (basic.existFetched) {
        form.setFieldsValue({ ...basic });
      }
    }, [basic.existFetched]);
    useEffect(() => {
      setBookTime(undefined);
    }, [chosenServiceId]);

    return (
      <div className="booking-section booking-base-form">
        <div className="section-title">Booking Appointment</div>
        <div className="booking-title">booking with:</div>
        <div className="doctor-info">
          <div className="doctor-avatar">
            <Avatar
              icon={<UserOutlined />}
              src={doctor?.profile?.avatar?.url}
              size={Math.min(
                ((document.body.clientWidth / 1440) * 48) >> 0,
                48
              )}
            />
          </div>
          <div className="doctor-name">
            <div className="name">{doctor?.profile?.name}</div>
            <div className="title">{doctor?.profile?.medicalTitle}</div>
          </div>
          <div className="doctor-reviews">
            <Rate
              // className="ant-rate-in-card"
              allowHalf
              disabled
              style={{ fontSize: 14 }}
              value={doctor?.profile?.overallRating}
            />
            <div className="amount">
              {formatNumber(doctor?.profile?.reviewCount)} reviews
            </div>
          </div>
        </div>
        <Form form={form} layout="vertical" initialValues={basic}>
          <Form.Item
            label="service"
            name="serviceId"
            rules={[{ required: true, message: "Missing service" }]}
          >
            <Select placeholder="Select services" options={treatmentServices} />
          </Form.Item>
          <Divider type="horizontal" />

          <Spin spinning={loading}>
            <div
              className={classNames("book-time-section", {
                disabled: !chosenServiceId,
              })}
            >
              <BookingCalendar
                disabledTip={timeDisableTip}
                times={timeOpts}
                value={dateTime}
                onChangeDate={setBookDate}
                onChangeTime={setBookTime}
              />
              <div className="timezone"><ExclamationCircleOutlined /> All times are in {timezone} Time</div>
            </div>
          </Spin>
          <Divider />
          <div
            className={classNames("book-for-section", {
              disabled: !dateTime,
            })}
          >
            <div className="form-column booking-for">
              <Form.Item
                name="bookFor"
                className="booking-for"
                label="booking for:"
                labelAlign="left"
              >
                <Radio.Group>
                  <Radio value="myself">Myself</Radio>
                  <Radio value="another">Another person</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            {bookFor === "another" && (
              <>
                <div className="form-column patient-name-item">
                  <Form.Item
                    label="Patient name"
                    name="patientName"
                    rules={[{ required: true, message: "Missing name" }]}
                  >
                    <Input placeholder="Please input patient's name" />
                  </Form.Item>
                  <Form.Item label="Phone number" name="phoneNumber">
                    <Input placeholder="Please input patient's phone number" />
                  </Form.Item>
                </div>
                <div className="form-column age-item">
                  <Form.Item label="Age" name="age">
                    <Input
                      type="number"
                      max={150}
                      min={0}
                      step={1}
                      placeholder="Please input patient's age"
                    />
                  </Form.Item>
                  <Form.Item label="Gender" name="gender">
                    <Select placeholder="Please select patient's gender">
                      <Option value="female">Female</Option>
                      <Option value="male">Male</Option>
                    </Select>
                  </Form.Item>
                </div>
              </>
            )}
            <Form.Item
              label="Reason for appointment"
              name="healthProblem"
            >
              <Input placeholder="describe health problem" />
            </Form.Item>

            <Form.Item label="Comments / special request" name="comments">
              <Input.TextArea
                placeholder="Is there anything else you want to share with us?"
                rows={4}
              />
            </Form.Item>
          </div>
        </Form>
        <Divider type="horizontal" />
      </div>
    );
  }
);
