import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import Logo2 from "../../assets/images/logo2.svg";
import intl from "react-intl-universal";
import LogoResponsive from "../../assets/images/logo-responsive.svg";
import LogoResponsive2 from "../../assets/images/logo-responsive2.svg";
import HeaderMenusDark from "../../assets/images/header-menus-dark.svg";
import HeaderMenusWhite from "../../assets/images/header-menus-white.svg";

import { Avatar, Button, Dropdown, Menu } from "antd";
import {
  router_account_profile,
  router_account_user_profile,
  router_login,
  router_reset_password,
  router_search_result,
} from "../utils/consts";
import { useResponsive } from "ahooks";
import { useModel } from "use-reaction";
import { model_user } from "../models/model_user";
import { RoleType } from "../utils/types";
import { getToken, logout } from "../utils/ajax";
import classNames from "classnames";
import React from "react";
import "./header.scss";

interface Props {
  whiteBg?: boolean;
  showUser?: boolean;
  mobileHeaderMenu?: React.ReactElement;
}
export const Header = ({
  whiteBg = false,
  showUser = true,
  mobileHeaderMenu,
}: Props) => {
  const navigate = useNavigate();
  const { isPC } = useResponsive();

  const {
    store: { id, avatar, role, profile },
  } = useModel(model_user);

  const goHome = () => navigate("/");
  const goToLogin = () => navigate(router_login);
  const goToAccount = () => navigate(router_account_user_profile);
  const goToProfile = () => navigate(router_account_profile);
  const goToSearchResultPage = () => {
    // todo
    navigate(router_search_result);
  };
  const onMenuClick = ({ key }: { key: string }) => {
    if (key === "account") {
      navigate(router_account_user_profile);
    } else if (key === "profile") {
      navigate(`/profile/doctor/${profile?.id}/${(profile?.name || '').replace(/\s+/g, '-')}`);
    } else {
      logout();
    }
  };
  const goToBlogPage = () => {
    window.open("https://findmyacupuncturist.medium.com");
  };

  const getHeaderMenu = () => {
    const isResetPwd = window.location.pathname === router_reset_password;
    const logined = !!getToken();
    return (
      mobileHeaderMenu || (
        <Menu className="mbl-head-menu">
          <Menu.Item key="mb-g-hm-1" onClick={goHome}>
            {intl.get("header_links_home")}
          </Menu.Item>
          <Menu.Item key="mb-g-hm-2" onClick={goToSearchResultPage}>
            {intl.get("header_links_doctors")}
          </Menu.Item>
          {isResetPwd ? (
            <Menu.Item key="mb-g-hm-3" onClick={goToLogin}>
              {intl.get("header_links_login")}
            </Menu.Item>
          ) : (
            <>
              <Menu.Item key="mb-g-hm-3" onClick={goToBlogPage}>
                {intl.get("header_links_blogs")}
              </Menu.Item>
              {logined ? (
                <>
                  <Menu.Item key="account" onClick={goToAccount}>
                    {intl.get("header_user_center_my_account")}
                  </Menu.Item>
                  {role === RoleType.ACUPUNCTURIST && (
                    <Menu.Item key="profile" onClick={goToProfile}>
                      {intl.get("header_user_center_my_profile")}
                    </Menu.Item>
                  )}
                  <Menu.Item key="logout" onClick={logout}>
                    {intl.get("header_user_center_logout")}
                  </Menu.Item>
                </>
              ) : (
                <Menu.Item key="login" onClick={goToLogin}>
                  Login
                </Menu.Item>
              )}
            </>
          )}
        </Menu>
      )
    );
  };

  return (
    <div className={classNames("App-header", whiteBg && "white-bg-header")}>
      <div className="logo" onClick={goHome}>
        <img
          src={
            isPC
              ? whiteBg
                ? Logo2
                : Logo
              : whiteBg
              ? LogoResponsive2
              : LogoResponsive
          }
          alt="header-logo"
        />
      </div>
      <div className="links">
        {isPC && (
          <>
            <span className="link home-link" onClick={goHome}>
              {intl.get("header_links_home")}
            </span>
            <span className="link doctor-link" onClick={goToSearchResultPage}>
              {intl.get("header_links_doctors")}
            </span>
            <span className="link blog-link" onClick={goToBlogPage}>
              {intl.get("header_links_blogs")}
            </span>
          </>
        )}
        {showUser ? (
          isPC ? (
            !id ? (
              <Button className="login-link" type="primary" onClick={goToLogin}>
                login
              </Button>
            ) : (
              <div className="user-center">
                <div className="user-center-ring"></div>
                <Dropdown
                  className="drop-down"
                  overlay={
                    <Menu onClick={onMenuClick}>
                      {role === RoleType.ACUPUNCTURIST && (
                        <Menu.Item key="profile">
                          {intl.get("header_user_center_my_profile")}
                        </Menu.Item>
                      )}
                      <Menu.Item key="account">
                        {intl.get("header_user_center_my_account")}
                      </Menu.Item>
                      <Menu.Item key="logout">
                        {intl.get("header_user_center_logout")}
                      </Menu.Item>
                    </Menu>
                  }
                  arrow={false}
                  placement="bottomRight"
                  overlayClassName="header-user-center"
                  trigger={["hover"]}
                >
                  <div className="user-center-avatar">
                    <Avatar
                      size={Math.min(
                        ((document.body.clientWidth / 1440) * 32) >> 0,
                        32
                      )}
                      src={avatar?.url}
                    />
                  </div>
                </Dropdown>
              </div>
            )
          ) : (
            <Dropdown trigger={["click"]} overlay={getHeaderMenu()}>
              <img
                className="header-menus"
                src={whiteBg ? HeaderMenusWhite : HeaderMenusDark}
                alt="header-menus"
              />
            </Dropdown>
          )
        ) : null}
      </div>
    </div>
  );
};
