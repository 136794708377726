import { getClinicBizHrs, putClinicBizHrs } from "@/web/api/clinic";
import { accountConfirmPwd } from "@/web/comps/account_confirm_pwd_modal";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useMount, useRequest } from "ahooks";
import { Button, Collapse, Form, InputNumber, Switch, TimePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment, { Moment } from "moment";
import { useState } from "react";
import "./account_clinic_biz_hr.scss";

const { Panel } = Collapse;
const weekdays = {
  monday: {
    open: true,
    name: "MON",
    times: [] as string[],
  },
  tuesday: {
    open: true,
    name: "TUE",
    times: [] as string[],
  },
  wednesday: {
    open: true,
    name: "WED",
    times: [] as string[],
  },
  thursday: {
    open: true,
    name: "THU",
    times: [] as string[],
  },
  friday: {
    open: true,
    name: "FRI",
    times: [] as string[],
  },
  saturday: {
    open: true,
    name: "SAT",
    times: [] as string[],
  },
  sunday: {
    open: true,
    name: "SUN",
    times: [] as string[],
  },
};
type WeekCfg = typeof weekdays;
type WeekDay = keyof WeekCfg;
const weekArr = Object.keys(weekdays) as WeekDay[];
export const AccountClinicBizHr = () => {
  const [cfg, setCfg] = useState<WeekCfg>({ ...weekdays });
  const [form] = useForm();

  const { run: putHrs, loading } = useRequest(putClinicBizHrs, {
    manual: true,
  });
  const onFinish = (values: any) => {
    // save times
    accountConfirmPwd({
      onSuccess: () => trySave(values),
    });
  };

  const trySave = (values: any) => {
    const businesshours: any[] = [];
    for (let key in values) {
      const weekdayCfg = cfg[key as WeekDay];
      const dayTimes = values[key];
      if (!dayTimes?.length) {
        // check open status
        if (
          !weekdayCfg.open &&
          !businesshours.find((_) => _.weekday === weekdayCfg.name)
        ) {
          businesshours.push({
            weekday: weekdayCfg.name,
            status: weekdayCfg.open ? 1 : 0,
          });
        }
        continue;
      }
      dayTimes.forEach(({ time, seat }: any) => {
        const [timeStart, timeEnd] = time?.map((_: any) =>
          _.toDate()
            .toISOString()
            .split("T")[1]
            .split(":")
            .slice(0, 2)
            .join(":")
        ); // pick utc time string
        // check utc weekday depends on timeStart
        const start = time[0] as Moment;
        const weekdayUTC = start
          .clone()
          .day(weekdayCfg.name)
          .add(new Date().getTimezoneOffset(), "minute")
          .format("ddd")
          .toUpperCase();
        businesshours.push({
          weekday: weekdayCfg.name,
          weekdayUTC,
          status: weekdayCfg.open ? 1 : 0,
          timeStart,
          timeEnd,
          seat,
        });
      });
    }
    putHrs(businesshours);
  };

  useMount(async () => {
    const res = await getClinicBizHrs();
    const { businesshours } = res;
    if (businesshours.length) {
      const _cfg = { ...cfg };
      const _values: any = {};
      businesshours.forEach(
        ({ weekday, status, timeStart, timeEnd, updateDate, seat }: any) => {
          const key = weekArr.find((_) => _.includes(weekday.toLowerCase()))!;
          _cfg[key].open = status == 1; // string '1' or number 1
          // transform form values
          _values[key] = _values[key] || [];
          timeStart &&
            timeEnd &&
            _values[key].push({
              time: [timeStart, timeEnd].map((_) =>
                moment(`${moment(updateDate).format('YYYY-MM-DD')}T${_}:00.000Z`)
              ),
              seat,
            });
            _values[key].sort((a:any,b: any) => a.time[0].isBefore(b.time[0]) ? -1 : 1)
        }
      );
      setCfg(_cfg);
      form.setFieldsValue(_values);
    }
  });

  return (
    <div className="account-content-page account-biz-hr">
      <Collapse>
        {weekArr.map((w) => (
          <Panel
            key={w}
            forceRender
            header={
              <div className="header-line">
                <div className="weekday">{w}</div>
                <Switch
                  onClick={(_, e) => e.stopPropagation()}
                  checked={cfg[w].open}
                  onChange={(open) =>
                    setCfg({
                      ...cfg,
                      [w]: {
                        ...cfg[w],
                        open,
                      },
                    })
                  }
                  title="Open"
                />
              </div>
            }
          >
            <Form layout="vertical" form={form} onFinish={onFinish}>
              <Form.List name={w}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restFields }, index) => (
                      <div className="form-row" key={key}>
                        <div className="form-column">
                          <Form.Item
                            className="first-form-item"
                            {...restFields}
                            name={[name, "time"]}
                            fieldKey={[name, "time"]}
                            label={`time ${index + 1}`}
                            rules={[
                              { required: true, message: "select time range" },
                            ]}
                          >
                            <TimePicker.RangePicker
                              format="HH:mm"
                              minuteStep={30}
                            />
                          </Form.Item>
                          <Form.Item
                            label="seat"
                            name={[name, "seat"]}
                            rules={[
                              { required: true, message: "lack seat number" },
                            ]}
                          >
                            <InputNumber
                              placeholder="seat number of this period"
                              min={1}
                            />
                          </Form.Item>
                        </div>
                        <CloseCircleOutlined
                          style={{
                            fontSize: "20px",
                            color: "#BCC4CE",
                            position: "relative",
                            top: "40px",
                          }}
                          onClick={() => remove(name)}
                        />
                      </div>
                    ))}
                    <div className="add-btn-container">
                      <Button
                        type="dashed"
                        className="add-btn"
                        onClick={() => add()}
                      >
                        + Add hours
                      </Button>
                    </div>
                  </>
                )}
              </Form.List>
            </Form>
          </Panel>
        ))}
      </Collapse>
      <div className="submit-btn">
        <Button type="primary" loading={loading} onClick={form.submit}>
          Save
        </Button>
      </div>
    </div>
  );
};
