import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { putFtlStep } from "../api/ftl";
import { router_account_profile, router_ftl_root } from "../utils/consts";
import IconVector from "@/assets/images/icon-vector.svg";
import { PlusOutlined, UpOutlined } from "@ant-design/icons";
import { formatNumber } from "../utils/util";
import "./profile_doctor_info.scss";
import { WrapperText } from "./wrapper_text";

interface Props {
  introduction: string;
  isSelf: boolean;
  expertiseAreas: any[];
  treatmentTechniques: any[];
  accomplishments: any[];
  treatmentServices: any[];
  educations: any[];
  workExperiences: any[];
  licenses: any[];
  certificates: any[];
}
export const ProfileDoctorInfo = ({
  introduction = "",
  isSelf,
  expertiseAreas = [],
  treatmentTechniques = [],
  accomplishments = [],
  treatmentServices = [],
  educations = [],
  workExperiences = [],
  licenses = [],
  certificates = [],
}: Props) => {
  const navigate = useNavigate();
  const [showAllTrmt, setShowAllTrmt] = useState(false);
  const [showAllAcmp, setShowAllAcmp] = useState(false);
  const goEdit = async (subMenu: string) => {
    navigate(router_account_profile + "/" + subMenu);
  };

  const treatmentTechniqueWrapped = useMemo(
    () => treatmentTechniques?.slice(0, 3),
    [treatmentTechniques]
  );
  const accomplishmentsWrapped = useMemo(
    () => accomplishments?.slice(0, 3),
    [accomplishments]
  );

  return (
    <div className="information-comp">
      <div className="information-list">
        <div className="information-item">
          <div className="information-item-title">Introduction</div>
          <div className="information-item-content">
            {introduction ? (
              <WrapperText text={introduction} className="introduction" />
            ) : (
              <div className="update-info">
                No Information available.
                {isSelf && (
                  <>
                    Please update in
                    <span
                      className="update-info-link"
                      onClick={(e) => goEdit("me")}
                    >
                      your profile
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="information-item">
          <div className="information-item-title">Area of expertise</div>
          <div className="information-item-content">
            {expertiseAreas?.length > 0 ? (
              <div className="expertise">
                {expertiseAreas.map((item, index) => (
                  <div key={index} className="area-item">
                    <div className="icon">
                      <img src={IconVector} alt="icon-vector" />
                    </div>
                    <div className="name">{item.name}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="update-info">
                No Information available.
                {isSelf && (
                  <>
                    Please update in
                    <span
                      className="update-info-link"
                      onClick={() => goEdit("me")}
                    >
                      your profile
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="information-item">
          <div className="information-item-title">Treatment techniques</div>
          <div className="information-item-content">
            {treatmentTechniques?.length > 0 ? (
              <div className="treatment-techniques">
                {treatmentTechniques.length > 6 && !showAllTrmt && (
                  <div
                    className="view-all"
                    onClick={(e) => setShowAllTrmt(!showAllTrmt)}
                  >
                    <PlusOutlined
                      style={{ fontSize: "12px", color: "#449086" }}
                    />
                    View all
                  </div>
                )}
                <div
                  className={[
                    "treatment-technique-list",
                    showAllTrmt ? "" : "show-all",
                  ].join(" ")}
                >
                  {(showAllTrmt
                    ? treatmentTechniques
                    : treatmentTechniqueWrapped
                  ).map((item, index) => (
                    <div key={index} className="treatment-technique-item">
                      {item.name}
                    </div>
                  ))}
                </div>
                {treatmentTechniques.length > 6 && showAllTrmt && (
                  <div className="hide-more-tags">
                    <UpOutlined onClick={(e) => setShowAllTrmt(!showAllTrmt)} />
                  </div>
                )}
              </div>
            ) : (
              <div className="update-info">
                No Information available.
                {isSelf && (
                  <>
                    Please update in
                    <span
                      className="update-info-link"
                      onClick={() => goEdit("me")}
                    >
                      your profile
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="information-item">
          <div className="information-item-title">Services</div>
          <div className="information-item-content">
            {treatmentServices?.length > 0 ? (
              <div className="services">
                <div className="services-table-row">
                  <div className="services-table-column">Service</div>
                  <div className="services-table-column">Duration (Min)</div>
                  <div className="services-table-column">Price ($)</div>
                </div>
                {treatmentServices.map((item, index) => (
                  <div key={index} className="services-table-row">
                    <div className="services-table-column">{item.name}</div>
                    <div className="services-table-column">
                      {formatNumber(item.duration * 1)}
                    </div>
                    <div className="services-table-column">
                      {formatNumber(item.price * 1)}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="update-info">
                No Information available.
                {isSelf && (
                  <>
                    Please update in
                    <span
                      className="update-info-link"
                      onClick={(e) => goEdit("service")}
                    >
                      your profile
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="information-item">
          <div className="information-item-title">
            Education and past position list
          </div>
          <div className="information-item-content">
            {educations?.length > 0 || workExperiences?.length > 0 ? (
              <div className="expertise">
                {educations?.map((item, index) => (
                  <div key={index} className="area-item">
                    <div className="icon">
                      <img src={IconVector} alt="" />
                    </div>
                    <div className="name2">
                      {`${item.degree}${item.degree && ","} ${item.school}`}
                    </div>
                    <div className="date">{item.graduatedYear}</div>
                  </div>
                ))}
                {workExperiences?.map((item, index) => (
                  <div key={index} className="area-item">
                    <div className="icon">
                      <img src={IconVector} alt="" />
                    </div>
                    <div className="name2">
                      {`${item.position}${item.position && ","} ${
                        item.companyName
                      }`}
                    </div>
                    <div className="date">{item.experience} years</div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="update-info">
                No Information available.
                {isSelf && (
                  <>
                    Please update in
                    <span
                      className="update-info-link"
                      onClick={(e) => goEdit("experience")}
                    >
                      your profile
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="information-item">
          <div className="information-item-title">
            Licenses and certificates
          </div>
          <div className="information-item-content">
            {licenses?.length > 0 || certificates?.length > 0 ? (
              <div className="expertise">
                {(licenses || [])
                  .concat(certificates || [])
                  .map((item, index) => (
                    <div key={index} className="area-item">
                      <div className="icon">
                        <img src={IconVector} alt="" />
                      </div>
                      <div className="name">{item.name}</div>
                      <div className="date">{item.issueDate}</div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="update-info">
                No Information available.
                {isSelf && (
                  <>
                    Please update in
                    <span
                      className="update-info-link"
                      onClick={(e) => goEdit("experience")}
                    >
                      your profile
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="information-item">
          <div className="information-item-title">
            Professional membership and academia activities (including
            publications)
          </div>
          <div className="information-item-content">
            {accomplishments?.length > 0 ? (
              <div className="membership-activities">
                {(showAllAcmp ? accomplishments : accomplishmentsWrapped).map(
                  (item, index) => (
                    <div key={index} className="membership-activities-item">
                      {item.content}
                    </div>
                  )
                )}
                {accomplishments.length > 3 && !showAllAcmp && (
                  <div className="view-more">
                    <span onClick={(e) => setShowAllAcmp(!showAllAcmp)}>
                      <PlusOutlined
                        style={{ fontSize: "12px", color: "#2F80ED" }}
                      />
                      view more publications
                    </span>
                  </div>
                )}
                {accomplishments.length > 0 && showAllAcmp && (
                  <div className="hide-more">
                    <UpOutlined onClick={(e) => setShowAllAcmp(!showAllAcmp)} />
                  </div>
                )}
              </div>
            ) : (
              <div className="update-info">
                No Information available.
                {isSelf && (
                  <>
                    Please update in
                    <span
                      className="update-info-link"
                      onClick={(e) => goEdit("experience")}
                    >
                      your profile
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
