import Calendar from "react-calendar";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import "react-calendar/dist/Calendar.css";
import "./booking_calendar.scss";
import moment from "moment";
import { Alert, Button } from "antd";
import { useMount } from "ahooks";

export type TimeOpt = {
  /** HH:mm */
  timeStart: string;
  timeEnd: string;
  ap: "AM" | "PM";
  active: boolean;
};
interface Props {
  disabledTip?: string;
  times?: TimeOpt[];
  value?: Date;
  onChangeDate?: (val: Date) => void;
  onChangeTime?: (val?: TimeOpt) => void;
}
const displayTime = (time: string) => {
  const [h, m] = time.split(":");
  const fixedHr = +h - 12;
  if (fixedHr > 0) {
    return `${fixedHr > 9 ? "" : "0"}${fixedHr}:${m}`;
  }
  return time;
};

export const BookingCalendar = ({
  disabledTip,
  times = [],
  value,
  onChangeDate,
  onChangeTime,
}: Props) => {
  const [date, setDate] = useState<Date>(new Date());
  const [time, setTime] = useState<TimeOpt>();

  const { ams, pms } = useMemo(() => {
    const ams: TimeOpt[] = [];
    const pms: TimeOpt[] = [];
    times?.forEach((_) => (_.ap === "AM" ? ams.push(_) : pms.push(_)));
    ams.sort((a, b) => (a.timeStart < b.timeStart ? -1 : 1));
    pms.sort((a, b) => (a.timeStart < b.timeStart ? -1 : 1));
    return { ams, pms };
  }, [times]);

  useEffect(() => {
    if (!value) {
      setTime(undefined);
      return;
    }
    const dateTime = moment(value);
    const date = dateTime.toDate();
    const hm = dateTime.format("HH:mm");
    if (dateTime.isSame(date, "date") && hm === time?.timeStart) {
      return;
    }
    setDate(date);
    const tm = times.find((_) => _.timeStart === hm);
    tm && setTime(tm);
  }, [value]);

  const dateChange = (val: Date) => {
    setDate(val);
    timeChange(undefined);
    onChangeDate && onChangeDate(val);
  };
  const timeChange = (val?: TimeOpt) => {
    setTime(val);
    onChangeTime && onChangeTime(val);
  };
  useMount(() => {
    !value && onChangeDate && onChangeDate(date);
  });
  return (
    <div className="common-calendar">
      {time ? (
        <div className="chosen-time">
          <span className="date">{moment(date).format("ddd MMM DD YYYY")}</span>
          <span className="time">
            {displayTime(time.timeStart) + time.ap} -{" "}
            {displayTime(time.timeEnd) +
              (+time.timeEnd.split(":")[0] >= 12 ? "PM" : time.ap)}
          </span>
        </div>
      ) : (
        <div className="chosen-tip">Please pick up a time</div>
      )}
      <div className="calendar">
        <Calendar
          value={date}
          locale="en-US"
          minDate={new Date()}
          onClickDay={dateChange}
          onClickMonth={dateChange}
        />
      </div>
      <div className="book-list">
        {disabledTip && <Alert message={disabledTip} type="error" />}
        {!!ams.length && (
          <div className="am-pm">
            <div className="title">AM</div>
            {ams.map((item, index) => (
              <div
                key={"am_" + index}
                className={classNames("book-item", {
                  disabled: !item.active,
                  selected: item.timeStart === time?.timeStart,
                })}
                onClick={() => item.active && timeChange(item)}
              >
                {item.timeStart} - {item.timeEnd}
              </div>
            ))}
          </div>
        )}
        {!!pms.length && (
          <div className="am-pm">
            <div className="title">PM</div>
            {pms.map((item, index) => (
              <div
                key={"pm_" + index}
                className={classNames("book-item", {
                  disabled: !item.active,
                  selected: item.timeStart === time?.timeStart,
                })}
                onClick={() => item.active && timeChange(item)}
              >
                {displayTime(item.timeStart)} - {displayTime(item.timeEnd)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
