import { ajax } from "../utils/ajax";
import { ClinicRole, DoctorStatus } from "../utils/types";

export const getClinicById = (id: number | string) =>
  ajax(`/clinic/${id}`, { type: "get" });

export const getClinicByName = (name: string) =>
  ajax(`/clinic/matchName`, {
    type: "get",
    params: { input: name },
    cacheTTL: 0,
  });

export const putClinic = (id: number, data: any) =>
  ajax("/clinic/basicInfo/" + id, { type: "put", data });

export const delClinicPic = (pic: string) =>
  ajax(`clinic/delete-picture/${pic}`, { type: "delete" });

export const getMyCinicRole = (clinicId: string | number) =>
  ajax("clinic/my-clinic-role/" + clinicId);

export const applyCinicRole = (profileId: number, clinicRole: ClinicRole) =>
  ajax("clinic/doctor-apply-role", {
    type: "put",
    data: { profileId, clinicRole },
  });
export const applyCinicDoctorStatus = (
  profileId: number,
  status: DoctorStatus
) =>
  ajax("clinic/doctor-apply-status", {
    type: "put",
    data: { profileId, status },
  });

export const getClinicDoctors = () => ajax("clinic/doctors", { cacheTTL: 0 });

export const getClinicBizHrs = () => ajax("clinic/business-hour", { cacheTTL: 0 });

export const putClinicBizHrs = (businessHours: any) =>
  ajax("clinic/business-hour", { type: "put", data: { businessHours } });

export const removeClinicDoctor = (id: number | string) =>
  ajax("clinic/remove-doctor/" + id, { type: "delete" });
