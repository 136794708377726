import { Avatar, Button, Modal, Rate } from "antd";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import { BasicLayout } from "@/web/comps/basic_layout";
import { formatNumber } from "@/web/utils/util";
import { useNavigate } from "react-router-dom";
import { router_account_setting, router_ftl_root } from "@/web/utils/consts";
import { userClaim } from "@/web/api/user";
import { useModel } from "use-reaction";
import {
  action_user_discard_claim,
  action_user_get_info,
  model_user,
} from "@/web/models/model_user";
import { DataItem } from "@/web/utils/types";
import "./index.scss";

export const ClaimProfile = () => {
  const navigate = useNavigate();
  const {
    doAction,
    store: { unclaimed },
  } = useModel(model_user);
  const doctor = unclaimed?.profile;
  const doctorRating = unclaimed?.rating;
  const cancelClaim = () =>
    Modal.confirm({
      title: "Discard this profile?",
      className: "fma-modal",
      content:
        "Are you sure to discard this existing profile in our system, and build a new one?",
      okText: "Discard",
      onOk: async () => {
        await doAction(action_user_discard_claim);
        navigate(router_ftl_root);
      },
    });

  const sendClaim = async () => {
    await userClaim(doctor.id);
    navigate(router_ftl_root);
  };

  return (
    <BasicLayout>
      <div className="claim-profile">
        <div className="title font-family-merriweather">
          We found this profile already exists. Is this you?
        </div>
        <div className="card">
          <div className="handle-zoom">
            <span className="title">Claim your profile</span>
            <div className="discard">
              <span>It's not me,</span>
              <span onClick={cancelClaim} className="btn">
                build new profile
              </span>
            </div>
          </div>
          <div className="detail-zoom">
            <div className="detail-avatar">
              <Avatar
                size={Math.min(
                  ((document.body.clientWidth / 1440) * 88) >> 0,
                  88
                )}
                icon={<UserOutlined />}
                src={doctor?.avatar?.url}
              />
            </div>
            <div className="detail-infos">
              <div className="detail-infos-sub">
                <div className="detail-infos-left">
                  <div className="name">{doctor?.name}</div>
                  <div className="medical-title">{doctor?.medicalTitle}</div>
                </div>
                <div className="detail-infos-right">
                  <Rate
                    allowHalf
                    disabled
                    defaultValue={doctorRating?.overallRating}
                  />
                  <div className="reviews">
                    {formatNumber(doctorRating?.reviewCount)} review
                    {doctorRating?.reviewCount > 1 ? "s" : ""}
                  </div>
                </div>
              </div>
              <div className="detail-infos-sup">
                {(doctor?.expertiseAreas || [])
                  .map((_: DataItem) => _.name)
                  .join(", ")}
              </div>
            </div>
            <div className="detail-btn">
              <Button type="primary" onClick={sendClaim}>
                Claim
              </Button>
            </div>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};
