import { acuAddBooking, getBooked } from "@/web/api/booking";
import {
  acuAddSchedule,
  acuDelSchedule,
  getAcuSchedules,
  getAcuServices,
} from "@/web/api/profile";
import { EventCalendar } from "@/web/comps/event_calendar";
import { model_user } from "@/web/models/model_user";
import {
  bookingStatusCfg,
  router_account_payment,
  scheduleLabelCfg,
} from "@/web/utils/consts";
import { EventType, Opt, ScheduleInterval } from "@/web/utils/types";
import { dataItem2Opt } from "@/web/utils/util";
import { MailOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
  Switch,
  Tag,
  TimePicker,
  Tooltip,
} from "antd";
import { useForm, useWatch } from "antd/lib/form/Form";
import classNames from "classnames";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useModel } from "use-reaction";
import "./account_acu_calendar.scss";
const { Option } = Select;

export const AccountAcuCalendar = () => {
  const {
    store: { profile },
  } = useModel(model_user);

  const navigate = useNavigate();
  const [creatingForm] = useForm();
  const [scheduleForm] = useForm();
  const [popCreate, setPopCreate] = useState<{
    start: Date;
    end: Date;
    view: string;
  }>();
  const [creating, setCreating] = useState<{ start: Date; end: Date }>();
  const [scheduling, setScheduling] = useState<{
    id?: number;
    start: Date;
    end: Date;
  }>();
  const [weekRange, setWeekRange] = useState<[Date, Date]>();
  const serviceId = useWatch("serviceId", creatingForm);
  const { data: treatmentServices } = useRequest<Opt[], any>(
    async () => {
      const res = await getAcuServices();
      return res.treatmentServices?.map(dataItem2Opt);
    },
    { ready: !!profile?.id }
  );
  const {
    data: myItems,
    refresh,
    loading,
  } = useRequest(
    async () => {
      const dateRange = weekRange?.length
        ? [weekRange[0], moment(weekRange[1]).endOf('date').toDate()]
        : [moment().startOf("week").toDate(), moment().endOf("week").toDate()];
      const booked = await getBooked({
        doctorId: profile?.id,
        clinicId: profile.clinicId,
        dateRange,
        limit: 99,
      });
      const schedules = await getAcuSchedules({ dateRange });
      return booked.list
        .map((_: any) => ({
          ..._,
          type: EventType.APPOINTMENT,
        }))
        .concat(
          schedules.map((_: any) => ({ ..._, type: EventType.SCHEDULE }))
        );
    },
    {
      refreshDeps: [weekRange],
      ready: !!profile?.id,
    }
  );

  const delSchedule = async (item: any) => {
    Modal.confirm({
      title: "Sure to delete?",
      className: "fma-modal",
      okText: "Yes",
      centered: true,
      onOk: async () => {
        await acuDelSchedule(item.id);
        await refresh();
      },
    });
  };
  const eventItem = ({
    event: { start, end, type, resource: item },
    style,
  }: any) => {
    if (!style?.top){
      return <i></i>
    }
    const times = item.allDay
      ? "All Day"
      : `${moment(start).format("HH:mm A")} - ${moment(end).format("HH:mm A")}`;
    return type === EventType.APPOINTMENT ? (
      <Tooltip
        placement="leftTop"
        trigger={["hover", "click"]}
        overlay={
          <div className="grid-item-tooltip">
            <Tag>{times}</Tag>
            <Tag color={bookingStatusCfg[item?.status].color}>
              {bookingStatusCfg[item?.status].label}
            </Tag>
            <Divider />
            <div className="patient">
              <div className="info">
                <span>Patient:</span>
                <span>{item?.patientName}</span>
              </div>
              <div className="info">
                <span>
                  <MailOutlined />
                </span>
                <span>{item.patientEmail}</span>
              </div>
            </div>
            <div className="detail">
              <div className="info">
                <span>Treatment: </span>
                <span>
                  {item.serviceDuration} mins {item.serviceName}
                </span>
              </div>
              <div className="info">
                <span>Problem:</span>
                <span>{item.healthProblem}</span>
              </div>
            </div>
          </div>
        }
      >
        <div
          className={classNames("rbc-event", item!.status)}
          key={item!.id}
          style={{ ...style, top: style.top + "%", left: style.left + "%" }}
          onClick={() => onClickItem && onClickItem(type, item)}
        >
          <div className="rbc-event-label">{times}</div>
          <div className="rbc-event-content">{item!.patientName}</div>
        </div>
      </Tooltip>
    ) : (
      <Tooltip
        placement="leftTop"
        trigger={["hover", "click"]}
        overlay={
          <div className="grid-item-tooltip">
            <Tag>{times}</Tag>
            <Tag color={scheduleLabelCfg[item?.interval].color}>
              Frequency: {scheduleLabelCfg[item?.interval].label}
            </Tag>
            <Divider />

            <div className="detail event">
              <div className="info">
                <span>Title:</span>
                <span>{item?.title}</span>
              </div>
              <Button danger onClick={() => delSchedule(item)}>
                delete
              </Button>
            </div>
          </div>
        }
      >
        <div
          className={classNames("rbc-event", item.interval)}
          key={"schedule_" + item!.id}
          style={{ ...style, top: style.top + "%", left: style.left + "%" }}
          onClick={() => onClickItem && onClickItem(type, item)}
        >
          <div className="rbc-event-label">{times}</div>
          <div className="rbc-event-content">{item!.title}</div>
        </div>
      </Tooltip>
    );
  };

  const onClickItem = (type: EventType, { id, ...others }: any) => {
    if (type === EventType.APPOINTMENT) {
      navigate(router_account_payment + "/" + id);
    } else {
      // show update schedule
      const { originStart, originEnd, start, end, ...data } = others;
      scheduleForm.setFieldsValue({
        dateTime: [moment(originStart || start), moment(originEnd || end)],
        ...data,
      });
      setScheduling({ id, start, end });
    }
  };
  const showCreate = () => {
    const { start, end } = popCreate!;
    creatingForm.setFieldsValue({ dateTime: [moment(start), moment(end)] });
    setCreating({ start, end });
    setPopCreate(undefined);
  };
  const showSchedule = () => {
    const { start, end } = popCreate!;
    scheduleForm.setFieldsValue({
      dateTime: [moment(start), moment(end)],
      allDay: false,
    });
    setScheduling({ start, end });
    setPopCreate(undefined);
  };

  const { runAsync: postBooking, loading: loadingPost } = useRequest(
    acuAddBooking,
    {
      manual: true,
    }
  );
  const { runAsync: postSchedule, loading: loadingPost2 } = useRequest(
    acuAddSchedule,
    {
      manual: true,
    }
  );
  const addBooking = async () => {
    const values = await creatingForm.validateFields();
    const dateTime = values.dateTime[0];
    await postBooking({
      ...values,
      age: +values.age,
      dateTime,
      date: [
        moment(dateTime).startOf("date").toDate(),
        moment(dateTime).endOf("date").toDate(),
      ],
      doctorId: profile.id,
      clinicId: profile.clinicId,
    });
    await refresh();
    setCreating(undefined);
    creatingForm.resetFields();
  };
  const addSchedule = async () => {
    const values = await scheduleForm.validateFields();
    const { dateTime, ...others } = values;
    let [start, end] = dateTime as [Moment, Moment];
    if (others.allDay) {
      start = start.hour(7).minute(0);
      end = start.clone().add(2, "h");
    }
    await postSchedule({
      ...others,
      start: start.toDate(),
      end: end.toDate(),
    });
    await refresh();
    setScheduling(undefined);
    scheduleForm.resetFields();
  };

  useEffect(() => {
    if (serviceId) {
      const service = treatmentServices?.find(
        (_) => _.value === serviceId
      ) as any;
      if (service) {
        const end = moment(creating?.start).add(service.duration, "minute");
        creatingForm.setFieldsValue({
          dateTime: [moment(creating?.start), end],
        });
      }
    }
  }, [serviceId]);

  return (
    <div className="acu-appointment">
      <Spin spinning={loading}>
        <EventCalendar
          timeStart="07:00"
          timeEnd="23:00"
          timeStep={30}
          items={myItems}
          itemRender={eventItem}
          onWeekChange={(start, end) => setWeekRange([start, end])}
          onClickItem={onClickItem}
          onCreate={setPopCreate}
        />
        <Modal
          visible={!!popCreate}
          closable
          className="fma-modal appointment-modal calendar-action-modal"
          onCancel={() => setPopCreate(undefined)}
          title="Choose Action:"
          footer={null}
          centered
        >
          <div className="action-sec">
            <Button
              onClick={showCreate}
              disabled={moment(popCreate?.start).isBefore(moment())}
            >
              Add Appointment
            </Button>
            <div className="tip">
              Pick a time period after present to add appointment
            </div>
          </div>

          <div className="action-sec">
            <Button onClick={showSchedule}>Add Schedule</Button>
            <div className="tip">
              You can make a one-time or frequency schedule
            </div>
          </div>
        </Modal>
        <Modal
          visible={!!creating}
          closable
          centered
          className="fma-modal appointment-modal"
          onCancel={() => setCreating(undefined)}
          onOk={addBooking}
          confirmLoading={loadingPost}
          okText="Add"
          title={`Add Appointment on ${moment(creating?.start).format(
            "MMM DD"
          )}`}
        >
          <Form
            className="add-appointment-form"
            layout="vertical"
            form={creatingForm}
          >
            <Form.Item label="Bookint time" name="dateTime">
              <TimePicker.RangePicker disabled />
            </Form.Item>
            <Form.Item
              label="Select a service"
              name="serviceId"
              rules={[{ required: true, message: "Missing service" }]}
            >
              <Select
                placeholder="Select services"
                options={treatmentServices}
              />
            </Form.Item>
            <div className="form-column patient-name-item">
              <Form.Item
                label="Patient name"
                name="patientName"
                rules={[{ required: true, message: "Missing name" }]}
              >
                <Input placeholder="Please input patient's name" />
              </Form.Item>
              <Form.Item
                label="Patient email"
                name="email"
                rules={[{ required: true, message: "Missing email" }]}
              >
                <Input
                  type="email"
                  placeholder="Please input patient's email"
                />
              </Form.Item>
              <Form.Item label="Phone number" name="phoneNumber">
                <Input placeholder="Please input patient's phone number" />
              </Form.Item>
            </div>
            <div className="form-column age-item">
              <Form.Item label="Age" name="age">
                <Input
                  type="number"
                  max={150}
                  min={0}
                  step={1}
                  placeholder="Please input patient's age"
                />
              </Form.Item>
              <Form.Item label="Gender" name="gender">
                <Select placeholder="Please select patient's gender">
                  <Option value="female">Female</Option>
                  <Option value="male">Male</Option>
                </Select>
              </Form.Item>
            </div>
            <Form.Item
              label="What health problem do you want to beat?"
              name="healthProblem"
              rules={[{ required: true, message: "Missing health problem" }]}
            >
              <Input placeholder="describe health problem" />
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={!!scheduling}
          closable
          centered
          className="fma-modal appointment-modal"
          onCancel={() => setScheduling(undefined)}
          onOk={addSchedule}
          confirmLoading={loadingPost2}
          okText="SAVE"
          title={`Add Schedule Event on ${moment(scheduling?.start).format(
            "MMM DD"
          )}`}
        >
          <Form
            className="add-appointment-form"
            layout="vertical"
            form={scheduleForm}
          >
            <Form.Item
              label="title"
              name="title"
              rules={[{ required: true, message: "Missing title" }]}
            >
              <Input placeholder="Please input title" />
            </Form.Item>
            <Form.Item noStyle dependencies={["allDay"]}>
              {({ getFieldValue }) => {
                const allDay = getFieldValue("allDay");
                const dateTime = getFieldValue("dateTime");
                return (
                  <Form.Item label="time" name="dateTime" required>
                    <TimePicker.RangePicker
                      showSecond={false}
                      minuteStep={10}
                      disabled={allDay}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item name="allDay" label="All day">
              <Switch />
            </Form.Item>
            <Form.Item label="Frequency" name="interval" required>
              <Radio.Group>
                <Radio value={ScheduleInterval.ONCE}>one-time</Radio>
                <Radio value={ScheduleInterval.WEEKLY}>weekly</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </div>
  );
};
