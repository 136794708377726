import { ajax } from "@/web/utils/ajax";
import { KV } from "use-reaction";
import { SearchResItem } from "../comps/search_res_card";
import { GOOGLE_MAP_KEY } from "../utils/consts";
import { GeoPoint } from "../utils/types";

type V = number | string;
export type GeoCircle = {
  type: "circle";
  radius: V; // in miles
  center: { lat: V; lng: V };
};
export type GeoArea = {
  type: "area";
  from: { lat: V; lng: V };
  to: { lat: V; lng: V };
};
export const searchProfile = async (filter: {
  type?: "doctor" | "clinic";
  keyword?: string;
  expertise?: string[];
  geo?: GeoCircle | GeoArea;
  // pagination
  start: number; // from 0
  rows: number; // eg. 10
  sort?: string;
  // todo: rating filter
}) => {
  const { keyword, expertise, geo, type, start, rows, sort } = filter;
  const params: KV = {
    q: "*:*", // for no keyword,
    "q.op": "OR",
    start,
    rows,
    sort
  };
  if (keyword) {
    params.q = `*${keyword}*`;
  }
  if (expertise?.length) {
    params.q = [keyword, ...expertise.map((_) => `*${_}*`)]
      .filter(Boolean)
      .join(" ");
  }
  let group_field = "";
  if (type) {
    params.group = true;
    group_field = type === "clinic" ? "clinic_id" : "profile_id";
    params["group.field"] = group_field;
    params["group.limit"] = 10; // if search doctor, limit the profile number if this doctor work in many clinics
    params["group.ngroups"] = true;
  }
  if (geo) {
    if (geo.type === "circle" && geo.center) {
      params.fq = `{!geofilt}`;
      params.sfield='location'
      params.pt = geo.center.lat + "," + geo.center.lng;
      params.d = geo.radius;
    } else if (geo.type === "area" && geo.from) {
      params.fq = `location:[${geo.from.lat},${geo.from.lng} TO ${geo.to.lat},${geo.to.lng}]`;
      if (params.sort?.includes('geo')){
        delete params.sort;
      }
    }
  }

  const res = await ajax("search/profile", {
    type: "get",
    params,
    cacheTTL: 30,
  });
  if (res?.grouped[group_field]) {
    const arr = res.grouped[group_field].groups;
    const list: SearchResItem[] = [];
    arr.forEach((g: any) => {
      list.push(...(g.doclist?.docs || []));
    });
    return {
      list,
      total: res.grouped[group_field].ngroups,
    };
  }
  return {
    list: [],
    total: 0,
  };
};

export const getMyLocation = () => {
  return new Promise<GeoPoint>((_) => {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // infoWindow.setPosition(pos);
          // infoWindow.setContent("Location found.");
          // infoWindow.open(map);
          // map.setCenter(pos);
          _(pos);
        },
        () => {
          guestLocation().then(_);
        }
      );
    } else {
      // Browser doesn't support Geolocation
      guestLocation().then(_);
    }
  });
};

const guestLocation = async () => {
  const url =
    "https://www.googleapis.com/geolocation/v1/geolocate?key=" + GOOGLE_MAP_KEY;
  const res = await ajax(url, { type: "post" });
  console.log("guess loc", res?.location);
  return res?.location;
};
