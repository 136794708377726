import { getExpertiseAreaList } from "@/web/api/home";
import { BasicLayout } from "@/web/comps/basic_layout";
import { SearchResultInGoogleMap } from "@/web/comps/search_in_gmap";
import SearchResultCard, { SearchResItem } from "@/web/comps/search_res_card";
import { DataItem, GeoPoint } from "@/web/utils/types";
import { useQuery } from "@/web/utils/util";
import IconNoData from "@/assets/images/icon-no-data.svg";
import {
  Button,
  Divider,
  Input,
  List,
  Pagination,
  Radio,
  Select,
  Skeleton,
  Slider,
  Tag,
} from "antd";
import {
  BorderOuterOutlined,
  MoreOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useMount, useRequest, useResponsive } from "ahooks";
import { debounce, without } from "lodash";
import { useEffect, useState } from "react";
import { getMyLocation, searchProfile } from "@/web/api/search";
import { CFG_DEFAULT_GEO_CENTER } from "@/web/utils/consts";
import {
  action_cache_search_filter,
  model_search,
  SearchFilterOpts,
  SORT_OPTIONS,
} from "@/web/models/model_search";
import { useModel } from "use-reaction";
import "./index.scss";

export const SearchPage = () => {
  const { isPC } = useResponsive();
  const { store: filter, doAction } = useModel(model_search);
  const [showFilter, setShowFilter] = useState(false);
  const [showList, setShowList] = useState(true);
  const updateFilter = (
    kv: Partial<SearchFilterOpts>,
    apply = true,
    page = 0
  ) => {
    doAction(action_cache_search_filter, { ...kv, page });
    apply && runSearch();
  };

  const { lat, lng, ...query } = useQuery<SearchFilterOpts>();
  const [geoCenter, setGeoCenter] = useState<GeoPoint>(
    lat && lng ? { lat, lng } : (undefined as any)
  );
  const [viewingArea, setViewingArea] = useState<{
    from: GeoPoint;
    to: GeoPoint;
  }>();
  useMount(() => {
    doAction(action_cache_search_filter, { ...query });
    runSearch();
  });
  const { data: expertises } = useRequest(async () => {
    const res: DataItem[] = await getExpertiseAreaList();
    // return res.map(dataItem2Opt);
    // use expertise-name only
    return res.map((_) => ({ label: _.name, value: _.name }));
  });
  const {
    run: runSearch,
    data,
    loading,
  } = useRequest<{ list: SearchResItem[]; total: number }, any>(
    async () => {
      if (!geoCenter) {
        // try get location
        let pos = CFG_DEFAULT_GEO_CENTER;
        try {
          pos = await getMyLocation();
          setGeoCenter(pos);
        } catch (error) {
          setGeoCenter(CFG_DEFAULT_GEO_CENTER);
        } finally {
          doAction(action_cache_search_filter, pos || CFG_DEFAULT_GEO_CENTER);
        }
      }
      const res = await searchProfile({
        type: filter.type,
        keyword: filter.keyword,
        expertise: filter.expertise,
        geo: viewingArea
          ? {
              type: "area",
              ...viewingArea,
            }
          : geoCenter
          ? {
              type: "circle",
              center: geoCenter!,
              radius: filter.distance!,
            }
          : undefined,
        start: +filter.page * +filter.limit,
        rows: +filter.limit,
        sort: filter.sortBy?.includes("geo")
          ? !!geoCenter
            ? filter.sortBy
            : undefined
          : filter.sortBy,
      });
      return {
        list: res?.list.map((_) => {
          const [lat, lng] = (_.location || "0,0").split(",")?.map((v) => +v);
          return {
            ..._,
            clinic_lat: lat,
            clinic_lng: lng,
          };
        }),
        total: res?.total,
      };
    },
    { manual: true }
  );

  const onAreaChange = (ne: GeoPoint, sw: GeoPoint) => {
    // convert northeast + southwest to northwest + southeast
    const from = {
      lat: sw.lat,
      lng: ne.lng
    }
    const to = {
      lat: ne.lat,
      lng: sw.lng
    }
    setViewingArea({ from, to });
    runSearch()
  };
  useEffect(
    () => setViewingArea(showList ? undefined : viewingArea),
    [showList]
  );

  return (
    <BasicLayout>
      <div className="search-page-new">
        <aside className="aside" hidden={!isPC && !showFilter}>
          <h1>SEARCH</h1>
          <Input
            className="search-input"
            allowClear
            placeholder="input keyword"
            value={filter.keyword}
            onPressEnter={runSearch}
            onChange={(e) =>
              updateFilter({ keyword: e.target.value?.trim() }, false)
            }
          />
          <Button type="primary" className="search-btn" onClick={runSearch}>
            search
          </Button>
          <Divider />
          <h3>Show List By</h3>
          <Radio.Group
            className="list-type"
            value={filter.type}
            onChange={(e) => updateFilter({ type: e.target.value })}
          >
            <Radio value="clinic">Clinic</Radio>
            <Radio value="doctor">Doctor</Radio>
          </Radio.Group>
          <Divider />
          {!!viewingArea ? (
            <>
              <h3>Viewing Map Area</h3>
              <div className="viewing-area">
                <span>NW:</span>
                <span className="point">
                  {(+viewingArea.from.lat).toFixed(5) +
                    "," +
                    (+viewingArea.from.lng).toFixed(5)}
                </span>
              </div>
              <div className="viewing-area">
                <span>SE:</span>
                <span className="point">
                  {(+viewingArea.to.lat).toFixed(5) +
                    "," +
                    (+viewingArea.to.lng).toFixed(5)}
                </span>
              </div>
            </>
          ) : (
            <>
              <h3>Distance</h3>
              <div className="slider-val">Radius: {filter.distance} miles</div>
              <Slider
                min={1}
                max={100}
                included
                value={+filter.distance!}
                tipFormatter={(val) => (
                  <div className="search-sldr-tip">{val}</div>
                )}
                onAfterChange={(v) => updateFilter({ distance: v })}
              />
            </>
          )}
          <Divider />
          <h3>Star Rating</h3>
          <div className="slider-val">Above: {filter.rating}</div>
          <Slider
            min={0}
            max={5}
            included
            value={+filter.rating!}
            tipFormatter={(val) => <div className="search-sldr-tip">{val}</div>}
            onAfterChange={(v) => updateFilter({ rating: v })}
          />
          <Divider />
          <h3>Expertise Areas</h3>
          <Select
            className="expertise-select"
            options={expertises}
            mode="multiple"
            allowClear
            value={filter.expertise}
            onChange={(v) => updateFilter({ expertise: v })}
          />
          {!isPC && (
            <Button
              onClick={(e) => {
                setShowFilter(false);
                runSearch();
              }}
              type="primary"
              className="mb-apply-btn"
            >
              apply
            </Button>
          )}
        </aside>
        <main className="main">
          {!isPC && (
            <div className="mb-search-btns">
              <Input
                className="mb-search-input"
                allowClear
                placeholder="input keyword"
                value={filter.keyword}
                onChange={(e) =>
                  updateFilter({ keyword: e.target.value?.trim() }, false)
                }
              />
              <Button type="primary" className="search-btn" onClick={runSearch}>
                search
              </Button>
            </div>
          )}
          <div className="top-bar">
            {isPC ? (
              <div className="keywords">
                {filter.keyword && (
                  <Tag
                    closable
                    key="keyword"
                    onClose={(e) => updateFilter({ keyword: undefined })}
                  >
                    {filter.keyword}
                  </Tag>
                )}
                {filter.expertise?.map((_, idx) => (
                  <Tag
                    closable
                    key={_.replace(/\s+/g, "_")}
                    onClose={(e) =>
                      updateFilter({ expertise: without(filter.expertise!, _) })
                    }
                  >
                    {_}
                  </Tag>
                ))}
              </div>
            ) : (
              <Button
                icon={<MoreOutlined />}
                onClick={(e) => setShowFilter(true)}
              >
                filter
              </Button>
            )}
            <div className="sort-by">
              {isPC && <span>Sort By</span>}
              <Select
                options={SORT_OPTIONS}
                value={filter.sortBy}
                onChange={(e) => updateFilter({ sortBy: e })}
              />
            </div>
            <Radio.Group
              optionType="button"
              className="panel-toggle"
              defaultValue={true}
              value={showList}
              onChange={(e) => setShowList(e.target.value)}
            >
              <Radio value={true}>
                <UnorderedListOutlined style={{ fontSize: "1.5rem" }} />
              </Radio>
              <Radio value={false}>
                <BorderOuterOutlined style={{ fontSize: "1.5rem" }} />
              </Radio>
            </Radio.Group>
          </div>
          {/* contents */}
          {showList ? (
            <div className="main-list">
              {loading ? (
                <div className="skeleton-list">
                  {([1, 2, 3, 4] as const).map((item) => (
                    <div key={item} className="skeleton-item">
                      <Skeleton active />
                    </div>
                  ))}
                </div>
              ) : data?.list.length ? (
                <>
                  <List
                    className="card-list"
                    dataSource={data.list}
                    rowKey="id"
                    renderItem={(item: any, index) => (
                      <SearchResultCard
                        key={index}
                        info={item}
                        type={filter.type}
                      />
                    )}
                  />
                  <div className="result-pagination">
                    <Pagination
                      showSizeChanger={false} // default to true when > 50
                      size={isPC ? "small" : "default"}
                      total={data?.total}
                      current={+filter.page}
                      pageSize={+filter.limit}
                      onChange={(page) => updateFilter({ page }, true, page)}
                    />
                  </div>
                </>
              ) : (
                <div className="no-data">
                  <div className="no-data-img">
                    <img src={IconNoData} alt="icon-no-date" />
                  </div>
                  <div className="no-data-tip font-family-merriweather">
                    No results found
                  </div>
                  <div className="no-data-description">
                    Try adjust your search or filter to find what you’re looking
                    for.
                  </div>
                </div>
              )}
            </div>
          ) : (
            <SearchResultInGoogleMap
              list={data?.list as any}
              type={filter.type}
              sortOpts={SORT_OPTIONS}
              sort={filter.sortBy}
              loading={loading}
              onSortChange={(sortBy) => updateFilter({ sortBy: sortBy })}
              onAreaChange={onAreaChange}
              visible
            />
          )}
        </main>
      </div>
    </BasicLayout>
  );
};
