import { getBookedById, userCancelBooked } from "@/web/api/booking";
import { formatNumber } from "@/web/utils/util";
import { QuestionCircleOutlined, UserOutlined } from "@ant-design/icons";
import { useMount, useRequest } from "ahooks";
import {
  Avatar,
  Button,
  Divider,
  message,
  Modal,
  Skeleton,
  Tooltip,
} from "antd";
import IconVisa from "@/assets/images/icon-visa.svg";
import moment from "moment";
import { useNavigate, useParams } from "react-router";
import { bookingStatusCfg, BookStatus } from "@/web/utils/consts";
import { useLoading, useModel } from "use-reaction";
import { model_user } from "@/web/models/model_user";
import { RoleType } from "@/web/utils/types";
import {
  action_acu_claim_booked,
  action_booking_edit,
  action_return_deposit,
  model_booking,
} from "@/web/models/model_booking";
import classNames from "classnames";
import "./account_appointment_detail.scss";

export const AccountAppointmentDetail = () => {
  const { id } = useParams();
  const {
    store: { role },
  } = useModel(model_user);
  const {
    store: { target, basic, cardData, bookDeposite, returnAmount, status },
    doAction,
  } = useModel(model_booking);
  const loading = useLoading(model_booking);

  const navigate = useNavigate();
  const refund = () => {
    Modal.confirm({
      title: role === RoleType.USER ? "Cancel appointment" : "Return Deposit",
      content: `Click confirm to cancel this appointment and return deposit $${
        role === RoleType.USER ? returnAmount : bookDeposite
      }`,
      okText: "confirm",
      className: "fma-modal",
      width: 600,
      centered: true,
      onOk: async () => {
        const res: any = await doAction(action_return_deposit, role, "model");
        if (res?.status === BookStatus.CANCEL_SUCCESS) {
          message.success("Operation succeed!");
        }
      },
    });
  };
  const report = () => {
    Modal.confirm({
      title: "Patient not show up?",
      content:
        "Click confirm to report the patient not show up, will charge the deposit immediately.",
      okText: "confirm",
      className: "fma-modal",
      width: 600,
      centered: true,
      onOk: async () => {
        const res = await doAction(action_acu_claim_booked, null, "model");
        if (res?.status === BookStatus.CANCEL_SUCCESS) {
          message.success("Operation succeed!");
        }
      },
    });
  };

  useMount(() => {
    doAction(action_booking_edit, id, "model");
  });
  return (
    <div className="booking-summary acu-appointment-detail">
      {loading ? (
        <>
          <Skeleton paragraph={{ rows: 4 }} />
          <Skeleton paragraph={{ rows: 4 }} />
        </>
      ) : (
        <>
          <div className={classNames("status", status)}>
            {bookingStatusCfg[status]?.label}
          </div>
          <div className="summary-section-doctor">
            <div className="doctor-avatar">
              <Avatar
                size={Math.min(
                  ((document.body.clientWidth / 1440) * 48) >> 0,
                  48
                )}
                icon={<UserOutlined />}
                src={target.doctor?.profile?.avatar?.url}
              />
            </div>
            <div className="doctor-info">
              <div className="name">{target.doctor?.profile?.name}</div>
              <div className="title">
                {target.doctor?.profile?.medicalTitle}
              </div>
            </div>
            <div className="doctor-book">
              <div className="time">
                {moment(basic.dateTime).format("HH:mm A")} -{" "}
                {moment(basic.dateTime)
                  .add(basic.selectedTreatmentService.duration, "m")
                  .format("HH:mm A")}
              </div>
              <div className="date">
                {moment(basic?.dateTime).format("dddd, MMM DD YYYY")}
              </div>
            </div>
          </div>
          <Divider type="horizontal" />
          <div className="summary-section-patient">
            {target?.clinic?.name && (
              <div className="patient-item">
                <div className="key">Clinic:</div>
                <div className="value">{target?.clinic?.name}</div>
              </div>
            )}
            <div className="patient-item">
              <div className="key">Address:</div>
              <div className="value">{target?.clinic?.address}</div>
            </div>
            <div className="patient-item">
              <div className="key">Tel:</div>
              <div className="value">{target?.clinic?.phone}</div>
            </div>
            <div className="patient-item">
              <div className="key">Patients:</div>
              <div className="value">{basic?.patientName}</div>
            </div>
            <div className="patient-item">
              <div className="key">Service:</div>
              <div className="value">
                {basic?.selectedTreatmentService.label}
              </div>
            </div>
            <div className="patient-item">
              <div className="key">Health problem:</div>
              <div className="value">{basic?.healthProblem}</div>
            </div>
          </div>
          <Divider type="horizontal" />
          <div className="summary-section-fee">
            <div className="key">Deposit</div>
            <div className="value">${formatNumber(bookDeposite || 0)}</div>
          </div>
          <div className="summary-section-fee">
            <div className="key">
              Deposit Return{" "}
              <Tooltip
                overlay={
                  <div>
                    before expiration, we will return full Deposit if doctor
                    cancel this appointment
                  </div>
                }
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
            <div className="value">
              $
              {formatNumber(
                role === RoleType.USER ? returnAmount : bookDeposite
              )}
            </div>
          </div>
          <Divider type="horizontal" />
          {cardData?.last4 && (
            <>
              <div className="sec-title">Card information:</div>
              <div className="summary-section-card">
                <div className="key">{cardData?.cardholderName}</div>
                <div className="value">
                  <img src={IconVisa} alt="card-img" />
                  <span className="text">
                    {" "}
                    **** **** **** {cardData?.last4}
                  </span>
                </div>
              </div>
              <Divider type="horizontal" />
            </>
          )}
          <div className="submit-btn">
            <Button onClick={() => navigate(-1)}>Back</Button>
            {role === RoleType.ACUPUNCTURIST && (
              <Button
                danger
                type="primary"
                disabled={status !== BookStatus.BOOKING_SUCCESS}
                onClick={report}
              >
                Report stood up
              </Button>
            )}
            <Button
              type="primary"
              disabled={
                status !== BookStatus.BOOKING_SUCCESS &&
                status !== BookStatus.BOOKING_REVIEW
              }
              onClick={refund}
            >
              {role === RoleType.USER ? "Cancel appointment" : "Return Deposit"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
