import {
  Routes,
  Route,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import { AcuArea } from "./acu_area";
import { AcuClinic } from "./acu_clinic";
import { AcuExperience } from "./acu_experience";
import { AcuProfile } from "./acu_profile";
import { AcuService } from "./acu_service";
import { UsrProfile } from "./usr_profile";
import {
  router_claim_profile,
  router_ftl_acu_area,
  router_ftl_acu_clinic,
  router_ftl_acu_experience,
  router_ftl_acu_profile,
  router_ftl_acu_service,
  router_ftl_finished,
  router_ftl_root,
  router_ftl_select_role,
  router_ftl_usr_profile,
  router_set_pwd,
  router_verify_email,
} from "../../utils/consts";
import { BasicLayout } from "@/web/comps/basic_layout";
import { useMount } from "ahooks";
import { SelectRole } from "./select_role";
import { useModel } from "use-reaction";
import { action_ftl_get_step, model_ftl } from "@/web/models/model_ftl";
import { useEffect } from "react";
import "./index.scss";
import { FtlFinishModal } from "./ftl_finished";
import { model_user } from "@/web/models/model_user";

const ftlRouters = [
  {
    path: router_ftl_select_role.replace(router_ftl_root, ""),
    element: <SelectRole />,
  },
  {
    path: router_ftl_usr_profile.replace(router_ftl_root, ""),
    element: <UsrProfile />,
  },
  {
    path: router_ftl_acu_profile.replace(router_ftl_root, ""),
    element: <AcuProfile />,
  },
  {
    path: router_ftl_acu_clinic.replace(router_ftl_root, ""),
    element: <AcuClinic />,
  },
  {
    path: router_ftl_acu_area.replace(router_ftl_root, ""),
    element: <AcuArea />,
  },
  {
    path: router_ftl_acu_experience.replace(router_ftl_root, ""),
    element: <AcuExperience />,
  },
  {
    path: router_ftl_acu_service.replace(router_ftl_root, ""),
    element: <AcuService />,
  },
  {
    path: router_ftl_finished.replace(router_ftl_root, ""),
    element: <FtlFinishModal />,
  },
];

export const FTL = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    store: { id: userId, confirmed, unclaimed, noPwd },
  } = useModel(model_user);
  const { doAction, store: { step_now } = { step_now: "" } } =
    useModel(model_ftl);

  useMount(() => {
    !step_now && doAction(action_ftl_get_step);
  });
  useEffect(() => {
    if (!step_now) {
      return;
    }
    if (userId && !confirmed) {
      navigate(router_verify_email);
      return;
    }
    if (unclaimed?.profile?.id) {
      navigate(router_claim_profile.replace(":id", unclaimed.profile.id + ""));
      return;
    }
    if (noPwd) {
      navigate(router_set_pwd);
      return;
    }
    const targetPath = `${router_ftl_root}${step_now}`;
    if (location.pathname !== targetPath) {
      navigate(targetPath);
    }
  }, [confirmed, step_now, unclaimed?.profile?.id]);

  return (
    <BasicLayout whiteBg={false} showUser={false}>
      <div className="ftl-wrapper">
        <Routes>
          {ftlRouters.map((r) => (
            <Route {...r} key={r.path} />
          ))}
        </Routes>
        <Outlet />
      </div>
    </BasicLayout>
  );
};
