import { BasicLayout } from "@/web/comps/basic_layout";
import { useParams } from "react-router-dom";
import { ClinicProfile } from "./clinic_profile";
import { DoctorProfile } from "./doctor_profile";
import "./index.scss";
export const Profile = () => {
  const { type, id } = useParams<{ type: "clinic" | "doctor"; id: string }>();

  return (
    <BasicLayout>
      {type === "clinic" ? (
        <ClinicProfile id={id!} />
      ) : (
        <DoctorProfile id={id!} />
      )}
    </BasicLayout>
  );
};
