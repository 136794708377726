import IconVisa2 from "@/assets/images/icon-visa-2.svg";
import IconMastercard from "@/assets/images/icon-mastercard.svg";
import { Checkbox, Divider, Form, Input, Radio, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useModel } from "use-reaction";
import { action_booking_post, model_booking } from "../models/model_booking";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useRequest } from "ahooks";
import { Link } from "react-router-dom";
import { router_policy } from "../utils/consts";
import { CardInfo, SquareCardInput } from "./square_card_input";
import "./booking_card_form.scss";
import { getUserCards } from "../api/booking";

const { getData: getCountryList } = require("country-list");
const countryList = getCountryList();

interface Props {}
export type BookingCardRef = {
  next: () => void;
};
export const BookingCardForm = forwardRef<BookingCardRef>(({}: Props, ref) => {
  const [form] = useForm();
  const squareCard = useRef<any>();
  const {
    doAction,
    doFunction,
    store: {
      cardData,
      basic: { existFetched },
    },
  } = useModel(model_booking);
  useImperativeHandle(ref, () => ({
    next: async () => {
      let card: any;
      if (pickOld) {
        card = { id: pickOld };
      } else {
        // use dofunction to show global loading
        card = await doFunction(async () => {
          const cardInfo: CardInfo = await squareCard.current?.submit();
          const { postalCode, ...newCard } = cardInfo;
          const values = await form.validateFields();
          const { cardholderName, save, ...billingAddress } = values;
          return {
            ...newCard,
            billingAddress: { ...billingAddress, postalCode },
            cardholderName,
            save,
          };
        }, "global");
      }
      const res: any = await doAction(action_booking_post, card, "global");
      if (!res.id) {
        return Promise.reject();
      }
    },
  }));
  const [pickOld, setPickOld] = useState<string | number>(0);
  const { data: oldCards } = useRequest(async () => {
    const cards = await getUserCards();
    if (!cards?.length) {
      setPickOld(0);
    }
    return cards;
  });

  useEffect(() => {
    if (existFetched && cardData.paymentToken) {
      form.setFieldsValue(cardData);
    }
  }, [existFetched]);
  return (
    <div className="booking-section booking-card-form">
      <div className="section-title">Payment Method</div>
      <div className="payment-section-title">
        <div className="title-left">
          <div className="title">Credit Card / Debit Card</div>
          <div className="description">Pay easily, fast and secure</div>
        </div>
        <div className="icon-right">
          <img src={IconVisa2} alt="icon-visa-2" />
          <img src={IconMastercard} alt="icon-mastercard" />
        </div>
      </div>
      <div className="payment-form">
        {
          <Radio.Group
            className="choose-add"
            onChange={(e) => setPickOld(e.target.value)}
            value={pickOld}
          >
            {oldCards?.map((c: any) => (
              <Radio value={c.id}>
                <span>
                  {c.brand} ******{c.last4}
                </span>
                <span className="holder">{c.cardholderName}</span>
              </Radio>
            ))}
            <Radio value={0}>Add new card</Radio>
          </Radio.Group>
        }
        {!pickOld && (
          <Form form={form} layout="vertical" initialValues={{ country: "US" }}>
            <Divider />
            <SquareCardInput ref={squareCard} />
            <Form.Item
              label="holder name"
              name="cardholderName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Your name",
                },
              ]}
            >
              <Input placeholder="Kristin ten Broeck, MSOM, L.Ac."></Input>
            </Form.Item>
            <Form.Item label="Address Line 1" name="addressLine1">
              <Input placeholder="1234 Main St" />
            </Form.Item>
            <Form.Item label="Address Line 2" name="addressLine2">
              <Input placeholder="Apartment, studio or floor" />
            </Form.Item>
            <div className="flex">
              <Form.Item label="Suburb / City" name="locality">
                <Input placeholder="city" />
              </Form.Item>

              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Choose Country",
                  },
                ]}
              >
                <Select showSearch placeholder="Country">
                  {Object.values(countryList)?.map((item: any) => (
                    <Select.Option value={item.code} key={item.code}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Form.Item name="save" valuePropName="checked">
              <Checkbox disabled={!!pickOld}>Save this card</Checkbox>
            </Form.Item>
            <div className="policy-content">
              By confirming this payment, you agree to our{" "}
              <Link to={router_policy}>Terms of Service</Link>. To ensure
              continued service, we'll store and update (e.g., upon expiration)
              your payment method. Learn about{" "}
              <Link to={router_policy}>how to cancel</Link> and our
              <Link to={router_policy}> refund policy</Link>. Prices may change.
            </div>
          </Form>
        )}
      </div>
      <Divider type="horizontal" />
    </div>
  );
});
