import { useResponsive } from "ahooks";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, Rate, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";

import IconMale from "@/assets/images/icon-male.svg";
import IconLocation from "@/assets/images/icon-location.svg";
import IconBuilding from "@/assets/images/icon-card-building.svg";
import IconPhone from "@/assets/images/icon-phone.svg";
import {
  router_account_profile,
  router_claim_verify,
  router_profile_with_slug,
} from "../utils/consts";
import { ReviewDoctorModal } from "./review_doctor_modal";
import { ReviewClinicModal } from "./review_clinic_modal";
import { useState } from "react";
import { formatNumber } from "../utils/util";
import "./profile_doctor_card.scss";
import { useModel } from "use-reaction";
import { model_user } from "../models/model_user";
import { RoleType } from "../utils/types";

interface Props {
  id: string;
  name: string;
  avatar: string;
  role: string;
  isSelf: boolean;
  medicalTitle: string;
  rating?: {
    reviewCount: number;
    overallRating: string | number;
    effectivenessRating: string | number;
    pleasantryRating: string | number;
  };

  clinicId: string;
  clinicName: string;
  clinicAddress: string;
  clinicPhone: string;
  clinicActive: boolean;
  goBooking: () => void;
}

export const ProfleDoctorCard = ({
  id,
  name,
  avatar,
  role,
  isSelf,
  rating,
  medicalTitle,
  clinicAddress,
  clinicId,
  clinicName,
  clinicPhone,
  clinicActive = true,
  goBooking,
}: Props) => {
  const navigate = useNavigate();
  const { isPC } = useResponsive();
  const {
    store: { id: userId, role: userRole },
  } = useModel(model_user);
  const [showReviewDoctor, setShowReviewDoctor] = useState(
    window.location.pathname.includes("review")
  );
  const [showReviewClinic, setShowReviewClinic] = useState(false);
  const goClinicPage = () =>
    navigate(
      router_profile_with_slug
        .replace(":type", "clinic")
        .replace(":id", clinicId)
        .replace(":slug", clinicName.replace(/\s+/g, "-"))
    );
  const goEditProfile = () => {
    navigate(router_account_profile + "/me");
  };

  const goClaim = () => {
    navigate(router_claim_verify.replace(":id", id));
  };
  const reviewDoctor = () => setShowReviewDoctor(true);
  return (
    <div className="role-card role-card__doctor">
      <div className="role-avatar">
        <Avatar shape="square" icon={<UserOutlined />} src={avatar} />
        {!userId && (
          <Tooltip
            overlay={
              <div>
                if you have not create an account on our platform but are sure
                this is your profile, please{" "}
                <Button type="primary" onClick={goClaim}>
                  claim your profile
                </Button>
              </div>
            }
            overlayClassName="claim-overlay"
          >
            <div className="claim">Is this You?</div>
          </Tooltip>
        )}
      </div>
      <div className="role-infos">
        <div className="infos-title">{name}</div>
        <div className="infos-desc">{medicalTitle}</div>
        <div className="infos-list">
          <div className="infos-item">
            <div className="infos-item-icon">
              <img src={IconMale} alt="icon-male" />
            </div>
            <div className="infos-item-value">{role}</div>
          </div>
          <div className="infos-item">
            <div className="infos-item-icon">
              <img src={IconBuilding} alt="icon-location" />
            </div>
            <div className="infos-item-value">
              Works at{" "}
              <span
                className="links"
                onClick={(e) => {
                  goClinicPage();
                }}
              >
                {clinicName}
              </span>
            </div>
          </div>
          <div className="infos-item">
            <div className="infos-item-icon">
              <img src={IconLocation} alt="icon-location" />
            </div>
            <div className="infos-item-value">{clinicAddress}</div>
          </div>
          <div className="infos-item">
            <div className="infos-item-icon">
              <img src={IconPhone} alt="icon-phone" />
            </div>
            <div className="infos-item-value">
              <a href={`tel:${clinicPhone}`}>{clinicPhone}</a>
            </div>
          </div>
        </div>
      </div>
      <div className="role-book role-book-doctor">
        <div className="btns">
          {!isSelf ? (
            <>
              <Button
                type="primary"
                hidden={
                  (!!userRole && userRole !== RoleType.USER) || !clinicActive
                }
                onClick={goBooking}
              >
                Book appointment
              </Button>
              <Button onClick={reviewDoctor}>Review Doctor</Button>
            </>
          ) : (
            <Button onClick={goEditProfile}>Edit my profile</Button>
          )}
        </div>
        <div className="role-scores">
          <div className="scores">{rating?.overallRating}</div>
          <div className="stars-reviews">
            <div className="stars">
              <Rate
                disabled
                value={rating?.overallRating ? +rating.overallRating : 0}
              />
            </div>
            <div className="reviews">
              {formatNumber(rating?.reviewCount || 0)} reviews
            </div>
          </div>
        </div>
      </div>
      <ReviewDoctorModal
        visible={showReviewDoctor}
        setVisible={setShowReviewDoctor}
        onReviewClinic={() => setShowReviewClinic(true)}
        doctorName={name}
        medicalTitle={medicalTitle}
        doctorId={id}
      />
      <ReviewClinicModal
        visible={showReviewClinic}
        setVisible={setShowReviewClinic}
        clinicName={clinicName}
        clinicId={clinicId}
      />
    </div>
  );
};
