import { putFtlacuClinic } from "@/web/api/ftl";
import { model_user, action_user_get_info } from "@/web/models/model_user";
import { RoleType } from "@/web/utils/types";
import { useIsClinicAdmin } from "@/web/utils/util";
import { Button, message } from "antd";
import { useRef } from "react";
import { useModel } from "use-reaction";
import { AcuClinic } from "../ftl/acu_clinic";
import "./account_clinic_profile.scss";

export const AccountClinicProfile = () => {
  const ref = useRef<any>();
  const { doAction } = useModel(model_user);
  const isAdmin = useIsClinicAdmin();
  const onSave = async (data: any) => {
    const res = await putFtlacuClinic(data);
    res?.id && message.success("save success!");
    doAction(action_user_get_info);
  };

  return (
    <>
      <AcuClinic
        ref={ref}
        className="account-profile"
        showExtra={false}
        onSave={onSave}
        canEdit={isAdmin}
        canCreate={false}
      />
      <div className="submit-btn">
        <Button type="primary" onClick={() => ref.current?.save()}>
          Save changes
        </Button>
        <Button onClick={() => ref.current?.view()}>
          View My Clinic Profile
        </Button>
      </div>
    </>
  );
};
