import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { NotFound } from "./pages/not_found";
import { Booking } from "./pages/booking";
import { BookingFinished } from "./pages/booking/finished";
import { FTL } from "./pages/ftl";
import { Profile } from "./pages/profile";
import { ServiceTerm } from "./pages/service_term";
import { SignIn } from "./pages/sign_in";
import { SignUp } from "./pages/sign_up";
import { Verify } from "./pages/verify";
import {
  router_account_root,
  router_account_verify_form,
  router_booking_appointment,
  router_booking_failed,
  router_booking_finished,
  router_claim_profile,
  router_claim_verify,
  router_ftl_root,
  router_login,
  router_policy,
  router_profile,
  router_profile_with_slug,
  router_profile_with_slug_review,
  router_reset_password,
  router_search_result,
  router_set_pwd,
  router_signup,
  router_verify_email,
} from "./utils/consts";
import { Account } from "./pages/account";
import { BookingFailed } from "./pages/booking/failed";
import { Home } from "./pages/home";
import { ResetPwd } from "./pages/reset_pwd";
import { ClaimProfile } from "./pages/claim_profile";
import { SearchPage } from "./pages/search";
import { getToken } from "./utils/ajax";
import { AccountVerifyForm } from "./pages/account/account_verify_form";
import React from "react";
import { ClaimEmail } from "./pages/claim_profile/claim_email";
import { SetPwd } from "./pages/set_pwd";

// define refactored routers here
export const routers = [
  {
    path: router_login,
    key: router_login,
    element: <SignIn />,
  },
  {
    path: router_signup,
    key: router_signup,
    element: <SignUp />,
  },
  {
    path: router_claim_profile,
    key: router_claim_profile,
    element: <ClaimProfile />,
    auth: true,
  },
  {
    path: router_verify_email,
    key: router_verify_email,
    element: <Verify />,
  },
  {
    path: router_claim_verify,
    key: router_claim_verify,
    element: <ClaimEmail />,
  },
  {
    path: router_set_pwd,
    key: router_set_pwd,
    element: <SetPwd />,
  },
  {
    path: router_reset_password,
    key: router_reset_password,
    element: <ResetPwd />,
  },
  {
    path: router_ftl_root + "*",
    key: router_ftl_root,
    element: <FTL />,
    auth: true,
  },
  {
    path: router_profile,
    key: router_profile,
    element: <Profile />,
  },
  {
    path: router_profile_with_slug,
    key: router_profile_with_slug,
    element: <Profile />,
  },
  {
    path: router_profile_with_slug_review,
    key: router_profile_with_slug_review,
    element: <Profile />,
  },
  {
    path: router_booking_appointment,
    key: router_booking_appointment,
    element: <Booking />,
    auth: true,
  },
  {
    path: router_booking_finished,
    key: router_booking_finished,
    element: <BookingFinished />,
    auth: true,
  },
  {
    path: router_booking_failed,
    key: router_booking_failed,
    element: <BookingFailed />,
    auth: true,
  },
  {
    path: router_account_root + "*",
    key: router_account_root,
    element: <Account />,
    auth: true,
  },
  {
    path: router_account_verify_form,
    key: router_account_verify_form,
    element: <AccountVerifyForm />,
    auth: true,
  },
  {
    path: router_policy,
    key: router_policy,
    element: <ServiceTerm />,
  },
  {
    path: router_search_result,
    key: router_search_result,
    element: <SearchPage />,
  },
  {
    path: "/",
    key: "/",
    element: <Home />,
  },
  {
    path: "*",
    key: "*",
    element: <NotFound />,
  },
];

const AuthGuard = ({
  auth,
  element,
}: {
  auth?: boolean;
  element: React.ReactElement;
}) => {
  const token = getToken();
  return auth ? (
    token ? (
      element
    ) : (
      <Navigate to={router_login} replace />
    )
  ) : (
    element
  );
};

export const AppRouter = () => {
  return (
    <div className="scale-wrapper">
      <BrowserRouter>
        <Routes>
          {routers.map(({ auth, element, ...r }) => (
            <Route
              {...r}
              element={<AuthGuard auth={auth} element={element} />}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
};
