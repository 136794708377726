import { BasicLayout } from "@/web/comps/basic_layout";
import { SectionBanner } from "./sec_banner";
import { SectionDoctors } from "./sec_doctors";
import { SectionExpertises } from "./sec_expertises";
import { SectionStatistics } from "./sec_statistic";
import { SectionValues } from "./sec_values";
import "./index.scss";
export const Home = () => {
  return (
    <BasicLayout>
      <div className="fma-homepage">
        <SectionBanner />
        <SectionStatistics />
        <SectionExpertises />
        <SectionValues />
        <SectionDoctors />
      </div>
    </BasicLayout>
  );
};
