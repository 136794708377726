import {
  applyCinicDoctorStatus,
  applyCinicRole,
  removeClinicDoctor,
} from "@/web/api/clinic";
import { getDoctorById } from "@/web/api/profile";
import { accountConfirmPwd } from "@/web/comps/account_confirm_pwd_modal";
import { ProfileAssociateDoctor } from "@/web/comps/profile_associate_doctor";
import { model_user } from "@/web/models/model_user";
import { clinicDoctorStatusCfg } from "@/web/utils/consts";
import { ClinicRole, DoctorStatus } from "@/web/utils/types";
import { useRequest } from "ahooks";
import { Button, Divider, Form, Input, message, Tag } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { useModel } from "use-reaction";
import "./account_clinic_doctor.scss";

export const AccountClinicDoctor = () => {
  const { id } = useParams();
  const [form] = useForm();
  const navigate = useNavigate();
  const {
    store: {
      profile: { clinicId },
    },
  } = useModel(model_user);
  const { data: doctor, mutate } = useRequest(
    async () => {
      const res = await getDoctorById(id!);
      form.setFieldsValue({
        name: res.profile.name,
        email: res.profile.email,
        treatmentServices: res.profile.treatmentServices,
      });
      return res;
    },
    {
      ready: !!id,
    }
  );

  const clinicRole = useMemo(
    () =>
      doctor?.profile?.clinicRole?.find((_: any) => _.clinicId === clinicId),
    [doctor, clinicId]
  );
  const back = () => navigate(-1);
  const trySave = () => {
    accountConfirmPwd({
      onSuccess: async () => {
        const role =
          clinicRole?.role === ClinicRole.ACUPUNCTURIST
            ? ClinicRole.CLINICADMIN
            : ClinicRole.ACUPUNCTURIST;
        await applyCinicRole(+id!, role);
        message.success("saved success!");
        // refresh role locally
        clinicRole.role = role;
        mutate({ ...doctor });
      },
    });
  };
  const toggleActive = () => {
    accountConfirmPwd({
      onSuccess: async () => {
        const status =
          clinicRole?.status === DoctorStatus.ACTIVE
            ? DoctorStatus.PENDING
            : DoctorStatus.ACTIVE;
        await applyCinicDoctorStatus(+id!, status);
        message.success("saved success!");
        // refresh status locally
        clinicRole.status = status;
        mutate({ ...doctor });
      },
    });
  };

  const removeDoctor = () => {
    accountConfirmPwd({
      onSuccess: async () => {
        await removeClinicDoctor(+id!);
        message.success("removed success!");
        back();
      },
    });
  };

  return (
    <>
      <div className="account-content-page account-clinic-doctor">
        <div className="profile-form doctor-profile-form">
          <ProfileAssociateDoctor
            key={"clinic-doctor-" + doctor?.profile?.id}
            avatar={doctor?.profile?.avatar?.url}
            doctorId={doctor?.profile?.id}
            doctorName={doctor?.profile?.name}
            medicalTitle={doctor?.profile?.medicalTitle}
            expertiseAreas={doctor?.profile?.expertiseAreas}
            active={doctor?.profile?.active}
            rank={doctor?.rating?.effectivenessRating || 0}
            reviewCount={doctor?.rating?.reviewCount}
          />

          <Form form={form} layout="vertical">
            <Form.Item label="name">
              <Input disabled />
            </Form.Item>
            <Form.Item label="email">
              <Input disabled />
            </Form.Item>
            <div className="service-list">
              <Form.List name="treatmentServices">
                {(fields) => (
                  <>
                    {fields.map(({ key, name, ...restFields }, index) => (
                      <div className="form-row" key={key}>
                        <div className="form-column">
                          <div className="form-item-title">
                            Service {index + 1}
                          </div>
                          <Form.Item
                            className="first-form-item"
                            {...restFields}
                            name={[name, "name"]}
                            fieldKey={[name, "name"]}
                            rules={[
                              { required: false, message: "Missing service" },
                            ]}
                          >
                            <Input disabled placeholder="E.g. Tuina" />
                          </Form.Item>
                        </div>
                        <div className="form-column">
                          <div className="form-item-title">Duration (min)</div>
                          <Form.Item
                            className="second-form-item"
                            {...restFields}
                            name={[name, "duration"]}
                            fieldKey={[name, "duration"]}
                            rules={[
                              {
                                required: false,
                                message: "Missing duration",
                              },
                            ]}
                          >
                            <Input disabled placeholder="E.g. 90" />
                          </Form.Item>
                        </div>
                        <div className="form-column">
                          <div className="form-item-title">Price ($)</div>
                          <Form.Item
                            className="third-form-item"
                            {...restFields}
                            name={[name, "price"]}
                            fieldKey={[name, "price"]}
                            rules={[
                              { required: false, message: "Missing price" },
                            ]}
                          >
                            <Input disabled placeholder="E.g. 100" />
                          </Form.Item>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
          </Form>
          <Divider />
          <div className="clinic-role">
            <Tag>
              <span>clinic role:</span>
              <span className="role">
                {clinicRole?.role === ClinicRole.ACUPUNCTURIST
                  ? "staff"
                  : "admin"}
              </span>
            </Tag>
            <Tag
              color={
                clinicRole?.status
                  ? (clinicDoctorStatusCfg as any)[clinicRole.status]!.color
                  : "red"
              }
            >
              {clinicRole?.status}
            </Tag>

            <Button onClick={trySave}>
              {clinicRole?.role === ClinicRole.ACUPUNCTURIST
                ? "set as admin"
                : "set as staff"}
            </Button>
            <Button onClick={toggleActive}>
              {clinicRole?.status === DoctorStatus.ACTIVE
                ? "deactive doctor"
                : "active doctor"}
            </Button>
            <Button type="primary" danger onClick={removeDoctor}>
              remove doctor
            </Button>
          </div>
        </div>
      </div>
      <div className="submit-btn">
        <Button onClick={back}>Back to list</Button>
      </div>
    </>
  );
};
