const en = {
  header_links_home: 'Home',
  header_links_login: 'Login',
  header_links_doctors: 'Find Practitioner',
  header_links_blogs: 'Blogs',
  header_user_center_my_account: 'My Account',
  header_user_center_my_profile: 'My Public Link',
  header_user_center_logout: 'Log out',
  footer_copyright: '© Copyright {currentYear} FindMyAcu.com',
  footer_links_service_term: 'Terms of Service',
  footer_links_privacy_policy: 'Privacy Policy',
  footer_links_contract_us: 'Contact Us',
  homepage_search_slogan: 'Find your Acupuncturist from Real User Reviews',
  homepage_search_description: 'Our user reviews are based on specific health concerns, treatment duration, effectiveness, cost, and etc.',
  homepage_our_statistics_title: 'Our statistics',
  homepage_our_statistics_description_title: 'Experience a different kind of healing...',
  homepage_our_statistics_description_content_1: 'Safe',
  homepage_our_statistics_description_content_2: 'Effective',
  homepage_our_statistics_description_content_3: 'Drug free',
  homepage_our_statistics_description_content_4: 'Promotes self healing',
  homepage_our_statistics_clinics: 'clinics',
  homepage_our_statistics_doctors: 'practitioners',
  homepage_our_statistics_cured: 'patients helped',
  homepage_find_acupuncturists_for_title: 'Find acupuncturists for',
  homepage_find_acupuncturists_for_title_mobile_1: 'Find',
  homepage_find_acupuncturists_for_title_mobile_2: 'acupuncturists for',
  homepage_deliver_values_title: 'The values we aim to deliver...',
  homepage_deliver_values_patients_section_title: 'For Patients',
  homepage_deliver_values_doctors_section_title: 'For Doctor',
  homepage_deliver_values_register_profile: 'Register your profile',
  homepage_deliver_values_item_specific_reviews_title: 'Specific reviews',
  homepage_deliver_values_item_specific_reviews_description: 'See specific problems, treatment duration and outcomes from other real patients to tune your decision',
  homepage_deliver_values_item_comprehensive_profiles_title: 'Comprehensive profiles',
  homepage_deliver_values_item_comprehensive_profiles_description: 'Learn more about doctors\' qualifications, specialties and techniques to find the best fit for your problems.',
  homepage_deliver_values_item_easy_booking_title: 'Easy booking',
  homepage_deliver_values_item_easy_booking_description: 'Streamlined online booking flow with your interested acupuncturist/clinics. Hassle free!',
  homepage_deliver_values_item_right_audience_title: 'Reach the right audience',
  homepage_deliver_values_item_right_audience_description: 'Register with our websites and gain exposure to patients interested in finding acupuncturists.',
  homepage_deliver_values_item_connect_patients_title: 'Connect with patients',
  homepage_deliver_values_item_connect_patients_description: 'Get feedbacks from real patients to improve your practices.',
  homepage_deliver_values_item_invest_future_title: 'Invest in the future',
  homepage_deliver_values_item_invest_future_description: 'Better user experience converts to better retainments of clients.',
  homepage_deliver_values_item_efficient_workflow_title: 'Efficient workflow',
  homepage_deliver_values_item_efficient_workflow_description: 'A streamlined process to help you manage your booking efficiently.',
  common_search_placeholder: 'Search by City, Zip code, doctor\'s name or clinic\'s name...',
  common_search_mobile_placeholder: 'City, Zip code...',
  common_button_search: 'Search'
};

export default en;
