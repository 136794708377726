import {
  checkClaimVerifyCode,
  fetchClaimVerifyCode,
  resetPassword,
} from "@/web/api/user";
import { BasicLayout } from "@/web/comps/basic_layout";
import { VerifyCode } from "@/web/comps/verify_code";
import { setToken } from "@/web/utils/ajax";
import { router_login } from "@/web/utils/consts";
import { useRequest } from "ahooks";
import { Button, Divider, Form, Input, message, Radio } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import "./claim_email.scss";
export const ClaimEmail = () => {
  const { id } = useParams();
  const [type, setType] = useState("email");
  const [receiver, setReceiver] = useState("");
  const [verified, setVerified] = useState(false);
  const navigate = useNavigate();
  const [form] = useForm();

  const { run: fetchVerify, loading } = useRequest(
    async (values: any) => {
      // call api
      await fetchClaimVerifyCode({
        type,
        receiver: values.receiver,
        profileId: +id!,
      });
      // then go step 2
      setReceiver(values.receiver);
    },
    { manual: true }
  );
  const resend = () => fetchVerify({ receiver });
  const { run: sendVerify, loading: loading2 } = useRequest(
    async (verifyCode: string) => {
      // call api to send
      const res = await checkClaimVerifyCode({
        verifyCode,
        profileId: +id!,
        ...(type === "email" ? { email: receiver } : { userPhone: receiver }),
      });
      setToken(res.access_token); // reset pwd use different token
      form.resetFields();
      setVerified(true);
    },
    { manual: true }
  );
  const { run: submitPassword, loading: loading3 } = useRequest(
    async () => {
      const values = await form.validateFields();
      if (values.newPassword === values.newPasswordConfirm) {
        await resetPassword(values.newPassword);
        setToken(""); // clear token, then go to login
        message.success("New password saved! let's login.", 1, goLogin);
      } else {
        message.error("The password entered twice is not the same！");
      }
    },
    { manual: true }
  );

  const goLogin = () => navigate(router_login, { replace: true });
  const back = () => navigate(-1);
  return (
    <BasicLayout>
      {!receiver ? (
        <div className="account-verify-form claim-email">
          <div className="claim-title">Let's verify your account first!</div>
          <Radio.Group
            className="veriry-type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <Radio value="email">Verify by email</Radio>
            <Radio value="phone">Verify by phone</Radio>
          </Radio.Group>
          <Divider />
          <div className="title">Verify {type}</div>
          {
            type === 'phone' && <div className="tips">
              Tips: make sure the phone number you typed can receive text message!
              or else it's recommended to verify by email.
            </div>
          }
          <div className="container">
            <Form layout="vertical" form={form} onFinish={fetchVerify}>
              {type === "email" ? (
                <Form.Item
                  label="email"
                  name="receiver"
                  rules={[{ required: true, message: "input email" }]}
                >
                  <Input type="email" placeholder="input email" />
                </Form.Item>
              ) : (
                <Form.Item
                  label="phone"
                  name="receiver"
                  rules={[{ required: true, message: "input phone number" }]}
                >
                  <Input prefix="+1" />
                </Form.Item>
              )}
            </Form>
          </div>
          <div className="submit-btn">
            <Button onClick={back}>cancel</Button>
            <Button type="primary" loading={loading} onClick={form.submit}>
              send
            </Button>
          </div>
        </div>
      ) : !verified ? (
        <VerifyCode
          className="account-verify-form"
          receiver={receiver}
          loading={loading2}
          resend={resend}
          onSend={sendVerify}
        />
      ) : (
        <div className="account-verify-form claim-email">
          <div className="title">Set your new password!</div>
          <Form form={form} layout="vertical" className="new-password-box">
            <div className="form-item">
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Missing new password!",
                  },
                ]}
              >
                <Input type="password" placeholder="Your new password here" />
              </Form.Item>
            </div>
            <div className="form-item">
              <Form.Item
                name="newPasswordConfirm"
                label="Confirm new password"
                rules={[
                  {
                    required: true,
                    message: "Missing new password!",
                  },
                ]}
              >
                <Input
                  type="password"
                  placeholder="Confirm your new password"
                />
              </Form.Item>
            </div>
            <div className="form-item form-item-submit">
              <Button
                type="primary"
                loading={loading3}
                onClick={submitPassword}
              >
                Submit Password
              </Button>
            </div>
          </Form>
        </div>
      )}
    </BasicLayout>
  );
};
