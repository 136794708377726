import { useCallback, useEffect, useState } from "react";
import { Select, Skeleton, Button, List } from "antd";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import IconMarkerDoctor from "../../assets/images/icon-google-map-doctor.svg";
import IconMarkerClinic from "../../assets/images/icon-google-map-clinic.svg";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { GeoPoint, Opt } from "../utils/types";
import { useMount, useResponsive, useUnmount } from "ahooks";
import { loadGoogleMap } from "../utils/lib";
import { getSumOfArr } from "../utils/util";
import SearchResultCard, { SearchResItem } from "./search_res_card";
import { CFG_DEFAULT_GEO_CENTER } from "../utils/consts";
import { getMyLocation } from "../api/search";
import "./search_in_gmap.scss";

const { Option } = Select;

// when render this comp, load google map and set center to washington DC without markers;
// when datas returned, redraw this map with markers
type Props = {
  sortOpts: Opt[];
  sort?: string;
  onSortChange: (sort: string) => void;
  onAreaChange?: (northEast: GeoPoint, southWest: GeoPoint) => void;
  loading?: boolean;
  visible: boolean;
  type: "doctor" | "clinic";
  list?: SearchResItem[];
};
export const SearchResultInGoogleMap = ({
  sortOpts,
  sort,
  onSortChange,
  onAreaChange,
  list,
  type,
  loading,
  visible,
}: Props) => {
  const { isPC } = useResponsive();

  const [showList, setShowList] = useState(false);
  const [googleMap, setGoogleMap] = useState<google.maps.Map>();
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();
  const [mapCenter, setMapCenter] = useState({
    lat: +CFG_DEFAULT_GEO_CENTER.lat,
    lng: +CFG_DEFAULT_GEO_CENTER.lng,
  });

  useMount(async () => {
    await loadGoogleMap();
    let center = mapCenter;
    try {
      const pos = await getMyLocation();
      center.lat = +pos.lat;
      center.lng = +pos.lng;
    } catch (error) {}
    const map = new window.google.maps.Map(
      document.getElementById("google-map")!,
      {
        center,
        zoom: 9,
        gestureHandling: "greedy",
      }
    );
    map.setCenter(center);
    setGoogleMap(map);

    map.addListener("idle", () => {
      if (onAreaChange) {
        const bounds = map.getBounds()!;
        const ne = bounds.getNorthEast().toJSON();
        const sw = bounds.getSouthWest().toJSON();
        if (doChanged(ne, sw)) {
          onAreaChange(ne, sw);
        }
      }
    });
  });
  useUnmount(
    () => googleMap && google.maps.event.clearInstanceListeners(googleMap)
  );

  useEffect(() => {
    if (googleMap && list?.length) {
      drawMarkers();
    }
  }, [list]);

  const drawMarkers = () => {
    infoWindow?.close();
    // pay attention to data structure between doctor and clinic, now there are some questions about it
    if (!list?.length) return;

    // calculate the center of the map
    let lats = list.map((item) => item.clinic_lat);
    let lngs = list.map((item) => item.clinic_lng);
    let len = list.length;
    let latAva = (getSumOfArr(lats) / len).toFixed(3);
    let lngAva = (getSumOfArr(lngs) / len).toFixed(3);
    // create markers

    const infoWd = new window.google.maps.InfoWindow({
      content: "",
      disableAutoPan: true,
    });
    setInfoWindow(infoWd);

    let markers = list.map((item) => {
      const position = {
        lat: item.clinic_lat,
        lng: item.clinic_lng,
      };
      const marker = new window.google.maps.Marker({
        position,
        label: "",
        // options: item,
        icon: type === "doctor" ? IconMarkerDoctor : IconMarkerClinic,
      });

      marker.addListener("click", () => {
        infoWd.setContent(`
					<div class="info-window-container">
						<div class="avatar ${item.avatar ? "has-avatar" : "no-avatar"}">
							<img src="${item.avatar || ""}" />
							<div></div>
						</div>
						<div class="infos">
							<div class="name">${
                (type === "clinic" ? item.clinic_name : item.profile_name) || ""
              }</div>
							<div class="medical-title">${
                type === "doctor" ? "Acupuncturist" : "Clinic"
              }</div>
							<div class="reviews">
								<span class="review-count">${item.patient_review_count} review(s)</span>
								<span class="cured cured_${type}">
									<span class="icon ${
                    item.cured_rating > 0.5
                      ? "icon_cured_high"
                      : "icon_cured_low"
                  }"></span>
									<span class="${item.cured_rating > 0.5 ? "green" : "red"}">${(
          item.cured_rating * 100
        ).toFixed(0)}% cured.</span>
								</span>
							</div>
							<div class="address">
								<span class="icon icon-location"></span>
								<span>${item.clinic_address || ""}</span>
							</div>
						</div>
					</div>
				`);
        infoWd.open(googleMap, marker);
      });

      return marker;
    });

    const center = {
      lat: +latAva,
      lng: +lngAva,
    };
    setMapCenter(center);
    new MarkerClusterer({
      markers,
      map: googleMap,
    });
    // googleMap!.setCenter(center);
  };

  return (
    <div className="search-result-in-google-map" hidden={!visible}>
      <div
        className={[
          "card-list-container",
          showList
            ? "card-list-container__show"
            : "card-list-container__hidden",
        ].join(" ")}
      >
        <div className="toggle-btn">
          {showList ? (
            isPC ? (
              <Button
                icon={<RightOutlined />}
                onClick={() => setShowList(false)}
              />
            ) : (
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={() => setShowList(false)}
              >
                hide list
              </Button>
            )
          ) : isPC ? (
            <Button icon={<LeftOutlined />} onClick={() => setShowList(true)} />
          ) : (
            <Button
              type="primary"
              style={{ width: "100%" }}
              className="show-map-list-btn"
              onClick={() => setShowList(true)}
            >
              show list
            </Button>
          )}
        </div>
        {showList && (
          <>
            <div className="filter-select">
              <Select
                disabled={!list?.length}
                placeholder="Relevance"
                onChange={(e) => onSortChange(e)}
                value={sort}
              >
                {sortOpts.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
              <span className="filter-tip">Sort by:</span>
            </div>
            {loading ? (
              <div className="card-list">
                {[1, 2, 3, 4].map((item) => (
                  <Skeleton key={item} />
                ))}
              </div>
            ) : (
              <List
                className="card-list"
                dataSource={list}
                renderItem={(item, index) => (
                  <SearchResultCard
                    key={index}
                    info={item as any}
                    type={type}
                  />
                )}
              />
            )}
          </>
        )}
      </div>
      <div className="google-map-container">
        <div id="google-map" className="google-map"></div>
      </div>
      {
        // todo: toggle btn shown on map
        !isPC && (
          <Button
            className="toggle-btn"
            icon={<LeftOutlined />}
            onClick={() => setShowList(true)}
          />
        )
      }
    </div>
  );
};

const lastArea: {
  ne?: google.maps.LatLngLiteral;
  sw?: google.maps.LatLngLiteral;
} = {};
const tolerance = 0.01;
function doChanged(
  ne: google.maps.LatLngLiteral,
  sw: google.maps.LatLngLiteral
) {
  if (!(lastArea.ne && lastArea.sw)) {
    lastArea.ne = ne;
    lastArea.sw = sw;
    return true;
  }
  if (
    Math.abs(lastArea.ne.lat - ne.lat) > tolerance ||
    Math.abs(lastArea.ne.lng - ne.lng) > tolerance ||
    Math.abs(lastArea.sw.lat - sw.lat) > tolerance ||
    Math.abs(lastArea.sw.lng - sw.lng) > tolerance
  ) {
    lastArea.ne = ne;
    lastArea.sw = sw;
    return true;
  }
  return false;
}
