import { getClinicById, getMyCinicRole } from "@/web/api/clinic";
import { getDoctorServicesById } from "@/web/api/profile";
import { ProfileBookService } from "@/web/comps/profile_book_service";
import { ProfleClinicCard } from "@/web/comps/profile_clinic_card";
import { ProfileClinicInfo } from "@/web/comps/profile_clinic_info";
import { ProfileClinicReviews } from "@/web/comps/profile_clinic_reviews";
import { model_user } from "@/web/models/model_user";
import { router_booking_appointment } from "@/web/utils/consts";
import { ClinicRole } from "@/web/utils/types";
import { dataItem2Opt } from "@/web/utils/util";
import { useRequest, useResponsive } from "ahooks";
import { message, Tabs } from "antd";
import qs from "qs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModel } from "use-reaction";

const { TabPane } = Tabs;
const tab_info = "information";
const tab_review = "patientReview";
interface Props {
  id: string;
}
export const ClinicProfile = ({ id }: Props) => {
  const { isPC } = useResponsive();
  const navigate = useNavigate();
  const [isSelf, setIsSelf] = useState(false);
  const [tabKey, setTabKey] = useState(tab_info);
  const [chosenDoctor, setChosenDoctor] = useState<any>();
  const [chosenService, setChosenService] = useState<number>();
  const {
    store: { profile },
  } = useModel(model_user);

  const { data, loading } = useRequest(async () => {
    const clinic = await getClinicById(id);
    if (profile?.id && clinic?.associateDoctors?.length) {
      const me = clinic.associateDoctors.find((_: any) => _.id === profile.id);
      if (me) {
        const myRole = await getMyCinicRole(id);
        if (myRole?.role === ClinicRole.CLINICADMIN) {
          setIsSelf(true);
        }
      }
    }
    return clinic;
  });
  const goBooking = (serviceId?: number) => {
    serviceId = serviceId || chosenService;
    navigate(
      router_booking_appointment +
        "?" +
        qs.stringify({ serviceId, doctorId: chosenDoctor.id, clinicId: id })
    );
  };

  const tipPickService = () => {
    setTabKey(tab_info);
    if (!chosenDoctor) {
      message.warn("Choose a doctor first!");
      return;
    }
    if (!chosenService) {
      message.warn("Pick a service of chosen doctor!");
      return;
    }
    goBooking();
  };

  const { data: serviceOpts } = useRequest(
    async () => {
      const res = await getDoctorServicesById(chosenDoctor.id);
      return res?.treatmentServices?.map(dataItem2Opt);
    },
    { ready: !!chosenDoctor?.id, refreshDeps: [chosenDoctor] }
  );

  return (
    <div className="profile-page clinic-profile">
      <div className="profile-banner"></div>
      <ProfleClinicCard
        avatar={data?.pictures?.length ? data.pictures[0].url : ""}
        clinicId={id}
        clinicName={data?.name}
        clinicActive={data?.active && data.associateDoctors?.length}
        clinicAddress={data?.address}
        clinicPhone={data?.phone}
        rating={data?.rating}
        goBooking={tipPickService}
      />
      <div className="profile-main">
        <div className="profile-main-center">
          <div className="profile-tabs">
            <Tabs activeKey={tabKey} onChange={setTabKey}>
              <TabPane tab="Information" key={tab_info}>
                <ProfileClinicInfo
                  id={id}
                  isSelf={isSelf}
                  introduction={data?.selfIntroduction}
                  treatmentServices={data?.treatmentServices}
                  associateDoctors={data?.associateDoctors}
                  onChosen={setChosenDoctor}
                />
              </TabPane>
              <TabPane tab="Patients' reviews" key={tab_review}>
                <ProfileClinicReviews />
              </TabPane>
            </Tabs>
          </div>
          <div className="profile-main-right">
            <ProfileBookService
              title={
                chosenDoctor?.name
                  ? `Services of ${chosenDoctor?.name}`
                  : "Choose a Doctor to book"
              }
              active={chosenDoctor?.active}
              options={serviceOpts}
              onChosen={setChosenService}
              onOk={goBooking}
            />
          </div>
          {!isPC && (
            <div className="book-tip">I'd like to book appointment with</div>
          )}
        </div>
      </div>
    </div>
  );
};
