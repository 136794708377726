import { deleteCard, getMycards } from "@/web/api/booking";
import { CardData } from "@/web/utils/types";
import { getCardIcon } from "@/web/utils/util";
import { useRequest } from "ahooks";
import { Button, Divider, message, Modal, Skeleton } from "antd";
import IconNoData from "@/assets/images/icon-no-data.svg";

import "./account_user_cards.scss";

export const AccountUserCards = () => {
  const {
    data: cards,
    loading,
    refresh,
  } = useRequest<CardData[], any>(getMycards);

  const delCard = (cd: CardData) => {
    Modal.confirm({
      title: "Delete this card?",
      content: `You are about to delete card with card number end in ${cd.last4}. Please make sure you really want to do this.`,
      okText: "delete",
      cancelText: "Close",
      className: "fma-modal",
      width: 600,
      centered: true,
      onOk: async () => {
        const res: any = await deleteCard(cd.id);
        if (res?.success) {
          message.success("Delete succeed!");
          refresh();
        }
      },
    });
  };
  return (
    <div className="account-user-cards">
      {loading ? (
        <>
          <Skeleton className="card" avatar paragraph />
          <Skeleton className="card" avatar paragraph />
        </>
      ) : cards?.length ? (
        cards?.map((c) => {
          let expMonth = c.expMonth.toString();
          if (expMonth.length === 1) {
            expMonth = "0" + expMonth;
          }
          return (
            <div className="card">
              <div className="line card-title">
                <span className="key">
                  {c.brand} ending in {c.last4}
                </span>
                <span className="val">
                  {expMonth}/{c.expYear}
                </span>
              </div>
              <div className="line text">
                <span className="key">Name on card:</span>
                <span className="val">{c.cardholderName}</span>
              </div>
              <div className="line text">
                <span className="key">Billing address:</span>
                <span className="val">
                  {[
                    c.billingAddress.addressLine1,
                    c.billingAddress.addressLine2,
                    c.billingAddress.locality,
                    c.billingAddress.postalCode,
                    c.billingAddress.country || "US",
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </span>
              </div>
              <Divider />
              <div className="line action">
                <img src={getCardIcon(c.brand)} alt="cardData-img" />
                <Button onClick={() => delCard(c)}>delete</Button>
              </div>
            </div>
          );
        })
      ) : (
        <div className="no-data">
          <div className="no-data-img">
            <img src={IconNoData} alt="icon-no-date" />
          </div>
          <div className="no-data-tip font-family-merriweather">
            No results found
          </div>
        </div>
      )}
    </div>
  );
};
