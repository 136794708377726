import { useMemo, useState } from "react";
import { UpOutlined } from "@ant-design/icons";
import classNames from "classnames";
import "./wrapper_text.scss";

interface Props {
  text: string;
  className?: string;
}
export const WrapperText = ({ text, className }: Props) => {
  const [showAll, setShowAll] = useState(false);
  const textWrapped = useMemo(() => text.slice(0, 500), [text]);

  return (
    <div className={classNames("wrapper-text", className)}>
      {showAll ? text : textWrapped}
      {!showAll && text.length > 500 && (
        <span onClick={(e) => setShowAll(!showAll)}>Read More</span>
      )}
      {showAll && text.length > 500 && (
        <UpOutlined
          style={{ color: "#696974" }}
          onClick={(e) => setShowAll(!showAll)}
        />
      )}
    </div>
  );
};
