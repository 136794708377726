// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import IconFacebook from "@/assets/images/icon-facebook.svg";
import { BasicLayout } from "@/web/comps/basic_layout";
import { ReactComponent as IconShowEye } from "@/assets/images/icon-show-pwd.svg";
import { ReactComponent as IconHideEye } from "@/assets/images/icon-hide-pwd.svg";
import { Form, Input, Button } from "antd";
import {
  email_reg,
  // email_reg,
  // FACEBOOK_CLIENT_ID,
  GOOGLE_CLIENT_ID,
  phone_reg,
  router_ftl_root,
  router_reset_password,
  router_signup,
} from "@/web/utils/consts";
import { useLoading, useModel } from "use-reaction";
import { action_user_login, model_user } from "@/web/models/model_user";
import { useNavigate } from "react-router-dom";
import { googleLogin, userLogin } from "@/web/api/user";
import { useEffect, useState } from "react";
import "./index.scss";
import { GoogleLoginBtn } from "@/web/comps/google_login_btn";
import { useResponsive } from "ahooks";
import { ValidateStatus } from "antd/lib/form/FormItem";

export const SignIn = () => {
  const navigate = useNavigate();
  const loading = useLoading(model_user);
  const { isPC } = useResponsive();
  const [validateStatus, setValidateStatus] = useState<ValidateStatus>("");
  const {
    doAction,
    store: { ftlStep },
  } = useModel(model_user);
  const onFinish = ({ account, password }: any) => {
    if (account && !(email_reg.test(account) || phone_reg.test(account))) {
      setValidateStatus("error");
      return false;
    }
    doAction(
      action_user_login,
      { data: { account: account.trim(), password }, api: userLogin },
      "model"
    );
  };

  const resetPwd = () => navigate(router_reset_password);

  // const fbSuccess = (res: any) => {
  //   if (!res?.userID) {
  //     message.error("facebook authorize failed");
  //     return;
  //   }
  //   let data = {
  //     userID: res?.userID || "",
  //     name: res?.name || "",
  //     email: res?.email || "",
  //     avatar: {
  //       url: res?.picture?.data?.url || "",
  //     },
  //   };
  //   doAction(action_user_login, { data, api: facebookLogin }, "model");
  // };
  const gglSuccess = (idToken: string) => {
    doAction(
      action_user_login,
      { data: { idToken }, api: googleLogin },
      "model"
    );
  };

  useEffect(() => {
    // after login
    if (ftlStep) {
      if (ftlStep === "finished") {
        navigate("/");
      } else {
        navigate(router_ftl_root);
      }
    }
  }, [ftlStep]);
  const width = isPC ? 400 : Math.min(400, document.body.clientWidth - 60);
  return (
    <BasicLayout showUser={false}>
      <div className="signin-page" style={{ width }}>
        <div className="account-page-block"></div>
        <div className="account-page-title font-family-merriweather">
          Log In
        </div>

        <Form className="login-form" layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="account"
            label="Email / Phone"
            validateStatus={validateStatus}
            help={
              validateStatus === "error"
                ? "Not valid format of email or phone number"
                : undefined
            }
            rules={[
              {
                required: true,
                message: "Please input your email or phone number!",
              },
            ]}
          >
            <Input
              placeholder="Email address or phone number"
              onFocus={() => setValidateStatus("")}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              placeholder="Password"
              iconRender={(visible) =>
                visible ? <IconHideEye /> : <IconShowEye />
              }
            />
          </Form.Item>
          <div className="forget-password">
            <span onClick={resetPwd}>Forgot password?</span>
          </div>
          <div className="form-item-btn">
            <Button
              type="primary"
              block={true}
              loading={loading}
              htmlType="submit"
              style={{height: 40}}
            >
              Login
            </Button>
          </div>

          {/* <FacebookLogin
              appId={FACEBOOK_CLIENT_ID}
              autoLoad={false}
              fields="name,email,picture"
              //   onClick={fbLogin}
              callback={fbSuccess}
              render={(renderProps: any) => (
                <Button
                  className="facebook"
                  type="primary"
                  block={true}
                  style={{ height: "64px", marginBottom: "12px" }}
                  onClick={renderProps.onClick}
                  disabled={renderProps.isDisabled}
                >
                  <img src={IconFacebook} alt="icon-facebook" />
                  Login with Facebook
                </Button>
              )}
            /> */}
          <div className="form-item-or">Or</div>
          <div className="google-section">
            <GoogleLoginBtn
              clientId={GOOGLE_CLIENT_ID}
              onSuccess={gglSuccess}
              width={width}
            />
          </div>
          <div className="form-item-link">
            Are you new here?
            <a href={router_signup}>Sign Up</a>
          </div>
        </Form>
      </div>
    </BasicLayout>
  );
};
