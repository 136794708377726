import { getLanguages, searchExpertiseCommon } from "@/web/api/options";
import {
  getFtlacuArea,
  getFtlacuLanguages,
  putFtlacuArea,
  putFtlacuLanguages,
} from "@/web/api/ftl";
import { getAcuIntroduction, putAcuInfroduction } from "@/web/api/profile";
import { getTreatmentTechs } from "@/web/api/options";
import { SearchSelect } from "@/web/comps/search_select";
import { action_user_get_info, model_user } from "@/web/models/model_user";
import { Opt } from "@/web/utils/types";
import { dataItem2Opt, opt2DataItem } from "@/web/utils/util";
import { useMount, useRequest } from "ahooks";
import { Button, Form, Input, message, Select, Skeleton, Tag } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import SkeletonInput from "antd/lib/skeleton/Input";
import { unionBy } from "lodash";
import { useState } from "react";
import { useModel } from "use-reaction";
import "./account_acu_profile_personal.scss";

export const AccountAcuProfilePersonal = () => {
  const [form] = useForm();
  const { doAction } = useModel(model_user);
  const { run: onFinish, loading } = useRequest(
    async ({ languageIds, ...values }: any) => {
      await putAcuInfroduction(values);
      const acuAreasEtc = {
        expertiseAreas: selectTags.map(opt2DataItem),
        treatmentTechniques: treatments.map(opt2DataItem),
        treatmentTechniqueDescription: treatmentsDes,
      };
      await putFtlacuArea(acuAreasEtc);
      await putFtlacuLanguages(languageIds);
      message.success("save success!");
      doAction(action_user_get_info);
    },
    { manual: true }
  );

  const [commonTags, setCommonTags] = useState<Opt[]>([]);
  const [selectTags, setSelectTags] = useState<Opt[]>([]);
  const [treatments, setTreatments] = useState<Opt[]>([]);
  const [treatmentsDes, setTreatmentsDes] = useState<string>();

  const getExpertiseByName = async (name: string) => {
    let common = commonTags;
    if (!common?.length) {
      // all onMount
      const commonData = await searchExpertiseCommon();
      common = commonData.map(dataItem2Opt);
      setCommonTags(common);
      const data = await getFtlacuArea();
      setSelectTags(data.expertiseAreas?.map(dataItem2Opt));
      setTreatments(data.treatmentTechniques?.map(dataItem2Opt));
      setTreatmentsDes(data.treatmentTechniqueDescription);
      const lang = await getFtlacuLanguages();
      form.setFieldsValue({ ...lang });
    }
    const data = await searchExpertiseCommon(name, 20);
    return unionBy(data.map(dataItem2Opt), common, "value");
  };

  const { data: treatmentList } = useRequest(async () => {
    const data = await getTreatmentTechs();
    return data.map(dataItem2Opt) as Opt[];
  });

  const toggleTag = (v: any) => {
    const idx = selectTags.findIndex((_) => _.value === v.value);
    if (idx > -1) {
      selectTags.splice(idx, 1);
    } else {
      selectTags.push(v);
    }
    setSelectTags([...selectTags]);
  };

  useMount(async () => {
    const res = await getAcuIntroduction();
    if (res?.selfIntroduction) {
      form.setFieldsValue(res);
    }
  });
  return (
    <>
      <div className="account-profile">
        <div className="experience-section self-introduction">
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <p className="section-title">Tell us about you</p>
            <Form.Item name="selfIntroduction" label="Self INtroduction">
              <TextArea
                maxLength={1000}
                rows={6}
                placeholder="e.g. As an acupuncturist, I view each individual as a dynamic, integrated whole and partner with my patients to create healing and well-being. Health goes beyond the absence of acute or chronic illness. Often, we do not acknowledge our health until it is compromised..."
              />
            </Form.Item>
            <Form.Item
              name="languageIds"
              label="Language(s)"
              rules={[
                {
                  required: true,
                  message: "Missing languages!",
                },
              ]}
            >
              <SearchSelect
                mode="multiple"
                placeholder="Please select"
                searchOnMount
                searchFn={getLanguages}
              />
            </Form.Item>
            <div className="acu-expertise-area">
              <div className="select-tags">
                <div className="select-tags-title font-family-merriweather">
                  What are your areas of expertise?
                </div>
                <SearchSelect
                  searchFn={getExpertiseByName}
                  value={selectTags.map((_) => _.value!)}
                  searchOnMount
                  mode="multiple"
                  className="acu-select"
                  onChange={(_, opts) => setSelectTags(opts as Opt[])}
                />
                <div className="suggestion-title">common expertise</div>
                <div className="suggestion-tags">
                  {commonTags?.length ? (
                    commonTags.map((t) => (
                      <Tag
                        key={"acu-expertise" + t.value}
                        className="expertise-tag"
                        onClick={(e) => toggleTag(t)}
                      >
                        {t.label}
                      </Tag>
                    ))
                  ) : (
                    <Skeleton paragraph={{ rows: 3 }} />
                  )}
                </div>
              </div>
              <div className="treatment-techniques">
                <div className="treatment-techniques-title font-family-merriweather">
                  What treatment techniques do you use?
                </div>
                {treatmentList?.length ? (
                  <Select
                    mode="multiple"
                    className="acu-select"
                    placeholder="Search for treatment techniques"
                    value={treatments}
                    disabled={!treatmentList?.length}
                    onChange={(_, opt) => setTreatments(opt as Opt[])}
                    options={treatmentList}
                  />
                ) : (
                  <SkeletonInput />
                )}
                <div className="input-title">Technique Details</div>
                <Input.TextArea
                  className="technique-detail"
                  placeholder="Enter your technique details"
                  value={treatmentsDes}
                  disabled={!treatmentList?.length}
                  onChange={(e) => setTreatmentsDes(e.target.value)}
                ></Input.TextArea>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="submit-btn">
        <Button type="primary" loading={loading} onClick={form.submit}>
          Save changes
        </Button>
      </div>
    </>
  );
};
