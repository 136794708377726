import IconRoleDoctor from "@/assets/images/icon-role-doctor.svg";
import IconRolePatient from "@/assets/images/icon-role-patient.svg";
import IconSpecificReviews from "@/assets/images/icon-specific-reviews.svg";
import IconComprehensiveProfile from "@/assets/images/icon-comprehensive-profile.svg";
import IconEasyBooking from "@/assets/images/icon-easy-booking.svg";
import IconUsers from "@/assets/images/icon-users.svg";
import IconChat from "@/assets/images/icon-chat.svg";
import IconInvest from "@/assets/images/icon-invest.svg";
import IconWorkflow from "@/assets/images/icon-workflow.svg";
import IconPen from "@/assets/images/icon-pen.svg";
import intl from "react-intl-universal";
import { useNavigate } from "react-router-dom";
import { model_user } from "@/web/models/model_user";
import { RoleType } from "@/web/utils/types";
import { useModel } from "use-reaction";
import { router_account_profile, router_account_user_profile } from "@/web/utils/consts";

const DELIVER_VALUES = [
  {
    role: "patients",
    icon: IconRolePatient,
    values: [
      {
        icon: IconSpecificReviews,
        key: "specific_reviews",
      },
      {
        icon: IconComprehensiveProfile,
        key: "comprehensive_profiles",
      },
      {
        icon: IconEasyBooking,
        key: "easy_booking",
      },
    ],
  },
  {
    role: "doctors",
    icon: IconRoleDoctor,
    values: [
      {
        icon: IconUsers,
        key: "right_audience",
      },
      {
        icon: IconChat,
        key: "connect_patients",
      },
      {
        icon: IconInvest,
        key: "invest_future",
      },
      {
        icon: IconWorkflow,
        key: "efficient_workflow",
      },
    ],
  },
];

export const SectionValues = () => {
  const navigate = useNavigate();
  const {
    store: {
      role,
    },
  } = useModel(model_user);

  const editProfile = () => {
    if (role === RoleType.USER) {
      navigate(router_account_user_profile);
    } else {
      navigate(router_account_profile);
    }
  };
  return (
    <div className="section values-delivered-section">
      <div className="values-delivered">
        <div className="section-block-container">
          <div className="section-block"></div>
        </div>
        <div className="section-title font-family-merriweather">
          {intl.get("homepage_deliver_values_title")}
        </div>
        <div className="values-list">
          {DELIVER_VALUES.map((m, index) => (
            <div key={index} className="values-item">
              <div className="values-item-left">
                <div className="values-item-left-img">
                  <img src={m.icon} alt={`icon-${m.role}`} />
                </div>
                <div className="values-item-left-role">
                  {intl.get(`homepage_deliver_values_${m.role}_section_title`)}
                </div>
                {m.role === "doctors" && (
                  <div
                    className="register-your-profile"
                    onClick={() => editProfile()}
                  >
                    {intl.get("homepage_deliver_values_register_profile")}
                    <img src={IconPen} alt="register-your-profile" />
                  </div>
                )}
              </div>
              <div className="values-item-right">
                {m.values.map((n, ind) => (
                  <div key={ind} className="values-right-item">
                    <div className="values-right-item-img">
                      <img src={n.icon} alt={`icon-${n.key}`} />
                    </div>
                    <div className="values-right-item-info">
                      <div className="title">
                        {intl.get(
                          `homepage_deliver_values_item_${n.key}_title`
                        )}
                      </div>
                      <div className="desc">
                        {intl.get(
                          `homepage_deliver_values_item_${n.key}_description`
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
