import {
  getUserSecurity,
  postUserCheckPwd,
  putUserSecurity,
} from "@/web/api/user";
import { accountConfirmPwd } from "@/web/comps/account_confirm_pwd_modal";
import { useMount, useRequest } from "ahooks";
import { Button, Form, Input, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { AccountSettingProgress } from "./account_setting_progress";
import "./account_setting_security.scss";

export const AccountSettingSecurity = () => {
  const [form] = useForm();
  const [pwd, setPwd] = useState("");
  const [editing, setEditing] = useState(false);
  const [securityId, setSecurityId] = useState(0);
  const toEdit = async () => {
    const res = await postUserCheckPwd(pwd);
    if (res.success) {
      message.success("verify success!");
      setEditing(true);
    }
  };
  const { run: submit, loading } = useRequest(
    (values: any) => putUserSecurity(values),
    { manual: true, throttleLeading: true }
  );
  const onFinish = async (values: any) => {
    await submit({ ...values, securityId });
    setEditing(false);
    message.success("changes saved!");
  };
  useMount(async () => {
    const security = await getUserSecurity();
    if (security) {
      setSecurityId(security.securityId);
      form.setFieldsValue(security);
    }
  });
  return (
    <>
      <div className="account-content-page account-security">
        <Form layout="vertical" hidden={editing}>
          <Form.Item label="password" name="password">
            <Input.Password
              placeholder="input password"
              value={pwd}
              onChange={(e) => setPwd(e.target.value.trim())}
            />
          </Form.Item>
          <div className="tips">
            Please verify password before change your security Q&A;
          </div>
        </Form>
        <Form
          form={form}
          hidden={!editing}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label="Your Security Question"
            name="question"
            rules={[
              {
                required: true,
                message: "choose your security question",
              },
            ]}
          >
            <Input disabled={!editing} placeholder="question" />
          </Form.Item>
          <Form.Item
            label="Your Security Answer"
            name="answer"
            rules={[
              {
                required: true,
                message: "enter your answer",
              },
            ]}
          >
            <Input disabled={!editing} placeholder="answer" />
          </Form.Item>
          <div className="tips">
            Suggest to use question and answer that information only you know.
          </div>
        </Form>
      </div>

      <div className="submit-btn">
        <Button hidden={editing} type="primary" danger onClick={toEdit}>
          Verify
        </Button>
        <Button
          hidden={!editing}
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={form.submit}
        >
          Save
        </Button>
      </div>

      <AccountSettingProgress/>
    </>
  );
};
