import { Button, Divider, Form, Input, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ReactComponent as IconShowEye } from "@/assets/images/icon-show-pwd.svg";
import { ReactComponent as IconHideEye } from "@/assets/images/icon-hide-pwd.svg";

import "./account_setting_delete.scss";
import { useMount } from "ahooks";
import { getUserSecurity, putDeleteAccount } from "@/web/api/user";
import { useState } from "react";
import { logout } from "@/web/utils/ajax";
export const AccountSettingDelete = () => {
  const [form] = useForm();
  const [securityId, setSecurityId] = useState(0);

  const onFinish = (values: any) => {
    tryDelete(values);
  };

  const tryDelete = (values: any) => {
    Modal.confirm({
      title: "Delete your account?",
      className: "fma-modal",
      centered: true,
      content: (
        <div>
          Warning: by delete, you will:
          <ul>
            <li>asdkl alskd laskd</li>
            <li>asdk asdlk asld </li>
            <li>lasid laskd ffgg</li>
          </ul>
        </div>
      ),
      okText: "Yes,Delete",
      width: 600,
      onOk: async () => {
        //todo use values to call api
        const { question, ...data } = values;
        await putDeleteAccount({
          securityId,
          ...data,
        });
        message.success("Account Deleted!", logout);
      },
    });
  };
  useMount(async () => {
    const security = await getUserSecurity();
    if (security) {
      setSecurityId(security.securityId);
      form.setFieldsValue({ question: security.question });
    }
  });
  return (
    <>
      <div className="account-content-page account-security account-delete">
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="current password"
            name="password"
            rules={[
              {
                required: true,
                message: "current password",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              iconRender={(visible) =>
                visible ? <IconHideEye /> : <IconShowEye />
              }
            />
          </Form.Item>
          <div className="tips">
            <span className="txt">
              To ensure this is operate by yourself. Please answer follow
              question you set before:
            </span>
            <Divider />
            <Form.Item label="Your Security Question" name="question">
              <Input disabled placeholder="question" />
            </Form.Item>
            <Form.Item
              label="Your Security Answer"
              name="securityAnswer"
              rules={[
                {
                  required: true,
                  message: "enter your answer",
                },
              ]}
            >
              <Input placeholder="enter your answer" />
            </Form.Item>
          </div>
        </Form>
      </div>
      <div className="submit-btn">
        <Button type="primary" onClick={form.submit}>
          Delete
        </Button>
      </div>
    </>
  );
};
