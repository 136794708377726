import { message, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { getToken } from "../utils/ajax";
import avatarImg from "@/assets/images/avatar.svg";
import deleteImg from "@/assets/images/delete.svg";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { ImgData } from "../utils/types";
import { API_HOST } from "../utils/consts";
import "./upload_avatar.scss";
const allow_types = ["iamge/jpg", "image/jpeg", "image/png", "image/svg"];

interface Props {
  initImg?: ImgData;
  des?: string;
  onDelete?: (id: number) => void;
  onFinish: (data: ImgData) => void;
}
export const UploadAvatar = ({
  initImg = { url: "" },
  des = "Choose profile picture",
  onDelete,
  onFinish,
}: Props) => {
  const [img, setImg] = useState<ImgData>();
  const [loading, setLoading] = useState(false);

  const beforeUpdate = (file: RcFile) => {
    if (!allow_types.includes(file.type)) {
      message.warning("You can only upload JPG/PNG/SVG file!");
      return false;
    }
    if (file.size / 1024 / 1024 > 2) {
      message.warning("Image must smaller than 2MB!");
      return false;
    }
    return true;
  };

  const deleteAvatar = (e: React.MouseEvent) => {
    e.stopPropagation();
    setImg(undefined);
    img?.id && onDelete && onDelete(img.id);
  };
  const getBase64 = (img: File, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  };
  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      let data = info.file.response.data;
      setImg(data as ImgData);
      onFinish && onFinish(data);
    }
    !img?.url &&
      info.file.originFileObj &&
      getBase64(info.file.originFileObj, (url) => {
        setImg({ url });
        setLoading(false);
      });
  };

  useEffect(() => {
    initImg?.url && setImg(initImg);
  }, [initImg]);
  return (
    <div className="upload-avatar-wrapper">
      <Upload
        showUploadList={false}
        accept="image/*"
        headers={{
          authorization: `Bearer ${getToken()}`,
        }}
        action={`${API_HOST}/users/avatar`}
        beforeUpload={beforeUpdate}
        onChange={handleChange}
      >
        <div className="upload-avatar">
          <div className="img-wrapper">
            <img className="avatar" src={img?.url || avatarImg} alt="avatar" />
            {img?.url ? (
              <img
                src={deleteImg}
                className="delete-avatar"
                alt="delete"
                onClick={deleteAvatar}
              />
            ) : null}
          </div>
          {des && <div className="text-link">{des}</div>}
        </div>
      </Upload>
    </div>
  );
};
