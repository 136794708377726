import { AppRouter } from "./routers";
import { useLoading, useModel, useProvider, useReaction } from "use-reaction";
import { Spin } from "antd";
import { configResponsive, useMount } from "ahooks";
import { action_init_intl, model_common } from "./models/model_common";
import { action_user_get_info, model_user } from "./models/model_user";
import { useGA4React } from "ga-4-react";
import { GOOGLE_ANALYTIC_ID } from "./utils/consts";
import { loadSquare } from "./utils/lib";
import "antd/dist/antd.css";
import "../assets/styles/rem.scss";
import "./app.scss";
import { model_ftl } from "./models/model_ftl";

function GlobalLoading({ children }: any) {
  const loading = useLoading();
  const {
    store: { intl_ready },
    doAction,
  } = useModel(model_common);
  const { doAction: doActionUser } = useModel(model_user);
  useModel(model_ftl);
  // init i18n
  useMount(() => doAction(action_init_intl, navigator.language));
  useMount(() => doActionUser(action_user_get_info));
  return (
    <Spin
      wrapperClassName="global-spin-wrapper"
      className="global-spin"
      delay={300}
      spinning={loading || !intl_ready}
    >
      {children}
    </Spin>
  );
}

const App = () => {
  configResponsive({ _: 0, isPC: 769 });
  useReaction(process.env.NODE_ENV === "development");
  useGA4React(GOOGLE_ANALYTIC_ID);
  loadSquare();
  const Provider = useProvider();

  return (
    <Provider>
      <GlobalLoading>
        <div className="App">
          <AppRouter />
        </div>
      </GlobalLoading>
    </Provider>
  );
};
export default App;
